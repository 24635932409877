import React, { useState } from "react";

import { BiPlus, BiSolidTrash } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdOutlineModeEditOutline } from "react-icons/md";

import ElectiveGroup from "./ElectiveGroup";

const ElectiveCategory = ({ ElectiveCategoryName, methodOnDelete }) => {
  const [createStreamCount, setCreateStreamCount] = useState(0);
  const [streamValues, setStreamValues] = useState([]);

  const [newGroup, setNewGroup] = useState({
    groupName: null,
    subjects: [],
    selectedSections: [],
  });

  // This state to show the GET data of Groups - Give arrays to display groups
  const [groupList, setGroupList] = useState([]);

  const handleCreateStreamButton = () => {
    setCreateStreamCount(createStreamCount + 1);
    setStreamValues([
      ...streamValues,
      { groupName: null, subjects: [], selectedSections: [] },
    ]);
  };

  const handleGroupNameChange = (event) => {
    setNewGroup((prevGroup) => ({
      ...prevGroup,
      groupName: event.target.value,
    }));
  };

  const handleSubjectInputChange = (event) => {
    const inputKey = event.target.value;
    if (event.key === "Enter" && inputKey.trim() !== "") {
      setNewGroup((prevGroup) => ({
        ...prevGroup,
        subjects: [...prevGroup.subjects, inputKey.trim()],
      }));
      event.target.value = ""; // Clear the input after adding the subject
    }
  };

  return (
    <>
      <div className="d-flex p-4 mb-4 rounded rounded-3 border-1 border-secondary flex-column">
        <div className="header py-2 h-auto border-bottom fw-bold fs-4">
          {ElectiveCategoryName}
          <button
            type="button"
            class="d-flex align-items-center gap-2 fw-bold fs-5"
            style={{ color: "#FF4B4B" }}
            onClick={() => methodOnDelete(false)}>
            <BiSolidTrash
              size={22}
              fill="#FF4B4B"
              className="pointer-cursor hover-click"></BiSolidTrash>
            Delete
          </button>
        </div>

        {/* Group Input Mood */}
        {Array.from({ length: createStreamCount }).map((_, index) => (
          <div className="ms-3 mt-4 py-2 h-auto border-bottom " key={index}>
            {/* Group Name Input  */}
            <div
              className="d-flex justify-content-between"
              id="elective-group__name">
              <h4 className=" mb-3 d-flex align-items-center gap-2">
                <input
                  type="text"
                  name="group-name"
                  id=""
                  placeholder="Group Name"
                  className="fw-bold fs-5 no-outline"
                  value={newGroup.groupName || ""}
                  onChange={handleGroupNameChange}
                />
                <MdOutlineModeEditOutline
                  fill="#2265E8"
                  className="pointer-cursor"
                  size={24}></MdOutlineModeEditOutline>
              </h4>
              <div className="d-flex">
                <RxCross1
                  size={24}
                  className="pointer-cursor me-3 hover-click"
                  style={{ color: "#FF4B4B" }}
                  onClick={() =>
                    setCreateStreamCount(createStreamCount - 1)
                  }></RxCross1>
                {/* <BsCheckLg
                  size={26}
                  className="pointer-cursor me-3 hover-click"
                  style={{ color: "#2265E8" }}></BsCheckLg> */}
              </div>
            </div>
            {/* Subjects Input */}
            <div
              className="subjects input mb-3 d-flex align-items-center gap-2"
              id="elective-group__subject">
              <input
                type="text"
                name="electives"
                id="elective-group__subject--input"
                placeholder="Enter Electives"
                className="border-2 p-2 rounded w-25"
                onKeyPress={handleSubjectInputChange}
              />
              <AiOutlineQuestionCircle
                size={20}
                color="#A0A0A0"
                className="pointer-cursor"
                title="Type the Elective Subject name and press Enter"></AiOutlineQuestionCircle>
            </div>
            {/* Subject List */}
            <ul id="elective__common-subject--list" className="d-flex p-0">
              {newGroup.subjects?.map((el, key) => {
                return (
                  <li
                    key={key}
                    className="px-3 py-1 me-2 rounded-pill bg-light border-1
           list-unstyled d-flex align-items-center gap-2 hover-dim text-capitalize">
                    {el}
                    <RxCross1 className="pointer-cursor"></RxCross1>
                  </li>
                );
              })}
            </ul>
            {/* Section List */}
            {newGroup.subjects.length > 0 && (
              <div className="section">
                <p>Applicable Section(s) for this Stream</p>
                <ul id="elective__common-subject--list" className="d-flex p-0">
                  {["a", "b", "c"].map((el, key) => {
                    return (
                      <li
                        key={key}
                        className="px-4 py-1 me-2 rounded-pill border-1
           list-unstyled pointer-cursor fw-bold text-capitalize"
                        style={{
                          backgroundColor: "#379DDB33",
                          color: "#4267B2",
                        }}>
                        {el}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        ))}

        {groupList?.map(() => (
          <ElectiveGroup
            groupName={"Biology"}
            subjectList={["Maths", "Chemistry", "Physics", "Biology"]}
            sections={["A", "B", "C", "D"]}
          />
        ))}

        <button
          onClick={handleCreateStreamButton}
          className="bg-primary-light text-primary pointer-cursor hover-click p-3 my-4 rounded  d-flex align-items-center gap-2 justify-content-center fs-5 fw-bold">
          <BiPlus></BiPlus>
          Create new stream
        </button>
      </div>
    </>
  );
};

export default ElectiveCategory;
