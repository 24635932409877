import React, { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Header from "../../../components/Header";
import NavigationIndicator from "../../../components/NavigationIndicator";
import { ElectivesCategory } from "./ElectiveComponents";

const Electives = () => {
  const data__commonSubjectList = ["English", "PET", "Yoga"];

  const [assignButton, setAssignButton] = useState(false);
  const [isStream, setIsStream] = useState(false);
  const [isLanguage, setIsLanguage] = useState(false);

  return (
    <div className="w-100 d-flex flex-column" style={{ background: "#fbf9ff" }}>
      <Header />
      <NavigationIndicator
        page="Settings"
        subpage="Electives"
        description="Create, manage, and assign elective streams and languages to sections."
      />

      {/* -------- Page Main Container ---------- */}
      <div className="col-11 ms-3 mt-2">
        <div
          className="ms-4"
          style={{
            background: "#ffffff",
            padding: "10px",
            borderRadius: "25px",
            border: "1px solid #d9d9d9",
          }}>
          <h4 className="teacher-header ms-3 mt-3">Electives Manager</h4>

          <div className="ms-3 mt-4 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <h6 className="me-2 mb-0 text-small-1">Class</h6>
              <select className="teacher-select">
                <option value="11" selected>
                  11
                </option>
                <option value="12">12</option>
              </select>
            </div>
          </div>

          {/* Content Container */}
          <div className="my-4 mx-5 p-4">
            <div className="">
              <div className="px-5">
                <h3 className="fs-2 fw-bold">Common Subjects</h3>
                <p>
                  These subjects are applicable for all students in the section.
                  To make changes or remove subjects, go to the{" "}
                  <a href="#" className="text-primary">
                    Subjects
                  </a>{" "}
                  section.
                </p>

                <ul id="elective__common-subject--list" className="d-flex p-0">
                  {data__commonSubjectList.map((el, key) => {
                    return (
                      <li
                        key={key}
                        className="px-3 py-1 me-2 rounded-pill bg-light border-1
                      list-unstyled ">
                        {el}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="px-5 mt-5">
                <div className="titel">
                  <h3 className="fs-2 fw-bold">Electives Subjects</h3>
                  <p>
                    Electives are subjects that changes for each student of a
                    class.
                  </p>
                </div>

                {isStream && (
                  <ElectivesCategory
                    ElectiveCategoryName={"Streams"}
                    methodOnDelete={(data) => {
                      setIsStream(data);
                    }}
                  />
                )}
                {isLanguage && (
                  <ElectivesCategory
                    ElectiveCategoryName={"Languages"}
                    methodOnDelete={(data) => {
                      setIsLanguage(data);
                    }}
                  />
                )}

                <div className="d-flex justify-content-center">
                  {assignButton ? (
                    <>
                      <div
                        onClick={() => setIsStream(true)}
                        className="bg-primary-light text-primary pointer-cursor hover-click p-4 my-4 mx-2 rounded  w-100 d-flex align-items-center gap-2 justify-content-center fs-5 fw-bold">
                        <BiPlus></BiPlus>
                        Stream
                        <AiOutlineQuestionCircle
                          title="Streams / Major / Group"
                          size={16}></AiOutlineQuestionCircle>
                      </div>
                      <div
                        onClick={() => setIsLanguage(true)}
                        className="bg-primary-light text-primary pointer-cursor hover-click p-4 my-4 mx-2 rounded  w-100 d-flex align-items-center gap-2 justify-content-center fs-5 fw-bold">
                        <BiPlus></BiPlus>
                        Languages
                        <AiOutlineQuestionCircle
                          title="Second languages : Ex. Tamil, Hindi, French and etc."
                          size={16}></AiOutlineQuestionCircle>
                      </div>
                    </>
                  ) : (
                    <button
                      className="btn px-4 py-2 my-5 bg-primary fs-5 text-white"
                      onClick={() => setAssignButton(!assignButton)}>
                      Assign Electives
                    </button>
                  )}
                </div>

                {assignButton && (
                  <div className="btn-container d-flex justify-content-center">
                    <button
                      type="button"
                      class="btn btn-outline-primary p-3 px-5 m-3 fw-bold custom-button">
                      Cancel
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary p-3 px-5 m-3"
                      disabled={!(isStream || isLanguage)}>
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Electives;
