import React, { useState, useEffect } from "react";
import admin from "../../assests/images/admin.jpg";
import axios from "axios";

import { useAuth } from "../../context/AuthContext";
import {
  ProfilemainAdmin,
  ProfilebranchAdmin,
} from "../../components/ProfileCard";
import { Container, Row } from "react-bootstrap";

const Roles = ({ setRole }) => {
  const apiHost = process.env.REACT_APP_API_HOST;
  const { state, dispatch } = useAuth();
  const apiUserData = state.user;
  console.log("apiUserData", state.user);
  const [loading, setLoading] = useState(true);
  const [mainAdminData, setMainAdminData] = useState([]);
  const [branchAdminData, setBranchAdminData] = useState([]);

  useEffect(() => {
    fetchUserRoles();
  }, []);

  const fetchUserRoles = async () => {
    try {
      const responseMain = await axios.get(
        `${apiHost}/user/${"3c897b20-29a8-4228-888b-a0f1ac6916a8"}/profiles/school-admin-main`
      );
      const responseBranch = await axios.get(
        `${apiHost}/user/${"3c897b20-29a8-4228-888b-a0f1ac6916a8"}/profiles/school-admin-branch`
      );

      if (responseMain.data.schools) {
        setMainAdminData(responseMain.data.schools);
        // dispatch({ type: 'SET_USER_DATA', payload: { userData: responseMain.data.profiles } });
      } else {
        console.warn("No main admin data found.");
      }
      if (responseBranch.data.schools) {
        setBranchAdminData(responseBranch.data.schools);
        // dispatch({ type: 'SET_USER_DATA', payload: { userData: responseMain.data.profiles } });
      } else {
        console.warn("Branch admin data is missing or not an array.");
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  console.log("mainAdminData", mainAdminData);
  console.log("branchAdminData", branchAdminData);
  // if (mainAdminData.length === 0 && branchAdminData.length === 0) {
  //     return (
  //         <div className='w-100 d-flex align-items-center justify-content-center' style={{ height: "100vh", background: "#fbf9ff" }} id="roles-main-div">
  //             <div className="mx-auto w-100" style={{ background: "#ffffff", padding: "30px 60px", width: "fit-content", borderRadius: "20px" }} id="roles-main-sub-div">
  //                 <h2 className='text-center'>You are not authorized. </h2>
  //                 <p className='text-center'>Please contact your school's Main admin, or Saras customer support.</p>
  //             </div>
  //         </div>
  //     );
  // }
  return (
    <>
      <div
        className="w-100 d-flex align-items-center justify-content-center"
        style={{ height: "100vh", background: "#fbf9ff" }}
        id="roles-main-div"
      >
        <div
          className="mx-auto w-100"
          style={{
            background: "#ffffff",
            padding: "30px 60px",
            width: "fit-content",
            borderRadius: "20px",
          }}
          id="roles-main-sub-div"
        >
          {mainAdminData.length !== 0 && branchAdminData.length !== 0 ? (
            <h5
              className="mb-5 text-center"
              style={{ fontWeight: "700", fontSize: "28px" }}
              id="roles-main-header"
            >
              Select Profile
            </h5>
          ) : (
            <>
              <h2 className="text-center">You are not authorized. </h2>
              <p className="text-center">
                Please contact your school's Main admin, or Saras customer
                support.
              </p>
            </>
          )}

          {loading ? (
            <div> Loading </div>
          ) : (
            <Container fluid>
              <Row className="justify-content-center">
                {mainAdminData.map((maindmin, index) => (
                  <ProfilemainAdmin
                    key={index}
                    schoolName={maindmin.school.schoolName}
                    onClick={() => {
                      setRole("main");
                      //   localStorage.setItem('MainadminProfileData', JSON.stringify(maindmin));
                      dispatch({
                        type: "SET_MAIN_ADMIN_DATA",
                        payload: { mainadminData: maindmin },
                      });
                    }}
                    id="roles-main-card1-div"
                  />
                ))}

                {Array.isArray(branchAdminData) &&
                  branchAdminData.map((branchAdmin, index) =>
                    branchAdmin.branches.map((branch) => (
                      <ProfilebranchAdmin
                        key={index}
                        schoolName={branch.schoolName}
                        branchName={branch.branchName}
                        onClick={() => {
                          setRole("branch");
                          dispatch({
                            type: "SET_BRANCH_ADMIN_DATA",
                            payload: { branchadminData: branchAdmin },
                          });
                        }}
                        id="roles-main-card2-div"
                      />
                    ))
                  )}

                {/* </div> */}
              </Row>
            </Container>
          )}
        </div>
      </div>
    </>
  );
};

export default Roles;
