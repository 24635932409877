import Labelcomponent from "../../../components/Label/Label";
import Inputcomponent from "../../../components/Input/Input";
import { countryCodeList } from "../../../constants"
import add_button from "../../../assests/images/icons/add_button.svg"
import delete_button from "../../../assests/images/icons/delete_button.svg"
import { useState } from "react";


const EmployeeForm = ({type, employeeformdata, employeedata}) =>{
    const [selectedOptions, setSelectedOptions] = useState({
        isOwner: false,
        isMainAdmin: false,
        isBranchAdmin: false,
      });
    const handleInputchange = (e) =>{
        const {name, value, checked } = e.target
        if (name === "owner" || name === "MainAdmin") {
            employeedata((prevdata)=> ({
                ...prevdata,
                [name]:checked
            }))
        } else {
            
        }
        employeedata((prevdata)=> ({
            ...prevdata,
            [name]:value
        }))
    }
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 50;

    const years = Array.from({ length: 51 }, (_, index) => startYear + index);

    const bloodGroups = [
        'A+',
        'A-',
        'B+',
        'B-',
        'AB+',
        'AB-',
        'O+',
        'O-',
    ];
    return(
        <form className="form-horizontal mt-4" >
        <div className="row col-12 mx-auto ">

            <div className="form-group row col-10 mx-auto p-0" >

                <div className="col-4 mt-2" >
                <Labelcomponent  value={"Employee Designation"} />
                  <Inputcomponent name={"designation"} onChange={handleInputchange} value={employeeformdata?.designation} placeholder={"Designation"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

                <div className="col-4 mt-2" >
                <Labelcomponent  value={"Joining date"} />
                  <Inputcomponent name={"joiningDate"} onChange={handleInputchange} value={employeeformdata?.joiningDate} placeholder={""} type={"date"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>
                <div className="col-4 mt-2" >
                   <Labelcomponent  value={"Leaving date"} />
                  <Inputcomponent name={"leavingDate"} onChange={handleInputchange} value={employeeformdata?.leavingDate} placeholder={""} type={"date"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

            </div>
            <div className="form-group row col-10 mx-auto p-0 mt-3" >
                <label className="add-branch-label">Roles and Responsibility</label>
                <div className="col-2 mt-2" >
                    <input checked={selectedOptions.isOwner} name={"owner"} onChange={handleInputchange} value={employeeformdata?.role1} type="checkbox" id="Owner" />
                    <label htmlFor="Owner" className="add-branch-label ms-1" disabled={type === "View" ? true : false}>Owner</label>
                </div>
            </div>
            <div className="form-group row col-10 mx-auto p-0 mt-2" >
                <div className="col-2 mt-2" >
                    <input checked={selectedOptions.isOwner} name={"MainAdmin"} onChange={handleInputchange} value={employeeformdata?.role2} type="checkbox" id="Main Admin" />
                    <label htmlFor="Main Admin" className="add-branch-label ms-1" disabled={type === "View" ? true : false}>Main Admin</label>
                </div>
            </div>
            <div className="form-group row col-10 mx-auto p-0 mt-2" >
                <div className="col-2 mt-4" >
                    <input name={"role3"} onChange={handleInputchange} value={employeeformdata?.role3} type="checkbox" id="Branch Admin" />
                    <label htmlFor="Branch Admin" className="add-branch-label ms-1" disabled={type === "View" ? true : false}>Branch Admin</label>
                </div>
                <div className="col-6" >
                     <Labelcomponent  value={"Select Branch"}  />
                    <Inputcomponent name={"branch"} onChange={handleInputchange} value={employeeformdata?.branch} placeholder={"Field"} type={"text"} className={"form-control ms-1"}  disabled={type === "View" ? true : false}/>
                </div>
                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                    <img src={add_button} alt="add_button" className="btn add-upload-button p-2 pb-0" />
                </div>

                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                    <img src={delete_button} alt="delete_button" className="btn add-upload-button p-2 pb-0 " />
                </div>

            </div>

            <div className="form-group row col-10 mx-auto p-0 mt-3" >
            <Labelcomponent  value={"Education"}  />
                <div className="col-3 mt-2" >
                      <Labelcomponent  value={"Degree/Class"}  />
                    <Inputcomponent name={"eduLevel"} onChange={handleInputchange} value={employeeformdata?.eduLevel} placeholder={"Degree/class"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

                <div className="col-2 mt-2" >
                     <Labelcomponent  value={"Course"}  />
                    <Inputcomponent name={"course"} onChange={handleInputchange} value={employeeformdata?.course} placeholder={"course"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div> 
                <div className="col-3 mt-2" >
                    <Labelcomponent  value={"School / College / University"}  />
                    <Inputcomponent name={"institute"} onChange={handleInputchange} value={employeeformdata?.institute} placeholder={"School / College / University"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

                <div className="col-2 mt-2" >
                <Labelcomponent  value={"Passout year"}  />
                    <select name={"passOutYear"} onChange={handleInputchange} value={employeeformdata?.passOutYear} className="form-select year-dropdown mt-2" disabled={type === "View" ? true : false}>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                    <img src={add_button} alt="add_button" className="btn add-upload-button p-2 pb-0" />
                </div>

                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                    <img src={delete_button} alt="delete_button" className="btn add-upload-button p-2 pb-0 " />
                </div>
            </div>

            {/* <div className="form-group row col-10 mx-auto p-0" >

                <div className="col-4 mt-2" >
                    <label className="add-branch-label">Id Proof name</label>
                    <input type="text" className="form-control add-branch-input" placeholder="Id Proof name" />
                </div>

                <div className="col-4 mt-2" >
                    <label className="add-branch-label">Id proof number</label>
                    <input type="text" className="form-control add-branch-input" placeholder="Id proof number" />
                </div>
                <div className="col-2 mt-2" >
                    <label className="add-branch-label">Id Proof name</label>
                    <button className=' form-control mt-2 mb-2 btn add-new-branch' style={{ width: "70%" }}>Upload</button>
                </div>

                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                    <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ">Add</button>
                </div>

                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                    <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                </div>

            </div>


            <div className="form-group row col-10 mx-auto p-0" >

                <div className="form-group row col-12 mx-auto p-0" >

                    <div className="col-4 mt-2" >
                        <label className="add-branch-label">Id Proof name</label>
                        <input type="text" className="form-control add-branch-input" placeholder="Id Proof name" />
                    </div>

                    <div className="col-4 mt-2" >
                        <label className="add-branch-label">Id proof number</label>
                        <input type="text" className="form-control add-branch-input" placeholder="Id proof number" />
                    </div>
                    <div className="col-2 mt-2" >
                        <label className="add-branch-label">Id Proof name</label>
                        <button className=' form-control mt-2 mb-2 btn add-new-branch' style={{ width: "70%" }}>Upload</button>
                    </div>

                    <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                        <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ">Add</button>
                    </div>

                    <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                        <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                    </div>

                </div>

            </div>




            <div className="form-group row col-10 mx-auto mt-2 p-0" >
                {/* <div className="col-6 mt-2" > 
                <RadioGroup defaultValue='1'>
                    <Radio colorScheme='purple' value='1' className="me-2" >
                        <span className="radio-button-text">Yes,comes by school transportation</span>
                    </Radio>
                    {/* </RadioGroup> */}
            {/* </div> */}
            {/* <div className="col-6 mt-2" > */}
            {/* <RadioGroup defaultValue='2' className="col-6 mt-2"> 
                    <Radio colorScheme='purple' value='2' className="ms-5 me-2"  >
                        <span className="radio-button-text"> No,comes by own transportation</span>
                    </Radio>
                </RadioGroup>
                {/* </div> 
            </div>

            <div className="form-group row col-10 mx-auto p-0" >
                <div className="form-group col-6  mx-auto mt-2" >
                    <label className="add-branch-label"> From </label>
                    <input type="text" className="form-control add-branch-input" placeholder="From (place)" />
                </div>

                <div className="form-group col-6  mx-auto mt-2" >
                    <label className="add-branch-label">To</label>
                    <input type="text" className="form-control add-branch-input" placeholder="To (place)" />
                </div>
            </div> */}

        </div>

    </form >
    )
}
export default EmployeeForm