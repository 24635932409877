import {
    Button,
} from "@chakra-ui/react";

import Modal from 'react-bootstrap/Modal';

import { countryCodeList } from "../../../constants"
import { Checkbox } from '@chakra-ui/react'
import React, { useState } from "react";
import { Radio, RadioGroup } from '@chakra-ui/react'
import bin from "../../../assests/images/icons/bin.svg"
import add_button from "../../../assests/images/icons/add_button.svg"
import delete_button from "../../../assests/images/icons/delete_button.svg"
import Inputcomponent from '../../../components/Input/Input'
import Labelcomponent from "../../../components/Label/Label";
import axios from "axios";
import { ArrowBackIcon } from "@chakra-ui/icons";
import ButtonComponent from "../../../components/Button/Button";
import PersonalForm from "./PersonalForm";
import ContactForm from "./ContactForm";
import EmployeeForm from "./EmployeeForm";
import { useAuth } from "../../../context/AuthContext";

const AddProfile = ({ setAddProfile, teacherData, onSave, type, onCancel, onEdit }) => {

    const [activeTab, setActiveTab] = useState("personal")
    const [teacherFormData, setTeacherFormData] = useState(teacherData || "");
    const [model, setModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [personalformdata, setpersonalFormData] = useState();
    const [contactformdata, setcontactFormData] = useState();
    const [employeeformdata, setemployeeFormData] = useState();
    const {state} = useAuth()
    const schoolname = state.mainadminData.school.schoolName;
    const schoolId = state.mainadminData.user.schoolId;
    const apiHost = process.env.REACT_APP_API_HOST;

    const handleChange = (e) => {
        const { name, value } = e.target;

        setTeacherFormData((prevTeacherData) => ({
            ...prevTeacherData,
            [name]: value,
        }));
    };

  

    const handleCancel = () => {
        setTeacherFormData(teacherFormData);
        // setAddProfile(false);
        onCancel();
    };
    const handleEdit = () => {

        onEdit();
    }
    
    const handlePersonalData  = (data) => {
        setpersonalFormData(data)
    }
    console.log("personalformdata", personalformdata);

    const handleContactData  = (data) => {
        setcontactFormData(data)
    }
    console.log("contactformdata", contactformdata);

    const handleEmployeeData  = (data) => {
        setemployeeFormData(data)
    }
    console.log("employeeformdata", employeeformdata);

   
    const handleSubmit =async (e) => {
        e.preventDefault();
        const user = {
            firstName: personalformdata?.firstName,
            lastName: personalformdata?.lastName,
            dateOfBirth: personalformdata?.dateOfBirth,
            gender: personalformdata?.gender || "male",
            bloodGroup: personalformdata?.bloodGroup,
            photoUrl: personalformdata?.photoUrl,
            phoneIsdCode:contactformdata?.phoneIsdCode || +91,
            phoneNumber:parseInt(contactformdata?.phoneNumber),
            email:contactformdata?.email,
            schoolId:schoolId,
        }
        const address ={
            doorNumber: contactformdata?.doorNumber,
            line1: contactformdata?.line1,
            line2: contactformdata?.line2,
            landmark: contactformdata?.landmark,
            city: contactformdata?.city,
            state: contactformdata?.state,
            pincode: contactformdata?.pincode,
            country: contactformdata?.country,
        }
        const employee ={
            schoolId:schoolId,
            designation:employeeformdata?.designation,
            joiningDate:employeeformdata?.joiningDate,
            leavingDate:employeeformdata?.leavingDate,
    
        }
        const eduQualifications =[{
            eduLevel:employeeformdata?.eduLevel,
            course:employeeformdata?.course,
            institute:employeeformdata?.institute,
            passOutYear:parseInt(employeeformdata?.passOutYear)
        }]

        const relatives = [{
            name:contactformdata?.personname,
            relationship: contactformdata?.relationname,
            phoneNumber:parseInt(contactformdata?.realationphoneIsdCode || +91) + contactformdata?.realationphoneNumber
        }]

        const roles = [{
            role:employeeformdata?.role,
            
        }]
     const createEmployee = {
        user:user,
        address:address,
        employee:employee,
        eduQualifications:eduQualifications,
        relatives:relatives,
        roles:roles
     }
        //setAddProfile(false);
        // onSave(teacherFormData);
        try {
            await axios.post(`${apiHost}/employee`, createEmployee)
            console.log("Employee Created");
             setAddProfile("");
        } catch (error) {
            console.error(error)
        }
        
    };

    // console.log("countryCodeList", countryCodeList)


    return (


        <div className="mt-3 pb-5" >

            <div class="d-flex col-12 align-items-end">

                <div class="d-flex col-6 align-items-center">
                    <h1 class="add-branch-title ms-3">Employees</h1>
                    <h1 class="add-branch-subtitle ms-3">{" > "} {type}</h1>
                </div>


                {/* <div class="d-flex col-6 justify-content-end align-items-center">
                    <label for="inputGroupSelect" class="teacher-campus-select">Academic Year</label>
                    &nbsp;
                    <input type="text" className="custom-select text-center" placeholder="2003-2004" disabled />
                </div> */}
            </div>



            <div className="row col-12 p-0 mx-auto mt-3">
                <Button className={`col-4 add-branch-header-tab${activeTab === "personal" ? "-active" : ""}`} onClick={() => setActiveTab("personal")}>Personal</Button>
                <Button className={`col-4 add-branch-header-tab${activeTab === "contact" ? "-active" : ""}`} onClick={() => setActiveTab("contact")}>Contact</Button>
                <Button className={`col-4 add-branch-header-tab${activeTab === "employee" ? "-active" : ""}`} onClick={() => setActiveTab("employee")}>Employee Information</Button>

            </div>
                {activeTab === "personal" &&<PersonalForm type={type} personalformdata={personalformdata} employeedata={handlePersonalData}/>}
                {activeTab === "contact" && <ContactForm type={type} contactformdata={contactformdata} employeedata={handleContactData}/> }
                {activeTab === "employee" &&<EmployeeForm type={type} employeeformdata={employeeformdata} employeedata={handleEmployeeData}/> }

            {type === "Add" || type === "Edit" ?
                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <ButtonComponent onClick={() => setModel(true)} className={'cancelbtn me-2'} value={"cancel"}/>
                        {/* <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={() => setModel(true)}>Cancel</button> */}
                        {activeTab === "personal" ?
                            <ButtonComponent  onClick={() => setActiveTab("contact")} className={'savebtn me-2'} value={"Continue"}/>
                            // <button className='me-2 btn button-1' style={{ width: "95px" }} onClick={() => setActiveTab("contact")}>Continue</button>
                            :
                            activeTab === "contact" ?
                            <ButtonComponent  onClick={() => setActiveTab("employee")} className={'savebtn me-2'} value={"Continue"}/>
                                // <button className='me-2 btn button-1' style={{ width: "95px" }} onClick={() => setActiveTab("employee")}>Continue</button>
                                :
                                <ButtonComponent  onClick={handleSubmit} className={'savebtn me-2'} value={"save"}/>
                                // <button className='me-2 btn button-1' style={{ width: "90px" }} onClick={handleSubmit}>Save</button>
                        }
                    </div>
                </div>
                :
                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <Button className='me-3 left-arrow-button' variant="link" leftIcon={<ArrowBackIcon />} style={{ width: "90px" }} onClick={() => setAddProfile("")}>Back</Button>
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={handleEdit}>Edit</button>
                        <Button className='me-3 text-delete-button' variant="link" style={{ width: "90px" }} onClick={() => setDeleteModel(true)}>Delete</Button>
                    </div>
                </div>
            }



            <Modal
                show={model}
                size="lg"
                animation
                centered
                onHide={() => setModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <h2 className='welcome-title'>Are you sure you want to cancel?</h2>
                        <h5 className='welcome-subtext'>Any unsaved changes will be lost</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setModel(false)}>No, I want to continue Editing</button>
                                <button className='me-2 btn popup-cancel-button-outline ' onClick={handleCancel}>Yes I Want to cancel it</button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>
            <Modal
                show={deleteModel}
                size="lg"
                animation
                centered
                onHide={() => setDeleteModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <img src={bin} alt="delete" className="mx-auto mb-3 " style={{ height: "70px", borderRadius: "30px" }} />
                        <h2 className='welcome-title mb-3'>Are you sure you want to delete?</h2>
                        <h5 className='welcome-subtext'>This action is irreversible and will permanently</h5>
                        <h5 className='welcome-subtext'> remove the item from the system.</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setDeleteModel(false)} style={{ width: "250px " }}>No, Keep it</button>
                                <button className='me-2 btn popup-cancel-button-outline ' onClick={() => alert("Deleting")} style={{ width: "250px " }}>Yes, Delete it</button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>


        </div >

    )
}

export default AddProfile