import './Input.css'


const Inputcomponent  = ({type, name = null, onChange = null, placeholder, className, disabled = false,important, value, id , accept = null}) =>{
    return(
        <>
    <input type={type} name={name} onChange={onChange} placeholder={placeholder} className={className} value={value} id={id} disabled={disabled} accept={accept}/>  
    </>
    )
}

export default Inputcomponent