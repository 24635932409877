import React, { useState, useEffect } from "react";

import Header from "../../../components/Header";

import people from "../../../assests/images/people_icon.png";

import axios from "axios";
import { apiUrl } from "../../../constants";
import {
  Box,
  HStack,
  Input,
  InputLeftElement,
  Text,
  Wrap,
  WrapItem,
  InputGroup,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Icon,
} from "@chakra-ui/react";

import { SearchIcon, SmallAddIcon } from "@chakra-ui/icons";
import DataTable from "./DataTable";
import BranchForm from "./BranchForm";

const BranchAdminBranches = () => {
  const [allBranchesData, setAllBranchesData] = useState([]);
  const [branchForm, setBranchForm] = useState("");
  const [branchData, setBranchData] = useState({});

  useEffect(() => {
    // Fetch All Branch data from API and update the formData state
    fetchAllBranchData().then((data) => {
      setAllBranchesData(data);
    });

    // fetchBranchData().then((data) => {
    //     setBranchData(data);
    // });
  }, []);

  const handleCancel = () => {
    // Reset the form data to the initial state
    setBranchForm("");
    setBranchData(branchData);
  };

  const handleEdit = () => {
    setBranchForm("Edit");
  };

  const handleSave = (newBranchData) => {
    // Call the API to save the updated data
    setBranchForm("");
    saveBranchData(newBranchData).then(() => {
      // Handle any success actions
    });
  };

  const saveBranchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}`); // Replace with your API endpoint
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllBranchData = async () => {
    try {
      const allBranchApiData = await axios.get(`${apiUrl}branch?schoolId=2`);
      console.log("Branch Data", allBranchApiData.data);

      // const addressData = await axios.get(`${apiUrl}address/${branchApiData.data.addressId}`); // Replace with your API endpoint
      // console.log("addressData ", branchApiData.data)
      // const apiData = {
      //     branchName: branchApiData.data.name
      // }
      // setBranchData({
      //     branchName: branchData.data.name,

      // })
      return allBranchApiData.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  const handleBranchType = (e, id) => {
    setBranchForm(e);
  };

  if (branchForm !== "") {
    return (
      <div
        className="w-100 d-flex flex-column"
        style={{ background: "#fbf9ff" }}
      >
        <Header />

        <div
          className="ms-3"
          style={{
            background: "#ffffff",
            padding: "10px",
            borderRadius: "15px",
            width: "95%",
            height: "auto",
            border: "1px solid #95A1BE",
          }}
        >
          <BranchForm
            type={branchForm}
            setBranchForm={setBranchForm}
            branchData={branchData}
            onSave={handleSave}
            onCancel={handleCancel}
            onEdit={handleEdit}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 d-flex flex-column" style={{ background: "#fbf9ff" }}>
      <Header title={"Branch Admin"} />

      {/* SUB HEADER */}
      <div className="ms-3">
        <h1 className="subheader-title ms-4">School Branches </h1>
      </div>
      <div className="d-flex mb-4 mt-3">
        <div className="ms-3 d-flex align-items-center me-2">
          <h5 className="ms-4 subheader me-3 my-auto add-teacher-subtitle">
            Branch
          </h5>
          <select
            className="dropdown-select"
            disabled
            style={{ cursor: "not-allowed" }}
          >
            <option selected>Branch name</option>
          </select>
        </div>

        <div className="ms-3 d-flex align-items-center">
          <h5 className="ms-4 subheader me-3 my-auto add-teacher-subtitle">
            Academic year
          </h5>
          <select
            className="dropdown-select"
            disabled
            style={{ cursor: "not-allowed" }}
          >
            <option selected>2023-2024</option>
            <option value="1">2024-2025</option>
          </select>
        </div>
      </div>

      {/* SUB HEADER 1 */}

      <div className="col-11 ms-3 mt-2">
        <div
          className="ms-4"
          style={{
            background: "#ffffff",
            padding: "10px",
            borderRadius: "25px",
            border: "1px solid #d9d9d9",
          }}
        >
          <h4 className="branch-header ms-3 mt-3">Branch List</h4>

          <div className="ms-3 mt-4 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <h6 className="me-2 mb-0 text-small-1">Show</h6>
              <select className="branch-select">
                <option selected>10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>
              <h6 className="ms-2 mb-0 text-small-1">entries</h6>
            </div>

            {/* <div className='d-flex me-5' >
                            <Button className='add-new-branch' size="sm" onClick={() => setBranchForm("Add")}>
                                <SmallAddIcon boxSize={6} /> Add New
                            </Button>
                        </div> */}
          </div>

          <div className="ms-3 mb-4">
            <DataTable
              allBranchesData={allBranchesData}
              setBranchType={handleBranchType}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchAdminBranches;
