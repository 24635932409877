import React, { useState, useEffect } from "react";
// //Custom css files

import "./App.css";
import "../src/assests/css/sidebar.css";
import "../src/assests/css/styles.css";
import "../src/assests/css/header.css";
import "../src/assests/css/branches.css";
import "../src/assests/css/employees.css";
import "../src/assests/css/bootstrap.min.css";
import "../src/assests/css/custom.css";
import "../src/assests/css/template.css";

import { Routes, Route, Navigate } from "react-router-dom";

import Roles from "./pages/Roles/Roles";
import Onboarding from "./pages/Onboarding/Onboarding";

// COMPONENTS
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

// PAGE COMPONENTS
import MainAdminDashboard from "./pages/Main Admin/Dashboard/MainAdminDashBoard";
import MainAdminSideBar from "./pages/Main Admin/SideBar/MainAdminSideBar";
import MainAdminBranches from "./pages/Main Admin/Branches/MainAdminBranches";
import EmployeeProfiles from "./pages/Main Admin/Employees/EmployeeProfiles";

import BranchAdminSideBar from "./pages/Branch Admin/SideBar/BranchAdminSideBar";
import BranchAdminDashboard from "./pages/Branch Admin/Dashboard/BranchAdminDashBoard";
import BranchAdminBranches from "./pages/Branch Admin/Branches/BranchAdminBranches";
import BranchProfiles from "./pages/Branch Admin/Employees/BranchProfiles";
import StudentsProfiles from "./pages/Branch Admin/Students/StudentsProfiles";
import ClassSection from "./pages/Branch Admin/Class_Section/ClassSection";
import PeriodTable from "./pages/Branch Admin/PeriodTable/PeriodTable";
import Electives from "./pages/Branch Admin/Electives/Electives";
import Adminprofile from "./pages/Main Admin/Profile/Adminprofile";
import BranchAdminprofile from "./pages/Branch Admin/Profile/Branchadminprofile";

import UnderConstruction from "./pages/under-construction/UnderConstruction";
import Calender from "./pages/Branch Admin/Calender/Calender";
import Login from "./pages/LoginPage/Login";
// import ManageTeachers from './pages/employees/ManageTeachers';
// import ManageSupport from './pages/employees/ManageSupport';
// import ManageOwners from './pages/owners/ManageOwners';

import { Amplify, Auth } from "aws-amplify";
import { useAuth } from "./context/AuthContext";

function App() {
  const { state, dispatch } = useAuth();
  const [role, setRole] = useState("");
  // const [schoolId, setschoolId] = useState('')

  Amplify.configure({
    Auth: {
      production: "false",
      region: "ap-south-1",
      userPoolId: "ap-south-1_o82kRXZGg",
      userPoolWebClientId: "7cff8l881modr5ndum48u4fgj4",
      authenticationFlowType: "CUSTOM_AUTH",
    },
  });

  useEffect(() => {
    checkUserSignedIn();
  }, []);

  const checkUserSignedIn = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // console.log('Signed-in user:', user);
      localStorage.setItem("userData", JSON.stringify(user.signInUserSession));
      dispatch({
        type: "LOGIN",
        payload: {
          user: user.signInUserSession, // Store user details if needed
        },
      });
    } catch (error) {
      console.log("No signed-in user:", error);
    }
  };

  // if (!state.isLoggedIn) {
  //   return (
  //     <div className="App">
  //       <div className='sidebar-container'>
  //         <Routes>
  //           <Route path="/login" element={<Login />} />
  //           <Route path="*" element={<Navigate to="/login" />} />
  //         </Routes>
  //       </div>
  //     </div>
  //   );
  // }

  if (!state.isLoggedIn) {
    return (
      <div className="App">
        <div className="sidebar-container">
          {role === "" ? (
            <Routes>
              <Route
                path="/select-profile"
                element={<Roles setRole={setRole} />}
              />
              <Route path="*" element={<Navigate to="/select-profile" />} />
            </Routes>
          ) : role === "main" ? (
            <>
              <MainAdminSideBar />
              <br />
              <Routes>
                <Route path="/dashboard" element={<MainAdminDashboard />} />
                <Route path="/branches" element={<MainAdminBranches />} />
                <Route
                  path="/employees/profiles"
                  element={<EmployeeProfiles />}
                />
                <Route
                  path="/under-construction"
                  element={<UnderConstruction />}
                />
                <Route path="/admin-profile" element={<Adminprofile />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </>
          ) : role === "branch" ? (
            <>
              <BranchAdminSideBar />
              <br />
              <Routes>
                <Route path="/dashboard" element={<BranchAdminDashboard />} />
                <Route path="/branches" element={<BranchAdminBranches />} />
                <Route
                  path="/employees/profiles"
                  element={<BranchProfiles />}
                />
                <Route
                  path="/students/profiles"
                  element={<StudentsProfiles />}
                />
                <Route path="/settings/class" element={<ClassSection />} />
                <Route path="/settings/period" element={<PeriodTable />} />
                <Route path="/settings/electives" element={<Electives />} />
                <Route path="/community/calender" element={<Calender />} />
                <Route
                  path="/under-construction"
                  element={<UnderConstruction />}
                />
                <Route
                  path="/Branchadmin-profile"
                  element={<BranchAdminprofile />}
                />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default App;

// const [role, setRole] = useState("main")
// const [isNewUser, setIsNewUser] = useState(false);
// const [isAuthenticated, setIsAuthenticated] = useState();

// const [userData,] = useState(JSON.parse(localStorage.getItem("user-data")))

// useEffect(() => {
//   checkUserSignedIn();
// }, [isAuthenticated])

// useEffect(() => {
//   console.log("App.js Use Effect Called");
// }, [userData])

// const checkUserSignedIn = async () => {
//   try {
//     const user = await Auth.currentAuthenticatedUser();
//     console.log('Signed-in user:', user);
//     localStorage.setItem("user-data", JSON.stringify(user.signInUserSession));
//     setIsAuthenticated(true);
//   } catch (error) {
//     console.log('No signed-in user:', error);
//   }
// };

//   if (!isAuthenticated) {
//     // If it's a new user, show the Login Page
//     return (
//       <div className="App">
//         <div className='sidebar-container'>
//           <Routes>
//             <Route path="/login" element={<Login setIsAuthenticated={(e) => setIsAuthenticated(e)} />} />
//             <Route path="*" element={<Navigate to="/login" />} />
//           </Routes>
//         </div>
//       </div>
//     );
//   }

//   if (isNewUser) {
//     // If it's a new user, show the OnboardingPage
//     return (
//       <div className="App">
//         <div className='sidebar-container'>
//           <Routes>
//             <Route path="/onboarding" element={<Onboarding setOnboardingCompleted={(e) => setIsNewUser(e)} />} />
//             <Route path="*" element={<Navigate to="/onboarding" />} />
//           </Routes>
//         </div>
//       </div>
//     );
//   }

//   if (!role) {
//     return <Roles setRole={setRole} />;
//   }

//   return (
//     <div className="App" >
//       <div className='sidebar-container'>

//         {role === "main" ?
//           <>
//             <MainAdminSideBar /><br />
//             <Routes>
//               <Route path="/dashboard" element={<MainAdminDashboard />} />
//               <Route path="/branches" element={<MainAdminBranches />} />
//               <Route path="/employees/profiles" element={<EmployeeProfiles />} />
//               <Route path="/under-construction" element={<UnderConstruction />} />
//               <Route path="*" element={<Navigate to="/dashboard" />} />
//             </Routes>

//           </>
//           :
//           <>
//             <BranchAdminSideBar />
//             <br />
//             <Routes>
//               <Route path="/dashboard" element={<BranchAdminDashboard />} />
//               <Route path="/branches" element={<BranchAdminBranches />} />
//               <Route path="/employees/profiles" element={<BranchProfiles />} />
//               <Route path="/students/profiles" element={<StudentsProfiles />} />
//               <Route path="/settings/class" element={<ClassSection />} />
//               <Route path="/settings/period" element={<PeriodTable />} />
//               <Route path="/settings/electives" element={<Electives />} />
//               <Route path="/community/calender" element={<Calender />} />
//               <Route path="/under-construction" element={<UnderConstruction />} />
//               <Route path="*" element={<Navigate to="/dashboard" />} />
//             </Routes>
//           </>
//         }

//       </div>
//     </div>
//   );
// }
