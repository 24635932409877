import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  HStack,
  Input,
  InputLeftElement,
  Text,
  Wrap,
  WrapItem,
  InputGroup,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer, IconButton, Icon
} from "@chakra-ui/react";

// icons
import eye from "../../../assests/images/icons/eye.svg";
import bin from "../../../assests/images/icons/bin.svg";
import main from "../../../assests/images/icons/main.svg"
import pencil from "../../../assests/images/icons/pencil.svg";

import { MdCompareArrows } from "react-icons/md"
// import { RiDeleteBinLine, RiEditLine, RiEyeLine } from 'react-icons/ri'

const DataTable = ({ Employeelist, setProfileType }) => {

console.log("Employeelist", Employeelist);

  const handleDelete = (id) => {
    alert(`Deleting item with id: ${id}`);
  };

  return (
    <TableContainer className="custom-scrollbar mt-3">
      <Table variant="simple" size="sm" style={{ textAlign: "center" }}>
        <Tbody fontSize="sm" padding={0}>
          <Tr className='branch-data-table-header'>
            <Td>Name<Icon as={MdCompareArrows} transform="rotate(90deg)" /></Td>
            <Td>Phone Number</Td>
            <Td>Roles</Td>
            <Td>Action</Td>
          </Tr>
          {Array.isArray(Employeelist) && Employeelist.map((item) =>
            <Tr className='branch-data-table' size="sm" key={item.no}>
              <Td>{item.fullName}</Td>
              <Td>{item.phoneIsdCode} {item.phoneNumber}</Td>

              <Td >
                <div className='d-flex'>
                  {item.employeeRoles === "Main Admin" ? <span className='roles-data' style={{ background: "#4267B21A", color: '#4267B2' }} >Main Admin</span> : ""}
                  {item.employeeRoles === "Branch Admin" ? <span className='roles-data ' style={{ background: "#0080801A", color: '#008080' }}>Branch Admin</span> : ""}
                  {item.employeeRoles === "Owner" ? <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB' }}>Owner</span> : ""}
                </div>
              </Td>
              <Td>
                <div className='d-flex'>
                  <img className='me-2 icon' src={eye} alt="View" onClick={() => setProfileType("View", item.id)} />
                  <img className='me-2 icon' src={pencil} alt="Edit" onClick={() => setProfileType("Edit", item.id)} />
                  <img className='me-2 icon' src={bin} alt="Delete" onClick={() => handleDelete("id")} />
                </div>
              </Td>
            </Tr>
          )}

        </Tbody>
      </Table>
    </TableContainer >
  )
}

export default DataTable