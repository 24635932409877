import {
    Button,
} from "@chakra-ui/react";

import Modal from 'react-bootstrap/Modal';

import { Checkbox } from '@chakra-ui/react'

import { ArrowBackIcon } from "@chakra-ui/icons";
import React, { useState } from "react";

const BranchForm = ({ setBranchForm, type, branchData, onSave, onCancel, onEdit }) => {

    const [branchFormData, setBranchFormData] = useState(branchData || "");
    const [model, setModel] = useState(false);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     setBranchFormData((prevBranchData) => ({
    //         ...prevBranchData,
    //         [name]: value,
    //     }));
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // setBranchForm(false);
    //     onSave(branchFormData);
    // };

    // const handleCancel = () => {
    //     setBranchFormData(branchFormData);
    //     // setBranchForm(false);
    //     onCancel();
    // };

    // const handleEdit = () => {

    //     onEdit();
    // }




    return (


        <div className="mt-3 pb-5" >
            <div className='d-flex align-items-end'>
                <h1 className='add-branch-title ms-3'>Branch list</h1>
                <h1 className='add-branch-subtitle ms-3'>{">"}{type}</h1>
            </div>

            <form className="form-horizontal mt-4" >

                <div className="row col-12 mx-auto ">

                    <div className="form-group col-10  mx-auto" >
                        <label className="add-branch-label"> Branch  Name <span style={{ color: "red" }}> * </span></label>
                        <input type="text" className="form-control add-branch-input" placeholder="Branch Name" disabled />
                    </div>

                    <div className="form-group col-10  mx-auto mt-2" >
                        <label className="add-branch-label"> Branch  Board </label>
                        <input type="text" className="form-control add-branch-input" placeholder="Branch Board" disabled />
                    </div>

                    <div className="form-group row col-10 mx-auto p-0" >
                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">Phone number </label>
                            <input type="text" className="form-control add-branch-input" placeholder="9999999999" disabled />
                        </div>

                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">Email </label>
                            <input type="text" className="form-control add-branch-input" placeholder="example@gmail.com" disabled />
                        </div>
                    </div>

                    <div className="form-group row col-10 mx-auto p-0" >
                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">Door no.</label>
                            <input type="text" className="form-control add-branch-input" placeholder="14" disabled />
                        </div>

                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">Address Line 1</label>
                            <input type="text" className="form-control add-branch-input" placeholder="Street Name" disabled />
                        </div>
                    </div>

                    <div className="form-group row col-10 mx-auto p-0" >

                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">Address Line 2</label>
                            <input type="text" className="form-control add-branch-input" placeholder="Area Name" disabled />
                        </div>

                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">Landmark</label>
                            <input type="text" className="form-control add-branch-input" placeholder="Church" disabled />
                        </div>

                    </div>

                    <div className="form-group row col-10 mx-auto p-0" >

                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">City</label>
                            <input type="text" className="form-control add-branch-input" placeholder="City" disabled />
                        </div>

                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">State / Province / Region</label>
                            <input type="text" className="form-control add-branch-input" placeholder="State Name" disabled />
                        </div>

                    </div>

                    <div className="form-group row col-10 mx-auto p-0" >

                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">PIN / ZIP / Postal Code</label>
                            <input type="text" className="form-control add-branch-input" placeholder="600001" disabled />
                        </div>

                        <div className="col-6 mt-2" >
                            <label className="add-branch-label">Country</label>
                            <input type="text" className="form-control add-branch-input" placeholder="India" disabled />
                        </div>

                    </div>

                    <div className="form-group col-10 mt-2  mx-auto" >
                        <label className="add-branch-label"> Branch  Logo </label>
                        <div className="d-flex">
                            <input type="text" className="form-control add-branch-input me-3" placeholder="" disabled />
                            <button className='mt-2 mb-2 btn add-new-branch'>Upload</button>
                        </div>
                    </div>
                </div>
            </form >

            {/* {type === "Add" || type === "Edit" ? */}

            <div className="row col-10 p-0 mx-auto mt-3 ">
                <div className="d-flex justify-content-center mt-3">
                    <Button className='me-3 left-arrow-button' variant="link" leftIcon={<ArrowBackIcon />} style={{ width: "90px" }} onClick={() => setBranchForm("")}>Back</Button>
                </div>
            </div>
            {/* 
                    <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={() => setModel(true)}>Cancel</button> */}
            {/* <button className='me-2 btn button-1' style={{ width: "90px" }} onClick={handleSubmit}>Save</button> */}
            {/* :
                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <Button className='me-3 left-arrow-button' variant="link" leftIcon={<ArrowBackIcon />} style={{ width: "90px" }} onClick={() => setBranchForm("")}>Back</Button>
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={handleEdit}>Edit</button>
                        <Button className='me-3 text-delete-button' variant="link" style={{ width: "90px" }} onClick={() => alert("Deleting item")}>Delete</Button>
                    </div>
                </div> */}
            {/* } */}



            <Modal
                show={model}
                size="lg"
                animation
                centered
                onHide={() => setModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <h2 className='welcome-title'>Are you sure you want to cancel?</h2>
                        <h5 className='welcome-subtext'>Any unsaved changes will be lost</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setModel(false)} style={{ width: "250px " }}>No, I want to continue filling</button>
                                {/* <button className='me-2 btn popup-cancel-button-outline ' onClick={handleCancel} style={{ width: "250px " }}>Yes I want to cancel it</button> */}
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>



        </div >

    )
}

export default BranchForm