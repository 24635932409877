import {
    Button,
} from "@chakra-ui/react";

import Modal from 'react-bootstrap/Modal';

import { Checkbox } from '@chakra-ui/react'

import { ArrowBackIcon } from "@chakra-ui/icons";
import React, { useState } from "react";

import add_button from "../../../assests/images/icons/add_button.svg"
import delete_button from "../../../assests/images/icons/delete_button.svg"

const ClassFormData = ({ setBranchAddProfile, type, branchData, onSave, onCancel, onEdit }) => {

    const [branchFormData, setBranchFormData] = useState(branchData || "");
    const [model, setModel] = useState(false);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     setBranchFormData((prevBranchData) => ({
    //         ...prevBranchData,
    //         [name]: value,
    //     }));
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        // setBranchForm(false);
        onSave(branchFormData);
    };

    const handleCancel = () => {
        setBranchFormData(branchFormData);
        onCancel();
    };

    const handleEdit = () => {

        onEdit();
    }




    return (


        <div className="mt-3 pb-5" >
            <div className='d-flex align-items-end'>
                <h1 className='add-branch-title ms-3 mb-0'>Class & Section</h1>
                <h1 className='add-branch-subtitle ms-2 mb-0'>{">"}{type}</h1>
            </div>

            <form className="form-horizontal mt-4" >

                <div className="row col-12 mx-auto ">

                    <div className="form-group col-10  mx-auto mt-3" >
                        <label className="add-branch-label"> Class / Grade  Name <span style={{ color: "red" }}> * </span></label>
                        <input type="text" className="form-control add-branch-input" placeholder="Ex. 5" />
                    </div>

                    <div className="form-group col-10  mx-auto mt-3" >
                        <label className="add-branch-label"> Section 1  Name <span style={{ color: "red" }}> * </span></label>
                        <div className="d-flex">
                            <div className="col-9 me-3">
                                <input type="text" className="form-control add-branch-input" placeholder="Ex. A" />
                            </div>
                            <div className="col-3 mt-2 d-flex">
                                <img src={add_button} alt="add_button" className="ms-3 me-4" />
                                <img src={delete_button} alt="delete_button" />
                            </div>
                        </div>
                    </div>

                    <div className="form-group col-10  mx-auto mt-3" >
                        <label className="add-branch-label"> Section 1  Name <span style={{ color: "red" }}> * </span></label>
                        <div className="d-flex">
                            <div className="col-9 me-3">
                                <input type="text" className="form-control add-branch-input" placeholder="Ex. A" />
                            </div>
                            <div className="col-3 mt-2 d-flex">
                                <img src={add_button} alt="add_button" className="ms-3 me-4" />
                                <img src={delete_button} alt="delete_button" />
                            </div>
                        </div>
                    </div>


                </div>
            </form >

            {type === "Add" || type === "Edit" ?

                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={() => setModel(true)}>Cancel</button>
                        <button className='me-2 btn button-1' style={{ width: "90px" }} onClick={handleSubmit}
                        >Save</button>
                    </div>
                </div>
                :
                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <Button className='me-3 left-arrow-button' variant="link" leftIcon={<ArrowBackIcon />} style={{ width: "90px" }} onClick={() => setBranchAddProfile("")}>Back</Button>
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={handleEdit}>Edit</button>
                        <Button className='me-3 text-delete-button' variant="link" style={{ width: "90px" }}
                        // onClick={() => setDeleteModel(true)}
                        >Delete</Button>
                    </div>
                </div>
            }



            <Modal
                show={model}
                size="lg"
                animation
                centered
                onHide={() => setModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <h2 className='welcome-title'>Are you sure you want to cancel?</h2>
                        <h5 className='welcome-subtext'>Any unsaved changes will be lost</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setModel(false)} style={{ width: "250px " }}>No, I want to continue filling</button>
                                <button className='me-2 btn popup-cancel-button-outline ' onClick={handleCancel} style={{ width: "250px " }}>Yes I want to cancel it</button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>



        </div >

    )
}

export default ClassFormData