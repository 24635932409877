import defaultimg from '../assests/images/people_icon.png'
import people from "../assests/images/people_icon.png"
import {Col} from 'react-bootstrap'


export const ProfilemainAdmin = ({schoolName, logo, onClick, id }) =>{
    console.log("schoolName", schoolName);
    const defaultimage = defaultimg;
    return(
        <>
          <Col md={4} lg={3}>
             <div className="p-4 me-3 w-100" style={{ border: "1px solid #A3AED0", borderRadius: "10px", cursor: "pointer", height: "300px"}}  onClick={onClick} id={id}>
                            <div className="admin-image-div mx-auto" id="roles-main-card1-sub-div-1">
                                <img src={logo || defaultimage} alt="Main Admin" className="admin-image img-fluid" id="roles-main-card1-sub-div-img" />
                            </div>
                            <div className="admin-text text-center mt-5 mx-auto" id="roles-main-card1-sub-div-2">
                                <h5 style={{ fontWeight: "800", fontSize: "24px" }} id="roles-main-card1-text">Main admin</h5>
                                <p className="m-0 data-card-title mt-1 " id="roles-main-card1-sub-text">{schoolName}</p>
                            </div>
                        </div>
                        </Col>
        </>
    )
}

export const ProfilebranchAdmin = ({schoolName, logo, branchName, onClick, id}) =>{
    return(
        <>
            <Col md={4} lg={3}>
             <div className="p-4 mb-3 w-100"  style={{ border: "1px solid #A3AED0", borderRadius: "10px", cursor: "pointer", height: "300px" }} onClick={onClick} id={id}>
                            <div className="admin-image-div mx-auto" id="roles-main-card1-sub-div-1">
                                <img src={logo || defaultimg} alt="Branch Admin" className="admin-image img-fluid" id="roles-main-card1-sub-div-img" />
                            </div>
                            <div className="admin-text text-center mt-5 mx-auto" id="roles-main-card2-sub-div-2">
                                <h5 style={{ fontWeight: "800", fontSize: "24px" }} id="roles-main-card2-text">Branch admin</h5>
                                <p className='data-card-admin mb-0' id="roles-main-card2-sub-text-1">{branchName}</p>
                                <p className="m-0 data-card-title mt-1" id="roles-main-card2-sub-text-2">{schoolName}</p>
                            </div>
                        </div>
                        </Col>
    
        </>
    )
}

//  Dashboard Card


const defaultsvg = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4492 10.8184C20.0476 11.9034 21.1676 13.3734 21.1676 15.3334V18.8334H24.6676C25.3092 18.8334 25.8342 18.3084 25.8342 17.6667V15.3334C25.8342 12.79 21.6692 11.285 18.4492 10.8184Z" fill="white"/>
        <path d="M9.4987 9.50008C12.076 9.50008 14.1654 7.41074 14.1654 4.83342C14.1654 2.25609 12.076 0.166748 9.4987 0.166748C6.92137 0.166748 4.83203 2.25609 4.83203 4.83342C4.83203 7.41074 6.92137 9.50008 9.4987 9.50008Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5009 9.50008C19.0792 9.50008 21.1676 7.41175 21.1676 4.83342C21.1676 2.25508 19.0792 0.166748 16.5009 0.166748C15.9526 0.166748 15.4392 0.283414 14.9492 0.446748C15.9176 1.64841 16.5009 3.17675 16.5009 4.83342C16.5009 6.49008 15.9176 8.01842 14.9492 9.22008C15.4392 9.38342 15.9526 9.50008 16.5009 9.50008Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5013 10.6667C6.3863 10.6667 0.167969 12.2301 0.167969 15.3334V17.6667C0.167969 18.3084 0.692969 18.8334 1.33464 18.8334H17.668C18.3096 18.8334 18.8346 18.3084 18.8346 17.6667V15.3334C18.8346 12.2301 12.6163 10.6667 9.5013 10.6667Z" fill="white"/>
      </svg>
    )
}
export const AdminDashboardCard = ({title, data,icon = defaultsvg()}) =>{
        return(
            <div className='data-card d-flex'>
            <div className='w-10'>
                {/* <img src={icon || people} alt="icon" /> */}
                <div className='svg-bg d-flex align-items-center justify-content-center'>
                {icon}
                </div>
            </div>
            <div className='ms-2 d-flex flex-column align-items-center w-100' >
                <h5 className="data-card-title">{title}</h5>
                <h3 className='data-card-data'>{data}</h3>
            </div>
        </div>
        )
    }

// export const BranchadminDashboardCard = ({title, data}) =>{
//     return(
//         <div className='data-card d-flex'>
//         <div className='w-10'>
//             <img src={people} alt="icon" />
//         </div>
//         <div className='ms-4 d-flex flex-column align-items-center' >
//             <h5 className="data-card-title">{title}</h5>
//             <h3 className='data-card-data'>{data}</h3>
//         </div>
//     </div>
//     )
// }

// export const MainadminDashboardCard = ({title, data}) =>{
//     return(
//         <div className='data-card d-flex'>
//         <div className='w-10'>
//             <img src={people} alt="icon" />
//         </div>
//         <div className='ms-4 d-flex flex-column align-items-center' >
//             <h5 className="data-card-title">{title}</h5>
//             <h3 className='data-card-data'>{data}</h3>
//         </div>
//     </div>
//     )
// }

 export const Featurecard = ({value}) =>{
    return(
    <div className='feature-card'>
    <p className='mb-0'>{value}</p>
</div>
    )
 }