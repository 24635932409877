import React, { useState } from "react";

import { RiLayoutMasonryFill } from "react-icons/ri";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";

//Images
import logo from "../../../assests/images/logo.svg";
import { useNavigate } from "react-router-dom";
import {getsvgType} from '../../../constants'

const Sidebar = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [activeSubTab, setActiveSubTab] = useState("manage_teachers");
  const navigate = useNavigate();

  const handleMenuClick = (tabName) => {
    setActiveTab(tabName);
    navigate(`/${tabName}`);
  };
  const handleSubMenuClick = (tabName) => {
    setActiveSubTab(tabName);
    navigate(`/${tabName}`);
  };

  return (
    <>
      <div className="sidebar">
        <div className="brand">
          <img src={logo} alt="logo" />
        </div>

        <div className="mt-5 ms-3">
          {/* SIDE BAR */}
          {/* HOME TAB */}
          <button
            className={
              activeTab === "dashboard"
                ? "mt-2 btn sidebar-item-active"
                : "mt-2 btn sidebar-item"
            }
            onClick={() => handleMenuClick("dashboard")}>
              {getsvgType("dashboard")}
            {/* <RiLayoutMasonryFill /> */}
            <span className="sidebar-tab"> &nbsp; Dashboard </span>
          </button>
          {/* SCHOOL BRANCHES TAB */}
          {/* <button
            className={
              activeTab === "branches"
                ? "mt-2 btn sidebar-item-active"
                : "mt-2 btn sidebar-item"
            }
            onClick={() => handleMenuClick("branches")}>
            <RiLayoutMasonryFill />
            <span className="sidebar-tab"> &nbsp; School Branches </span>
          </button> */}
          {/* EMPLOYEES TAB */}
          <button
            className={
              activeTab === "employees"
                ? "mt-2 btn sidebar-item-active"
                : "mt-2 btn sidebar-item"
            }
            onClick={() => setActiveTab("employees")}>
            {/* <RiLayoutMasonryFill /> */}
            {getsvgType("employees")}
            <span className="sidebar-tab"> &nbsp; Employees </span>
            {activeTab === "employees" ? (
              <span className="ms-5">
                <BiChevronDown className="sidebar-arrow-icon" />
              </span>
            ) : (
              <span className="ms-5">
                <BiChevronRight className="sidebar-arrow-icon" />
              </span>
            )}
          </button>
          {/* EMPLOYEES SUB-TAB */}
          {activeTab === "employees" && (
            <div className="sub-title ">
              {/* <p className='sidebar-list-title mt-2 mb-1'>Teachers</p> */}
              <ul className="sidebar-list-menu p-0">
                <li
                  className={ 
                    activeSubTab === "employees/profiles"
                      ? "sidebar-list-item-active pt-0"
                      : "sidebar-list-item pt-0"
                  }
                  onClick={() => handleSubMenuClick("employees/profiles")}>
                  Directory
                </li>
              </ul>
              {/* <p className='sidebar-list-title mt-2 mb-1'>Supporting Staff</p>
                            <ul className='sidebar-list-menu'>
                                <li className={activeSubTab === "manage_staff" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => handleSubMenuClick("manage_staff")}>Manage</li>
                                <li className={activeSubTab === "staff_attendance" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("staff_attendance")}>Attendance</li>
                            </ul>
                            <p className='sidebar-list-title mt-2 mb-1'>Owners</p>
                            <ul className='sidebar-list-menu'>
                                <li className={activeSubTab === "management_staff" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => handleSubMenuClick("management_staff")}>Manage</li>
                                <li className={activeSubTab === "mstaff_attendance" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("mstaff_attendance")}>Attendance</li>
                            </ul> */}
            </div>
          )}
          {/* STUDENTS TAB */}
          <button
            className={
              activeTab === "students"
                ? "mt-2 btn sidebar-item-active"
                : "mt-2 btn sidebar-item"
            }
            onClick={() => setActiveTab("students")}>
            {/* <RiLayoutMasonryFill /> */}
            {getsvgType("student")}
            <span className="sidebar-tab"> &nbsp; Students </span>
            {activeTab === "students" ? (
              <span className="ms-5">
                <BiChevronDown className="sidebar-arrow-icon" />
              </span>
            ) : (
              <span className="ms-5">
                <BiChevronRight className="sidebar-arrow-icon" />
              </span>
            )}
          </button>
          {/* STUDENTS SUB-TAB */}
          {activeTab === "students" && (
            <div className="sub-title">
              <ul className="sidebar-list-menu p-0">
                <li
                  className={ 
                    activeSubTab === "students/profiles"
                      ? "sidebar-list-item-active pt-0"
                      : "sidebar-list-item pt-0"
                  }
                  onClick={() => handleSubMenuClick("students/profiles")}>
                  Directory
                </li>
                {/* <li className={activeSubTab === "student_attendance" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("student_attendance")}>Student Attendance</li>
                                <li className={activeSubTab === "admissions" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("admissions")}>Admissions</li> */}
              </ul>
            </div>
          )}
          {/* FINANCE TAB */}
          {/* <button className={activeTab === "finance" ? "mt-2 btn sidebar-item-active" : "mt-2 btn sidebar-item"} onClick={() => setActiveTab("finance")}>
            <RiLayoutMasonryFill /><span className='sidebar-tab'> &nbsp; Finance </span>
            {activeTab === "finance" ?
              <span className='ms-5'>
                <BiChevronDown className='sidebar-arrow-icon' />
              </span>
              :
              <span className='ms-5'>
                <BiChevronRight className='sidebar-arrow-icon' />
              </span>}
          </button> */}
          {/* FINANCE SUB-TAB */}
          {/* {activeTab === "finance" &&
            <div className='ms-2'>
              <ul className='sidebar-list-menu mt-2'>
                <li className={activeSubTab === "student_payment" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("student_payment")}>Student Payment</li>
                <li className={activeSubTab === "class_fee" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("class_fee")}>Class Fee-Entry</li>
              </ul>
            </div>
          } */}
          {/* Settings TAB */}
          <button className={activeTab === "settings" ? "mt-2 btn sidebar-item-active" : "mt-2 btn sidebar-item"} onClick={() => setActiveTab("settings")}>
            {/* <RiLayoutMasonryFill /> */}
            {getsvgType("academics")}
            <span className='sidebar-tab'> &nbsp; Academics</span>
            {activeTab === "settings" ?
              <span className='ms-5'>
                <BiChevronDown className='sidebar-arrow-icon' />
              </span>
              :
              <span className='ms-5'>
                <BiChevronRight className='sidebar-arrow-icon' />
              </span>}
          </button>
          {/* Settings SUB-TAB */}
          {activeTab === "settings" &&
            <div className='sub-title'>
              <ul className='sidebar-list-menu p-0'>
                <li className= {activeSubTab === "settings/class" ? "sidebar-list-item-active pt-0" : "sidebar-list-item pt-0"} onClick={() => handleSubMenuClick("settings/class")}>Class & Section</li>
                {/* <li className={activeSubTab === "settings/period" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => handleSubMenuClick("settings/period")}>Period Table</li>
                <li className={activeSubTab === "settings/period" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => handleSubMenuClick("settings/electives")}>Period Table</li>
                <li className={activeSubTab === "timings" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("timings")}>Timings</li>
                <li className={activeSubTab === "subjects" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("subjects")}>Subjects</li>
                <li className={activeSubTab === "timetable" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("timetable")}>Time Table</li>
                <li className={activeSubTab === "exam_timetable" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("exam_timetable")}>Exam Time Table</li>
                <li className={activeSubTab === "calender" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("calender")}>Annual Calender</li> */}
              </ul>
            </div>
          }

          {/* SCHOOL COMMUNITY TAB */}
          <button className={activeTab === "community" ? "mt-2 btn sidebar-item-active" : "mt-2 btn sidebar-item"} onClick={() => setActiveTab("community")}>
            {/* <RiLayoutMasonryFill /> */}
            {getsvgType("settings")}
            <span className='sidebar-tab'> &nbsp; School Community </span>
            {activeTab === "community" ?
              <span className='ms-5'>
                <BiChevronDown className='sidebar-arrow-icon' />
              </span>
              :
              <span className='ms-5'>
                <BiChevronRight className='sidebar-arrow-icon' />
              </span>}
          </button>
          {/* SCHOOL COMMUNITY SUB-TAB */}
          {activeTab === "community" &&
            <div className='ms-2'>
              <ul className='sidebar-list-menu mt-2'>
                <li className={activeSubTab === "community/calender" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => handleSubMenuClick("community/calender")}>Calender </li>
                <li className={activeSubTab === "transport" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("transport")}>Transportation</li>
                <li className={activeSubTab === "messages" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("messages")}>Messages</li>
              </ul>
            </div>
          }
        </div>
      </div>

    </>
  );
};

export default Sidebar;
