import React, { useState } from 'react'

import { RiLayoutMasonryFill } from "react-icons/ri";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";

//Images
import logo from "../../../assests/images/logo.svg"
import { useNavigate } from 'react-router-dom';
import {getsvgType} from '../../../constants'

const MainAdminSideBar = () => {


    const [activeTab, setActiveTab] = useState("dashboard");
    const [activeSubTab, setActiveSubTab] = useState("manage_teachers")
    const navigate = useNavigate();


    const handleMenuClick = (tabName) => {
        setActiveTab(tabName);
        navigate(`/${tabName}`);

    }
    const handleSubMenuClick = (tabName) => {
        setActiveSubTab(tabName)
        navigate(`/${tabName}`)
    }

    return (

        <>

            <div className='sidebar'>

                <div className='brand'>
                    <img src={logo} alt="logo" />
                </div>

                <div className='mt-5 ms-3'>

                    {/* SIDE BAR */}
                    {/* HOME TAB */}
                    <button className={activeTab === "dashboard" ? "mt-2 btn sidebar-item-active" : "mt-2 btn sidebar-item"} onClick={() => handleMenuClick("dashboard")}>
                        {/* <RiLayoutMasonryFill /> */}
                        {getsvgType('dashboard')}
                        <span className='sidebar-tab'> &nbsp; DashBoard </span>
                    </button>
                    {/* SCHOOL BRANCHES TAB */}
                    <button className={activeTab === "branches" ? "mt-2 btn sidebar-item-active" : "mt-2 btn sidebar-item"} onClick={() => handleMenuClick("branches")}>
                        {/* <RiLayoutMasonryFill /> */}
                        {getsvgType('branches')}
                        <span className='sidebar-tab'> &nbsp; School Branches </span>
                    </button>
                    {/* EMPLOYEES TAB */}
                    <button className={activeTab === "employees" ? "mt-2 btn sidebar-item-active" : "mt-2 btn sidebar-item"} onClick={() => setActiveTab("employees")}>
                        {/* <RiLayoutMasonryFill /> */}
                        {getsvgType('employees')}
                        <span className='sidebar-tab'> &nbsp; Employees </span>
                        {activeTab === "employees" ?
                            <span className='ms-5'>
                                <BiChevronDown className='sidebar-arrow-icon' />
                            </span>
                            :
                            <span className='ms-5'>
                                <BiChevronRight className='sidebar-arrow-icon' />
                            </span>}

                    </button>
                    {/* EMPLOYEES SUB-TAB */}
                    {activeTab === "employees" &&
                        <div className='sub-title'>
                            {/* <p className='sidebar-list-title mt-2 mb-1'>Teachers</p> */}
                            <ul className='sidebar-list-menu p-0'>
                                <li className="sidebar-list-item-active " onClick={() => handleSubMenuClick("employees/profiles")}>Directory</li>
                                {/* <li className={activeSubTab === "teacher_attendance" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("teacher_attendance")}>Attendance</li> */}
                                {/* <li className={activeSubTab === "leave_request" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("leave_request")}>Leave Request</li>
                <li className={activeSubTab === "assign_classes" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("assign_classes")}>Assign Classes</li> */}
                            </ul>
                            {/* <p className='sidebar-list-title mt-2 mb-1'>Supporting Staff</p>
                            <ul className='sidebar-list-menu'>
                                <li className={activeSubTab === "manage_staff" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => handleSubMenuClick("manage_staff")}>Manage</li>
                                <li className={activeSubTab === "staff_attendance" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("staff_attendance")}>Attendance</li>
                            </ul>
                            <p className='sidebar-list-title mt-2 mb-1'>Owners</p>
                            <ul className='sidebar-list-menu'>
                                <li className={activeSubTab === "management_staff" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => handleSubMenuClick("management_staff")}>Manage</li>
                                <li className={activeSubTab === "mstaff_attendance" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("mstaff_attendance")}>Attendance</li>
                            </ul> */}
                        </div>}
                    {/* STUDENTS TAB */}

                    {/* SCHOOL COMMUNITY TAB */}
                    <button className={activeTab === "community" ? "mt-2 btn sidebar-item-active" : "mt-2 btn sidebar-item"} onClick={() => setActiveTab("community")}>
                        {/* <RiLayoutMasonryFill /> */}
                        {getsvgType('settings')}
                        <span className='sidebar-tab'> &nbsp; Settings </span>
                        {activeTab === "community" ?
                            <span className='ms-5'>
                                <BiChevronDown className='sidebar-arrow-icon' />
                            </span>
                            :
                            <span className='ms-5'>
                                <BiChevronRight className='sidebar-arrow-icon' />
                            </span>}
                    </button>
                    {/* SCHOOL COMMUNITY SUB-TAB */}
                    {activeTab === "community" &&
                        <div className='ms-2'>
                            <ul className='sidebar-list-menu mt-2'>
                                <li className={activeSubTab === "noticeboard" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("noticeboard")}>Notice Board  </li>
                                <li className={activeSubTab === "transport" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("transport")}>Transportation</li>
                                <li className={activeSubTab === "messages" ? "sidebar-list-item-active" : "sidebar-list-item"} onClick={() => setActiveSubTab("messages")}>Messages</li>
                            </ul>
                        </div>
                    }


                </div>



            </div>

        </>


    )
}

export default MainAdminSideBar