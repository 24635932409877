import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  HStack,
  Input,
  InputLeftElement,
  Text,
  Wrap,
  WrapItem,
  InputGroup,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer, IconButton, Icon
} from "@chakra-ui/react";

// icons
import eye from "../../../assests/images/icons/eye.svg";
import bin from "../../../assests/images/icons/bin.svg";
import main from "../../../assests/images/icons/main.svg"
import pencil from "../../../assests/images/icons/pencil.svg";

import { MdCompareArrows } from "react-icons/md"
// import { RiDeleteBinLine, RiEditLine, RiEyeLine } from 'react-icons/ri'

const DataTable = ({ allBranchesData, setProfileType }) => {


  const data = [
    { Name: "Emp Name", Phone_Number: 123456879, Roles1: "Main Admin", Roles2: "Branch Admin", Roles3: "Owner" },

  ];


  const handleDelete = (id) => {
    alert(`Deleting item with id: ${id}`);
  };

  return (
    <TableContainer className="custom-scrollbar mt-3">
      <Table variant="simple" size="sm" style={{ textAlign: "center" }}>
        <Tbody fontSize="sm" padding={0}>
          <Tr className='branch-data-table-header'>
            <Td>Name<Icon as={MdCompareArrows} transform="rotate(90deg)" /></Td>
            <Td>Phone Number</Td>
            <Td>Roles</Td>
            <Td>Action</Td>
          </Tr>
          {data.map((item) =>
            <Tr className='branch-data-table' size="sm" key={item.no}>
              <Td>{item.Name}</Td>
              <Td>{item.Phone_Number}</Td>
              {/* <Td>{item.Roles1} {item.Roles2} {item.Roles3}</Td> */}
             <Td>{item.hasOwnProperty("Roles1") && item.hasOwnProperty("Roles2")&& item.hasOwnProperty("Roles3") ? `${item.Roles1} ${item.Roles2} ${item.Roles3}` : "No Role"}</Td>

              <Td>
                <div className='d-flex'>
                  <img className='me-2 icon' src={eye} alt="View" onClick={() => setProfileType("View", "id")} />
                  <img className='me-2 icon' src={pencil} alt="Edit" onClick={() => setProfileType("Edit", "id")} />
                  <img className='me-2 icon' src={bin} alt="Delete" onClick={() => handleDelete("id")} />
                </div>
              </Td>
            </Tr>
          )}

        </Tbody>
      </Table>
    </TableContainer >
  )
}

export default DataTable