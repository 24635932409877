import Labelcomponent from "../../../components/Label/Label";
import Inputcomponent from "../../../components/Input/Input";
import { countryCodeList } from "../../../constants"
import add_button from "../../../assests/images/icons/add_button.svg"
import delete_button from "../../../assests/images/icons/delete_button.svg"

const ContactForm = ({type, contactformdata, employeedata}) =>{
    const handleInputchange = (e) =>{
        const {name, value} = e.target
        employeedata((prevdata)=> ({
            ...prevdata,
            [name]:value
        }))
    }
    return(
        <form className="form-horizontal mt-4" >
        <div className="row col-12 mx-auto ">

            <div className="form-group row col-10 mx-auto p-0" >
             <Labelcomponent  value={"Address"} />
                <div className="col-6 mt-2" >
                 <Labelcomponent  value={"Door number"} />
                 <Inputcomponent name={"doorNumber"} onChange={handleInputchange} value={contactformdata?.doorNumber} placeholder={"Door No."} type={"number"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

                <div className="col-6 mt-2" >
                     <Labelcomponent  value={"Line 1"} />
                 <Inputcomponent name={"line1"} onChange={handleInputchange} value={contactformdata?.line1} placeholder={"Line 1"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

            </div>

            <div className="form-group row col-10 mx-auto p-0" >

                <div className="col-6 mt-2" >
                <Labelcomponent  value={"Line 2"} />
                 <Inputcomponent name={"line2"} onChange={handleInputchange} value={contactformdata?.line2} placeholder={"Line 2"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

                <div className="col-6 mt-2" >
                <Labelcomponent  value={"Landmark"} />
                 <Inputcomponent name={"landmark"} onChange={handleInputchange} value={contactformdata?.landmark} placeholder={"Landmark"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

            </div>
            <div className="form-group row col-10 mx-auto p-0" >

                <div className="col-6 mt-2" >
                <Labelcomponent  value={"City"} />
                 <Inputcomponent name={"city"} onChange={handleInputchange} value={contactformdata?.city} placeholder={"City"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>                                
                </div>

                <div className="col-6 mt-2" >
                <Labelcomponent  value={"State / Province / Region"} />
                 <Inputcomponent name={"state"} onChange={handleInputchange} value={contactformdata?.state} placeholder={"State Name"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

            </div>
            <div className="form-group row col-10 mx-auto p-0" >

                <div className="col-6 mt-2" >
                <Labelcomponent  value={"ZIP / Postal / Pin Code"} />
                 <Inputcomponent name={"pincode"} onChange={handleInputchange} value={contactformdata?.pincode} placeholder={"6000001"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

                <div className="col-6 mt-2" >
                <Labelcomponent  value={"Country"} />
                 <Inputcomponent name={"country"} onChange={handleInputchange} value={contactformdata?.country} placeholder={"Country"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

            </div>


            <div className="form-group row col-10  mx-auto mt-2 p-0" >
                <div className="col-6" >
                    <Labelcomponent value={"Phone number"} important={"important"}/>
                    <div className="d-flex">
                        <select name={"phoneIsdCode"} onChange={handleInputchange} value={contactformdata?.phoneIsdCode} className=" mt-2 phone-number-select" disabled={type === "View" ? true : false} style={{height:"48px"}}>
                            {countryCodeList.map((country) => (
                                <option value={country.name} >
                                    {country.code + "  " + country.dial_code}
                                </option>
                            ))}
                        </select>
                        <Inputcomponent name={"phoneNumber"} onChange={handleInputchange} value={contactformdata?.phoneNumber} placeholder={"9999999999"} type={"number"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                    </div>
                </div>
                <div className="col-6" >
                <Labelcomponent  value={"Email"} />
                 <Inputcomponent name={"email"} onChange={handleInputchange} value={contactformdata?.email} placeholder={"abc@gmail.com"} type={"email"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>
            </div>
            <div className="form-group row col-10 mx-auto p-0" >
                <Labelcomponent value={"Emergency contact"} className={"mt-3"}/>
                <div className="col-3 mt-2" >
                <Labelcomponent  value={"Person name"} />
                 <Inputcomponent name={"personname"} onChange={handleInputchange} value={contactformdata?.personname} placeholder={"Field"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>
                <div className="col-3 mt-2" >
                  <Labelcomponent  value={"Relation name"} />
                 <Inputcomponent name={"relationname"} onChange={handleInputchange} value={contactformdata?.relationname} placeholder={"Field"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

                <div className="col-4 mt-2" >
                <Labelcomponent  value={"Phone number"} important={"important"} />
                    <div className="d-flex">
                        <select name={"realationphoneIsdCode"} onChange={handleInputchange} value={contactformdata?.realationphoneIsdCode} className=" mt-2 phone-number-select" disabled={type === "View" ? true : false}>
                            {countryCodeList.map((country, key) => (
                                <option key={key} value={country.dial_code} >
                                    {country.code + "  " + country.dial_code}
                                </option>
                            ))}
                        </select>
                        <Inputcomponent name={"realationphoneNumber"} onChange={handleInputchange} value={contactformdata?.realationphoneNumber} placeholder={"9999999999"} type={"number"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                    </div>
                </div>
                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                    <img src={add_button} alt="add_button" className="btn add-upload-button p-2 pb-0" />
                </div>

                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                    <img src={delete_button} alt="delete_button" className="btn add-upload-button p-2 pb-0 " />
                </div>
            </div>


        </div>

    </form>
    )
}
export default ContactForm