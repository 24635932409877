import React, { useState, useEffect } from "react";

import Header from "../../../components/Header";

import people from "../../../assests/images/people_icon.png";

import axios from "axios";
import { apiUrl } from "../../../constants";
import {
  Box,
  HStack,
  Input,
  InputLeftElement,
  Text,
  Wrap,
  WrapItem,
  InputGroup,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Icon,
} from "@chakra-ui/react";

import { SearchIcon, SmallAddIcon } from "@chakra-ui/icons";
import DataTable from "./DataTable";
import StudentsAddProfile from "./StudentsAddProfile";
import Buttoncomponent from "../../../components/Button/Button";

const StudentsProfiles = () => {
  const [allTeachersData, setAllTeachersData] = useState([]);
  const [studentsAddProfile, setStudentsAddProfile] = useState("");
  const [teacherData, setTeacherData] = useState({});

  useEffect(() => {
    // Fetch All Branch data from API and update the formData state
    fetchAllTeacherData().then((data) => {
      setAllTeachersData(data);
    });

    // fetchTeacherData().then((data) => {
    //     setTeacherData(data);
    // });
  }, []);

  const handleCancel = () => {
    // Reset the form data to the initial state
    setStudentsAddProfile("");
    setTeacherData(teacherData);
  };

  const handleSave = (newTeacherData) => {
    // Call the API to save the updated data
    saveTeacherData(newTeacherData).then(() => {
      // Handle any success actions
    });
    setStudentsAddProfile("");
  };

  const handleEdit = () => {
    setStudentsAddProfile("Edit");
  };
  const handleProfileType = (e, id) => {
    setStudentsAddProfile(e);
  };

  const saveTeacherData = async () => {
    try {
      const response = await axios.get(`${apiUrl}`); // Replace with your API endpoint
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  //edit needed
  const fetchAllTeacherData = async () => {
    try {
      const allBranchApiData = await axios.get(`${apiUrl}branch?schoolId=1`);
      console.log("Branch Data", allBranchApiData.data);

      // const addressData = await axios.get(`${apiUrl}address/${branchApiData.data.addressId}`); // Replace with your API endpoint
      // console.log("addressData ", branchApiData.data)
      // const apiData = {
      //     branchName: branchApiData.data.name
      // }
      // setBranchData({
      //     branchName: branchData.data.name,

      // })
      return allBranchApiData.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  if (studentsAddProfile !== "") {
    return (
      <div
        className="w-100 d-flex flex-column"
        style={{ background: "#fbf9ff" }}
      >
        <Header title={"Branch Admin"} />
        <div className="row">
          <div className="col-11 ms-3">
            <div
              className="ms-4 mt-3"
              style={{
                background: "#ffffff",
                padding: "10px",
                borderRadius: "15px",
                height: "auto",
                border: "1px solid #95A1BE",
                minHeight: "100vh",
              }}
            >
              <StudentsAddProfile
                setStudentsAddProfile={setStudentsAddProfile}
                type={studentsAddProfile}
                teacherData={teacherData}
                onSave={handleSave}
                onCancel={handleCancel}
                onEdit={handleEdit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 d-flex flex-column" style={{ background: "#fbf9ff" }}>
      <Header title={"Branch Admin"} />

      {/* SUB HEADER */}
      <div className="d-flex align-items-end mt-3 ps-3">
        <h1 className="add-teacher-title ps-3">Students</h1>
        <h1 className="add-teacher-subtitle ps-2">{">"} Manage</h1>
      </div>
      {/* <div className="ms-5 mt-4" >
                <WrapItem>
                    <Wrap justify={"center"}>
                        <WrapItem>
                            <label
                                for="inputGroupSelect"
                                className="teacher-campus-select"
                            >
                                Branch
                            </label>
                            &nbsp;
                            <select
                                className="custom-select"
                                id="inputGroupSelect"
                            >
                                <option selected>Saras University of Schools</option>
                                <option value="1">Saras University of Schools1</option>
                                <option value="2">Saras University of Schools2</option>
                                <option value="3">Saras University of Schools3</option>
                            </select>
                        </WrapItem>
                        <WrapItem>
                            <label
                                for="inputGroupSelect"
                                className="teacher-campus-select"
                            >
                                Academic Year
                            </label>
                            &nbsp;
                            <select name="academic-select" className='custom-select'>
                                <option value="1">2003-2004</option>
                                <option value="2">2004-2005</option>
                            </select>
                        </WrapItem>
                    </Wrap>
                    &nbsp; &nbsp;
                </WrapItem>
            </div> */}
      <div className="ps-3 mt-2">
        <p className="ps-3 text-small-2 mb-0 ">
          As a Branch Admin, you can create Student profile
        </p>
      </div>

      {/* SUB HEADER 1 */}
      <div className="col-11 ms-3 mt-2">
        <div
          className="ms-4"
          style={{
            background: "#ffffff",
            padding: "10px",
            borderRadius: "25px",
            border: "1px solid #d9d9d9",
          }}
        >
          <h4 className="teacher-header ms-3 mt-3">Students List</h4>

          <div className="ms-3 mt-4 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <h6 className="me-2 mb-0 text-small-1">Show</h6>
              <select className="teacher-select">
                <option selected>10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>
              <h6 className="ms-2 mb-0 text-small-1">entries</h6>
            </div>

            <div className="d-flex me-5">
              {/* <Button className='add-new-teacher me-3' paddingX={6} size="sm"  onClick={() => setStudentsAddProfile("Add")} >
                                <SmallAddIcon boxSize={6} /> Create
                            </Button> */}
              <Buttoncomponent
                onClick={() => setStudentsAddProfile("Add")}
                className={"createbtn"}
                value={"Create"}
              />
            </div>
          </div>

          <div className="ms-3 mb-4">
            <DataTable
              allTeachersData={allTeachersData}
              setProfileType={handleProfileType}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsProfiles;
