import React, { useState, useEffect } from 'react'

import Header from "../../../components/Header";

import people from "../../../assests/images/people_icon.png"

import axios from 'axios';
import { apiUrl } from '../../../constants';
import {
    Box,
    HStack,
    Input,
    InputLeftElement,
    Text,
    Wrap,
    WrapItem,
    InputGroup,
    Button,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer, Icon
} from "@chakra-ui/react";

import { SearchIcon, SmallAddIcon } from "@chakra-ui/icons";
import DataTable from './DataTable';
import BranchForm from './BranchForm';
import { FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import ButtonComponent  from '../../../components/Button/Button';
import { useAuth } from '../../../context/AuthContext';
import {Row,Col} from 'react-bootstrap'

const MainAdminBranches = () => {


    const [allBranchesData, setAllBranchesData] = useState({})
    const [branchForm, setBranchForm] = useState("")
    const [branchData, setBranchData] = useState({});
    const [getbranchId, setgetBranchId] = useState(0)
    const {state} = useAuth()
    const schoolname = state.mainadminData.school.schoolName;
    const schoolId = state.mainadminData.user.schoolId;
    const apiHost = process.env.REACT_APP_API_HOST;
    
    console.log("schoolname", schoolname);
    console.log("schoolId", schoolId);
    useEffect(() => {
        // Fetch All Branch data from API and update the formData state
        fetchAllBranchData().then((data) => {
            setAllBranchesData({data})
        })

        // fetchBranchData().then((data) => {
        //     setBranchData(data);
        // });
    }, []);

        const fetchBranchesdata = async () => {
            try {
                const {data} =await  axios.get(`${apiHost}/branch?schoolId=${schoolId}`)
                setAllBranchesData(data)
                console.log("setAllBranchesData", data);
            } catch (error) {
                console.error(error)
            }
        }

        useEffect(() =>{
            fetchBranchesdata()
        },[])

    const handleCancel = () => {
        // Reset the form data to the initial state
        setBranchForm("");
        setBranchData(branchData);
    };

    const handleEdit = () => {
        setBranchForm("Edit");
    }

    const handleSave = (newBranchData) => {
        fetchBranchesdata()
        // Call the API to save the updated data
        saveBranchData(newBranchData).then(() => {
            // Handle any success actions
        });
        setBranchForm("");
    };


    const saveBranchData = async () => {

        try {
            const response = await axios.get(`${apiUrl}`); // Replace with your API endpoint
            return response.data;
        } catch (error) {
            // Handle error
            console.error('Error fetching data:', error);
        }

    }

    const fetchAllBranchData = async () => {

        try {
            const allBranchApiData = await axios.get(`${apiUrl}/branch?schoolId=${schoolId}`);
            console.log("Branch Data", allBranchApiData.data)

            // const addressData = await axios.get(`${apiUrl}address/${branchApiData.data.addressId}`); // Replace with your API endpoint
            // console.log("addressData ", branchApiData.data)
            // const apiData = {
            //     branchName: branchApiData.data.name
            // }
            // setBranchData({
            //     branchName: branchData.data.name,

            // })
            return allBranchApiData.data;


        } catch (error) {
            // Handle error
            console.error('Error fetching data:', error);
        }

    }

    const handleBranchType = (e, id) => {
        setBranchForm(e);
        setgetBranchId(id)
    }

    const handleSearch = () => {
        // Implement your search logic here
        console.log('Search clicked!');
      };

    if (branchForm !== "") {
        return (
            <div className='w-100 d-flex flex-column' style={{ background: "#fbf9ff" }}>
                <Header title= "Main Admin" schoolname={schoolname}/>
                <Col md={11} className='ms-3'>
                <div className='ms-4 mt-3' style={{ background: "#ffffff", padding: "10px", borderRadius: "15px", minHeight: "100vh", height: "auto", border: "1px solid #95A1BE" }}>
                    <BranchForm branchId = {getbranchId} type={branchForm} setBranchForm={setBranchForm} branchData={branchData} onSave={handleSave} onCancel={handleCancel} onEdit={handleEdit} />
                </div>
                </Col>
            </div>
        )
    }

    // const handleCreateClick = () =>{
    //     setBranchForm("create")
    // }
   
  

    return (

        <div className='w-100 d-flex flex-column' style={{ background: "#fbf9ff" }}>
            <Header title= "Main Admin" schoolname={schoolname}/>

            {/* SUB HEADER */}
            <div className=' mt-3 ps-3 d-flex align-items-center' >
                <h1 className='subheader-title ps-3'>School Branches</h1>
                <div className=''>
                <input type='search' placeholder='search here' id='branch-search' style={{width:"200px", padding:"2px 10px", border:"1px solid #8F9BBA", height:"30px", borderRadius:"6px"}}></input>
                </div>
            </div>

            {/* <div className='ms-3 d-flex align-items-center'>
                <h5 className='ms-4 subheader me-3 my-auto'>Academic year</h5>
                <select className="dropdown-select" >
                    <option selected>2023-2024</option>
                    <option value="1">2024-2025</option>
                </select>
            </div> */}
            <div className='ps-3 mt-2'>
                <p className='ps-3 text-small-2 mb-2 '>Add branches to your school group. Click 'Add New' to create a new branch.</p>
            </div>

            {/* SUB HEADER 1 */}

            <div className='col-11 ms-3 mt-2' >
                <div className='ms-4' style={{ background: "#ffffff", padding: "10px", borderRadius: "25px", border: "1px solid #d9d9d9" }}>
                    <h4 className='branch-header ms-3 mt-3'>Branch List</h4>

                    <div className='ms-3 mt-4 d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <h6 className='me-2 mb-0 text-small-1'>Show</h6>
                            <select className="branch-select">
                                <option selected>10</option>    
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                            </select>
                            <h6 className='ms-2 mb-0 text-small-1'>entries</h6>
                        </div>

                        <div className='d-flex me-5' >
                            {/* <Button className='add-new-branch' size="sm" onClick={() => setBranchForm("create")} >
                                <SmallAddIcon boxSize={6} /> <span style={{ fontWeight: "600" }}>Create</span>
                            </Button> */}
                            <ButtonComponent onClick={() => setBranchForm("Add")} className={'createbtn'} value={"create"}/>
                        </div>
                    </div>

                    <div className='ms-3 mb-4'>
                        <DataTable allBranchesData={allBranchesData} setBranchType={handleBranchType} />
                    </div>
                </div>
            </div>



        </div>
    )
}

export default MainAdminBranches