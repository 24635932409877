import React from "react";

const ElectiveGroup = ({ groupName, subjectList, sections }) => {
  return (
    <>
      <div className="ms-3 mt-4 py-2 h-auto border-bottom ">
        <h4 className="fw-bold fs-5 mb-3">{groupName}</h4>
        <ul id="elective__common-subject--list" className="d-flex p-0">
          {subjectList?.map((el, key) => {
            return (
              <li
                key={key}
                className="px-3 py-1 me-2 rounded-pill bg-light border-1
                      list-unstyled">
                {el}
              </li>
            );
          })}
        </ul>
        <p>Applicable Section(s) for this Stream</p>
        <ul id="elective__common-subject--list" className="d-flex p-0">
          {sections?.map((el, key) => {
            return (
              <li
                key={key}
                className="px-4 py-1 me-2 rounded-pill border-1
            list-unstyled pointer-cursor fw-bold"
                style={{ backgroundColor: "#379DDB33", color: "#4267B2" }}>
                {el}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ElectiveGroup;
