import React, { useState, useEffect } from "react";

import Header from "../../../components/Header";

import people from "../../../assests/images/people_icon.png";

import axios from "axios";
import { apiUrl } from "../../../constants";
// import {
//     Box,
//     HStack,
//     Input,
//     InputLeftElement,
//     Text,
//     Wrap,
//     WrapItem,
//     InputGroup,
//     Button,
//     Table,
//     Tbody,
//     Tr,
//     Td,
//     TableContainer, Icon
// } from "@chakra-ui/react";

import { SearchIcon, SmallAddIcon } from "@chakra-ui/icons";
import DataTable from "./DataTable";
import AddProfile from "./AddProfile";
import ButtonComponent from "../../../components/Button/Button";
import { useAuth } from "../../../context/AuthContext";
import { Row, Col } from "react-bootstrap";

const EmployeeProfiles = () => {
  const [allTeachersData, setAllTeachersData] = useState([]);
  const [employeelist, setEmployeelist] = useState([]);
  const [addProfile, setAddProfile] = useState("");
  const [teacherData, setTeacherData] = useState({});
  const { state } = useAuth();
  const schoolname = state.mainadminData.school.schoolName;
  const schoolId = state.mainadminData.user.schoolId;
  const apiHost = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    // Fetch All Branch data from API and update the formData state
    // fetchAllTeacherData().then((data) => {
    //     setAllTeachersData(data)
    // })
    // fetchTeacherData().then((data) => {
    //     setTeacherData(data);
    // });
  }, []);

  const handleCancel = () => {
    // Reset the form data to the initial state
    setAddProfile("");
    setTeacherData(teacherData);
  };

  const handleSave = (newTeacherData) => {
    // Call the API to save the updated data
    saveTeacherData(newTeacherData).then(() => {
      // Handle any success actions
    });
    setAddProfile("");
  };
  const handleProfileType = (e, id) => {
    setAddProfile(e);
  };

  const handleEdit = () => {
    setAddProfile("Edit");
  };

  const saveTeacherData = async () => {
    try {
      const response = await axios.get(`${apiUrl}`); // Replace with your API endpoint
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  //edit needed
  // const fetchAllTeacherData = async () => {

  //     try {
  //         const allBranchApiData = await axios.get(`${apiUrl}/employee?schoolId=${schoolId}`);
  //         console.log("Branch Data", allBranchApiData.data)
  //         setEmployeelist()

  //         // const addressData = await axios.get(`${apiUrl}address/${branchApiData.data.addressId}`); // Replace with your API endpoint
  //         // console.log("addressData ", branchApiData.data)
  //         // const apiData = {
  //         //     branchName: branchApiData.data.name
  //         // }
  //         // setBranchData({
  //         //     branchName: branchData.data.name,

  //         // })
  //         return allBranchApiData.data;

  //     } catch (error) {
  //         // Handle error
  //         console.error('Error fetching data:', error);
  //     }

  // }
  const FetchEmployee = async () => {
    try {
      const { data } = await axios.get(`${apiHost}/employee?schoolId=${1}`);
      console.log("data", data.employees);
      setEmployeelist(data.employees);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    FetchEmployee();
  }, []);

  if (addProfile !== "") {
    return (
      <div
        className="w-100 d-flex flex-column"
        style={{ background: "#fbf9ff" }}
      >
        <Header />
        <Col md={11} className="ms-3 mt-3">
          <div
            className="ms-4"
            style={{
              background: "#ffffff",
              padding: "10px",
              borderRadius: "15px",
              minHeight: "100vh",
              height: "auto",
              border: "1px solid #95A1BE",
            }}
          >
            <AddProfile
              setAddProfile={setAddProfile}
              type={addProfile}
              teacherData={teacherData}
              onSave={handleSave}
              onCancel={handleCancel}
              onEdit={handleEdit}
            />
          </div>
        </Col>
      </div>
    );
  }

  return (
    <div className="w-100 d-flex flex-column" style={{ background: "#fbf9ff" }}>
      <Header title="Main Admin" schoolname={schoolname} />

      {/* SUB HEADER */}
      <div className="d-flex align-items-end mt-3 ps-3">
        <h1 className="add-teacher-title ps-3">Employees</h1>
        <h1 className="add-teacher-subtitle ps-2">{">"} Profiles</h1>
      </div>
      {/* <div className="ms-5 mt-4" >
                <WrapItem>
                    <Wrap justify={"center"}>
                        <WrapItem>
                            <label for="inputGroupSelect" className="teacher-campus-select" style={{ fontWeight: "800" }}>
                                Academic Year
                            </label>
                            &nbsp;
                            <select name="academic-select" className='custom-select'>
                                <option value="1">2003-2004</option>
                                <option value="2">2004-2005</option>
                            </select>
                        </WrapItem>
                    </Wrap>
                    &nbsp; &nbsp;
                </WrapItem>
            </div> */}
      <div className="ps-3 mt-2">
        <p className="ps-3 text-small-2 mb-0 ">
          {" "}
          Manage employee profiles and assign roles like Main admin, Branch
          admin, and Owner.
        </p>
        <p className="ps-3 text-small-2 mb-2">
          For teachers and other roles, log in as Branch Admin.{" "}
        </p>
      </div>

      {/* SUB HEADER 1 */}
      <div className="col-11 ms-3 mt-2">
        <div
          className="ms-4"
          style={{
            background: "#ffffff",
            padding: "10px",
            borderRadius: "25px",
            border: "1px solid #d9d9d9",
          }}
        >
          <h4 className="teacher-header ms-3 mt-3">Employees List</h4>

          <div className="ms-3 mt-4 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <h6 className="me-2 mb-0 text-small-1">Show</h6>
              <select className="teacher-select">
                <option selected>10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>
              <h6 className="ms-2 mb-0 text-small-1">entries</h6>
            </div>

            <div className="d-flex me-5">
              {/* <Button className='add-new-branch' size="sm" onClick={() => setAddProfile("Add")} >
                                <SmallAddIcon boxSize={6} /> <span style={{ fontWeight: "600" }}>Create</span>
                            </Button> */}
              <ButtonComponent
                onClick={() => setAddProfile("Add")}
                className={"createbtn"}
                value={"create"}
              />
            </div>
          </div>

          <div className="ms-3 mb-4">
            <DataTable
              Employeelist={employeelist}
              setProfileType={handleProfileType}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeProfiles;
