import React from "react";

const NavigationIndicator = ({page, subpage, description}) => {
  return (
    <>
      <div className="d-flex align-items-end">
        <h1 className="add-teacher-title ms-5">{page}</h1>
        <h1 className="add-teacher-subtitle ms-3">
          {">"} {subpage}
        </h1>
      </div>
      <div className="ms-4 mt-2">
        <p className="ms-4 text-small-2 mb-0 ">{description}</p>
      </div>
    </>
  );
};

export default NavigationIndicator;
