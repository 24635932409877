import {
    Button,
} from "@chakra-ui/react";

import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Checkbox } from '@chakra-ui/react'
import bin from "../../../assests/images/icons/bin.svg"
import { ArrowBackIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import ButtonComponent from '../../../components/Button/Button'
import Inputcomponent from '../../../components/Input/Input'
import Labelcomponent from "../../../components/Label/Label";
import { useAuth } from '../../../context/AuthContext';

const BranchForm = ({ branchId, setBranchForm, type, onSave, onCancel, onEdit }) => {
    console.log("setBranchForm type ", type);
    console.log("branchId", branchId);

    const [branchFormData, setBranchFormData] = useState({});
    const [model, setModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [branchdata, setBranchdata] = useState({})
    const { state } = useAuth()
    const schoolId = state.mainadminData.user.schoolId;
    const apiHost = process.env.REACT_APP_API_HOST;

    const fetchBranchdata = async () => {
        try {
            const { data } = await axios.get(`${apiHost}/branch/${branchId}`)
            console.log("data", data);
            setBranchdata(data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (type === 'Edit') {
                await axios.put(`${apiHost}/branch/${branchId}`, branchdata);
                console.log("Data updated");
            }
            else if (type === 'Add') {
                const requestdata =  {
                    schoolId: schoolId,
                    name: branchdata.name,
                    board:branchdata.board,
                    phoneNumber:branchdata.phoneNumber,
                    email:branchdata.email,
                    logo:branchdata.logo,
                    address:{
                        doorNumber:branchdata.doorNumber,
                        line1:branchdata.line1,
                        line2:branchdata.line2,
                        landmark:branchdata.landmark,
                        city:branchdata.city,
                        state:branchdata.state,
                        pincode:branchdata.pincode,
                        country:branchdata.country
                    }
                }
                // const requestdata = {
                //     ...branchdata,
                //     schoolId: schoolId
                // }
                await axios.post(`${apiHost}/branch`, requestdata)
                console.log("Data Created");
            }
            await fetchBranchdata()
            onSave(branchdata);
        }
        catch (error) {
            console.error(error)
        }
};


const handleCancel = () => {
    setBranchFormData(branchFormData);
    // setBranchForm(false);
    onCancel();
};

const handleEdit = () => {
    onEdit();
}


useEffect(() => {
    if (type === 'View' || type === "Edit") {
        fetchBranchdata()
    }
}, [])

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBranchdata((prevBranchData) => ({
        ...prevBranchData,
        [name]: value,
    }));
};

console.log("branh::::", branchdata);
return (


    <div className="mt-3 pb-5" >
        <div className='d-flex align-items-center'>
            <h1 className='add-branch-title ms-3'>School Branch</h1>
            <h1 className='add-branch-subtitle ms-2'>{">"}{type}</h1>
        </div>

        <form className="form-horizontal mt-4" >

            <div className="row col-12 mx-auto ">

                <div className="form-group col-10  mx-auto" >
                    <Labelcomponent value="Branch  Name" important={"important"} />
                    <Inputcomponent onChange={handleInputChange} name={"name"} value={branchdata.name} className="form-control" type={"text"} placeholder={"Branch Name"} disabled={type === "View" ? true : false} />
                </div>

                <div className="form-group col-10  mx-auto mt-2" >
                    <Labelcomponent value="Branch  Board" />
                    <Inputcomponent onChange={handleInputChange} name={"board"} value={branchdata.board} className="form-control " type={"text"} placeholder={"Branch Board"} disabled={type === "View" ? true : false} />
                </div>

                <div className="form-group row col-10 mx-auto p-0" >
                    <div className="col-6 mt-2" >
                        <Labelcomponent value="Phone number" />
                        <Inputcomponent onChange={handleInputChange} name={"phoneNumber"} value={branchdata.phoneNumber} className="form-control " type={"text"} placeholder={"9999999999"} disabled={type === "View" ? true : false} />
                    </div>

                    <div className="col-6 mt-2" >
                        <Labelcomponent value="Email" />
                        <Inputcomponent onChange={handleInputChange} name={"email"} value={branchdata.email} className="form-control " type={"text"} placeholder={"example@gmail.com"} disabled={type === "View" ? true : false} />
                    </div>
                </div>

                <div className="form-group row col-10 mx-auto p-0" >
                    <div className="col-6 mt-2" >
                        <Labelcomponent value="Door no." />
                        <Inputcomponent onChange={handleInputChange} name={"doorNumber"} value={branchdata?.address?.doorNumber} className="form-control " type={"text"} placeholder={"14"} disabled={type === "View" ? true : false} />
                    </div>

                    <div className="col-6 mt-2" >
                        <Labelcomponent value="Address Line 1" />
                        <Inputcomponent onChange={handleInputChange} name={"line1"} value={branchdata?.address?.line1} className="form-control " type={"text"} placeholder={"Street Name"} disabled={type === "View" ? true : false} />
                    </div>
                </div>

                <div className="form-group row col-10 mx-auto p-0" >
                    <div className="col-6 mt-2" >
                        <Labelcomponent value="Address Line 2" />
                        <Inputcomponent onChange={handleInputChange} name={"line2"} value={branchdata?.address?.line2} className="form-control " type={"text"} placeholder={"Area Name"} disabled={type === "View" ? true : false} />
                    </div>

                    <div className="col-6 mt-2" >
                        <Labelcomponent value="Landmark" />
                        <Inputcomponent onChange={handleInputChange} name={"landmark"} value={branchdata?.address?.landmark} className="form-control " type={"text"} placeholder={"Church"} disabled={type === "View" ? true : false} />
                    </div>
                </div>

                <div className="form-group row col-10 mx-auto p-0" >
                    <div className="col-6 mt-2" >
                        <Labelcomponent value="City" />
                        <Inputcomponent onChange={handleInputChange} name={"city"} value={branchdata?.address?.city} className="form-control " type={"text"} placeholder={"City"} disabled={type === "View" ? true : false} />
                    </div>

                    <div className="col-6 mt-2" >
                        <Labelcomponent value="State/Province/Region" />
                        <Inputcomponent onChange={handleInputChange} name={"state"} value={branchdata?.address?.state} className="form-control " type={"text"} placeholder={"Tamil Nadu"} disabled={type === "View" ? true : false} />
                    </div>
                </div>

                <div className="form-group row col-10 mx-auto p-0" >
                    <div className="col-6 mt-2" >
                        <Labelcomponent value="PIN / ZIP / Postal Code" />
                        <Inputcomponent onChange={handleInputChange} name={"pincode"} value={branchdata?.address?.pincode} className="form-control " type={"text"} placeholder={"600001"} disabled={type === "View" ? true : false} />
                    </div>

                    <div className="col-6 mt-2" >
                        <Labelcomponent value="Country" />
                        <Inputcomponent onChange={handleInputChange} name={"country"} value={branchdata?.address?.country} className="form-control " type={"text"} placeholder={"India"} disabled={type === "View" ? true : false} />
                    </div>
                </div>
                    {type !== "View" &&
                <div className="form-group col-10 mt-3  mx-auto" >
                    <label className="add-branch-label"> Branch  Logo </label>
                    <div className="d-flex">
                        <Inputcomponent onChange={handleInputChange} name={"logo"} className="form-control mt-2" type={"file"} placeholder={""} id="onboarding-group-email-input" disabled={type === "View" ? true : false} />
                        {/* <ButtonComponent className={'mt-2 mb-2 col-2 uploadbtn'} value={"Upload"} id="onboarding-group-email-upload-button" /> */}
                    </div>
                </div>
                    }
            </div>
        </form >

        {type === "Add" || type === "Edit" ?

            <div className="row col-10 p-0 mx-auto mt-3 ">
                <div className="d-flex justify-content-center mt-3">
                    <ButtonComponent onClick={() => setModel(true)} className={' me-2 btn cancelbtn'} value={"Cancel"} />
                    <ButtonComponent onClick={handleSubmit} className={' me-2 btn savebtn'} value={"Save"} />
                    {/* <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={() => setModel(true)}>Cancel</button> */}
                    {/* <button className='me-2 btn button-1' style={{ width: "90px" }} onClick={handleSubmit}>Save</button> */}
                </div>
            </div>
            :
            <div className="row col-10 p-0 mx-auto mt-3 ">
                <div className="d-flex justify-content-center mt-3">
                    <Button className='me-3 left-arrow-button' variant="link" leftIcon={<ArrowBackIcon />} style={{ width: "90px" }} onClick={() => setBranchForm("")}>Back</Button>
                    {/* <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={handleEdit}>Edit</button> */}
                    <ButtonComponent onClick={handleEdit} className={'btn editbtn'} value={"Edit"} />
                    <ButtonComponent onClick={() => setDeleteModel(true)} className={'me-3 btn deletebtn '} value={"Delete"} />
                    {/* <Button className='me-3 text-delete-button' variant="link" style={{ width: "90px" }} onClick={() => setDeleteModel(true)}>Delete</Button> */}
                </div>
            </div>
        }



        <Modal
            show={model}
            size="lg"
            animation
            centered
            onHide={() => setModel(false)}
            backdrop="static"
            keyboard={false}
            style={{ padding: "0px" }}
        >
            <Modal.Body style={{ padding: "0px", }}>
                <div className='mt-4 text-center' >
                    <h2 className='welcome-title'>Are you sure you want to cancel?</h2>
                    <h5 className='welcome-subtext'>Any unsaved changes will be lost</h5>
                    <div className='mt-5 '>
                        <div className="d-flex justify-content-center mt-3">
                            <button className='me-2 btn popup-cancel-button ' onClick={() => setModel(false)} style={{ width: "250px " }}>No, I want to continue filling</button>
                            <button className='me-2 btn popup-cancel-button-outline ' onClick={handleCancel} style={{ width: "250px " }}>Yes I want to cancel it</button>
                        </div>
                    </div>
                </div>
                <br /><br />
            </Modal.Body>
        </Modal>

        <Modal
            show={deleteModel}
            size="lg"
            animation
            centered
            onHide={() => setDeleteModel(false)}
            backdrop="static"
            keyboard={false}
            style={{ padding: "0px" }}
        >
            <Modal.Body style={{ padding: "0px", }}>
                <div className='mt-4 text-center' >
                    <img src={bin} alt="delete" className="mx-auto mb-3 " style={{ height: "70px", borderRadius: "30px" }} />
                    <h2 className='welcome-title mb-3'>Are you sure you want to delete?</h2>
                    <h5 className='welcome-subtext'>This action is irreversible and will permanently</h5>
                    <h5 className='welcome-subtext'> remove the item from the system.</h5>
                    <div className='mt-5 '>
                        <div className="d-flex justify-content-center mt-3">
                            {/* <ButtonComponent className={"me-2 btn popup-cancel-button"} onClick={() => setDeleteModel(false)} style={{ width: "250px " }} value={"No, Keep it"}/> */}
                            <button className='me-2 btn popup-cancel-button ' onClick={() => setDeleteModel(false)} style={{ width: "250px " }}>No, Keep it</button>
                            <button className='me-2 btn popup-cancel-button-outline ' onClick={() => alert("Deleting")} style={{ width: "250px " }}>Yes, Delete it</button>
                        </div>
                    </div>
                </div>
                <br /><br />
            </Modal.Body>
        </Modal>



    </div >

)
}

export default BranchForm