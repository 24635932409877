import React from 'react'

const PeriodComponent = () => {
    return (
        <div className="period-box me-2 ms-3 mb-3 mt-3 " style={{
            border: "none",
            width:
                // !edit ? "170px" : 
                "200px"
            , height: "80px"
        }}>
            <div className='d-flex'>
                <h6 className="period-title ms-2 mt-2 mb-0 me-4">Period 1</h6>
                <input type="checkbox" id="Teacher" /><label htmlFor="Teacher" className="period-break my-auto ms-2" >break</label>
                <p className="period-break my-auto ms-2" >x</p>
            </div>

            <div className="d-flex justify-contents-start" style={{ textAlign: "left" }} >
                <div className='d-flex flex-column mb-3 ms-2'>
                    <label for="from" className='period-from '>From</label>
                    <input type="time" className='period-time' readOnly value={"15:32"} />
                </div>
                <div className='d-flex flex-column mb-3 ms-2'>
                    <label for="to" className='period-from '>To</label>
                    <input type="time" className='period-time' readOnly value={"15:32"} />
                </div>
            </div>
        </div>
    )
}

export default PeriodComponent