
import { SmallAddIcon } from "@chakra-ui/icons";
import {Button} from "@chakra-ui/react";
import "./Button.css"

const ButtonComponent  = ({onClick, className, value }) =>{

    return(
        <>
        <Button size="sm" className={className} onClick={onClick} value={value}>
          {className === 'createbtn' ?(
            <>         
             <SmallAddIcon boxSize={6} /> 
          </>
          ): null}
         <span style={{ fontWeight: "600" }}>{value}</span>
        </Button>
        </>
    )
}
export default ButtonComponent 