import Inputcomponent from "../../../components/Input/Input";
import Labelcomponent from "../../../components/Label/Label";
const PersonalForm = ({type, personalformdata, employeedata}) =>{
    const handleInputchange = (e) =>{
        const {name, value} = e.target
        employeedata((prevdata)=> ({
            ...prevdata,
            [name]:value
        }))
    }
    return(
        <form className="form-horizontal mt-2 h-auto" >

        <div className="row col-12 mx-auto ">

            <div className="form-group row col-10 mx-auto p-0" >

                <div className="col-6 mt-2" >
                    <Labelcomponent  value={"First Name"} important={"important"}/>
                    <Inputcomponent name={"firstName"} onChange={handleInputchange} value={personalformdata?.firstName} placeholder={"First name"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>                              
                </div>

                <div className="col-6 mt-2" >
                     <Labelcomponent  value={"Last name"} />
                    <Inputcomponent name={"lastName"} onChange={handleInputchange} value={personalformdata?.lastName} placeholder={"Last name"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

            </div>

            <div className="form-group row col-10 mx-auto p-0" >

                <div className="col-4 mt-2" >
                     <Labelcomponent  value={"Date of birth"} />
                    <Inputcomponent name={"dateOfBirth"} onChange={handleInputchange} value={personalformdata?.dateOfBirth} placeholder={"05/05/1999"} type={"date"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

                <div className="col-4 mt-2" >
                    <Labelcomponent  value={"Gender"} />
                    <select name={"gender"} onChange={handleInputchange} value={personalformdata?.gender}  className='gender-select mt-2' disabled={type === "View" ? true : false}>
                        <option value="0" disabled defaultChecked>Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="others">others</option>
                    </select>
                </div>
                
                <div className="col-4 mt-2" >
                <Labelcomponent  value={"Blood group"} />
                <Inputcomponent name={"bloodGroup"} onChange={handleInputchange} value={personalformdata?.bloodGroup} placeholder={"Blood group"} type={"text"} className={"form-control"}  disabled={type === "View" ? true : false}/>
                </div>

            </div>

            {/* <div className="form-group col-10  mx-auto" >
            <label className="add-branch-label"> Blood group <span style={{ color: "red" }}> * </span></label>
            <select className="blood-group-dropdown">
                {bloodGroups.map((group) => (
                    <option key={group} value={group}>
                        {group}
                    </option>
                ))}
            </select>
        </div> */}

            <div className="form-group col-10 mt-2  mx-auto" >
                <label className="add-branch-label"> Upload photo in JPG, JPEG and PNG format </label>
                <div className="d-flex">
                    <input name={"photoUrl"} onChange={handleInputchange} value={personalformdata?.photoUrl} type={"file"} accept="image/png, image/gif, image/jpeg" className="form-control add-branch-input me-3" placeholder="" disabled={type === "View" ? true : false} />
                    {/* <ButtonComponent className={"mt-2 mb-2 col-2 uploadbtn"} value={"Upload"}/> */}
                </div>
            </div>
        </div>
    </form >
    )
}

export default PersonalForm