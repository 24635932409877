import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
    Box,
    HStack,
    Input,
    InputLeftElement,
    Text,
    Wrap,
    WrapItem,
    InputGroup,
    Button,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer, IconButton, Icon
} from "@chakra-ui/react";

// icons
import eye from "../../../assests/images/icons/eye.svg";
import bin from "../../../assests/images/icons/bin.svg";
import main from "../../../assests/images/icons/main.svg"
import pencil from "../../../assests/images/icons/pencil.svg";

import { MdCompareArrows } from "react-icons/md"

const DataTable = ({ allBranchesData, setProfileType }) => {


    const data = [
        { Name: "Emp Name", Phone_Number: 123456879, Roles4: "Teacher" },

    ];


    const handleDelete = (id) => {
        alert(`Deleting item with id: ${id}`);
    };

    return (
        <TableContainer className="custom-scrollbar mt-3">
            <Table variant="simple" size="sm" style={{ textAlign: "center" }}>
                <Tbody fontSize="sm" padding={0}>
                    <Tr className='branch-data-table-header'>
                        <Td>Class<Icon as={MdCompareArrows} transform="rotate(90deg)" /></Td>
                        <Td>Sections</Td>
                        <Td>Action</Td>
                    </Tr>
                    {data.map((item) =>
                        <Tr className='branch-data-table' size="sm" key={item.no}>
                            <Td>{item.Name}</Td>
                            <Td >
                                <div className='d-flex'>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >A</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >B</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >C</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >D</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >E</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >F</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >G</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >H</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >I</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >J</span>
                                    <span className='roles-data' style={{ background: "#379DDB1A", color: '#379DDB', width: "35px" }} >K</span>
                                </div>
                            </Td>
                            <Td>
                                <div className='d-flex'>
                                    <img className='me-2 icon' src={eye} alt="View" onClick={() => setProfileType("View", "id")} />
                                    <img className='me-2 icon' src={pencil} alt="Edit" onClick={() => setProfileType("Edit", "id")} />
                                    <img className='me-2 icon' src={bin} alt="Delete" onClick={() => handleDelete("id")} />
                                </div>
                            </Td>
                        </Tr>
                    )}

                </Tbody>
            </Table>
        </TableContainer >
    )
}

export default DataTable