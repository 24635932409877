import './Label.css'

function Labelcomponent({value, important, className = ""}) {
    return(
        <>
        <label className={`add-branch-label ${className}`} > {value} </label>
        {important === 'important' && (
            <span style={{ color: "red" }}> * </span>
        )}
        </>
    )
}

export default Labelcomponent