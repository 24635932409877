import {
    Button, Input,
} from "@chakra-ui/react";

import Modal from 'react-bootstrap/Modal';

import { countryCodeList } from "../../../constants"
import { Checkbox } from '@chakra-ui/react'
import React, { useState } from "react";
import { Radio, RadioGroup } from '@chakra-ui/react'
import bin from "../../../assests/images/icons/bin.svg"
import { ArrowBackIcon } from "@chakra-ui/icons";

import add_button from "../../../assests/images/icons/add_button.svg"
import delete_button from "../../../assests/images/icons/delete_button.svg"
import Labelcomponent from '../../../components/Label/Label'
import Inputcomponent from "../../../components/Input/Input";
import ButtonComponent from "../../../components/Button/Button";

const BranchAddProfile = ({ setBranchAddProfile, teacherData, onSave, type, onCancel, onEdit }) => {

    const [activeTab, setActiveTab] = useState("personal")
    //const[contact,setContact] = useState("contact")
    const [teacherFormData, setTeacherFormData] = useState(teacherData || "");
    const [model, setModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
   

    const handleChange = (e) => {
        const { name, value } = e.target;

        setTeacherFormData((prevTeacherData) => ({
            ...prevTeacherData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //setBranchAddProfile(false);
        onSave(teacherFormData);
    };

    const handleCancel = () => {
        setTeacherFormData(teacherFormData);
        // setBranchAddProfile(false);
        onCancel();
    };
    const handleEdit = () => {

        onEdit();
    }
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 50;

    const years = Array.from({ length: 51 }, (_, index) => startYear + index);

    const bloodGroups = [
        'A+',
        'A-',
        'B+',
        'B-',
        'AB+',
        'AB-',
        'O+',
        'O-',
    ];



    return (


        <div className="mt-3 pb-5" >

            <div class="d-flex col-12 align-items-end">

                <div class="d-flex col-6 align-items-end">
                    <h1 class="add-branch-title ms-3 ">Employees</h1>
                    <h1 class="add-branch-subtitle ps-2">{">"} {type}</h1>
                </div>


                {/* <div class="d-flex col-6 justify-content-end">
                    <label for="inputGroupSelect" class="teacher-campus-select">Academic Year</label>
                    &nbsp;
                    <select name="academic-select" class="custom-select">
                        <option value="1">2003-2004</option>
                        <option value="2">2004-2005</option>
                    </select>
                </div> */}
            </div>



            <div className="row col-12 p-0 mx-auto mt-3">
                <Button className={`col-4 add-branch-header-tab${activeTab === "personal" ? "-active" : ""}`} onClick={() => setActiveTab("personal")}>Personal</Button>
                <Button className={`col-4 add-branch-header-tab${activeTab === "contact" ? "-active" : ""}`} onClick={() => setActiveTab("contact")}>Contact</Button>
                <Button className={`col-4 add-branch-header-tab${activeTab === "employee" ? "-active" : ""}`} onClick={() => setActiveTab("employee")}>Employee Information</Button>

            </div>
            {activeTab === "personal" ?
                <form className="form-horizontal mt-2 h-auto" >

                    <div className="row col-12 mx-auto ">
                        <div className="form-group col-10 mx-auto" >
                            <Labelcomponent value={"Branch"}/>
                            <Inputcomponent placeholder={"saras-branch"} type={"text"} className={"form-control"} disabled={"disabled"}/>
                            {/* <label className="add-branch-label">Branch</label> */}
                            {/* <input type="text" className="form-control add-branch-input" placeholder="saras-branch" disabled /> */}
                        </div>

                        <div className="form-group row col-10 mx-auto p-0" >

                            <div className="col-6 mt-2" >
                                <Labelcomponent value={"First name"} important={"important"}/>
                                <Inputcomponent placeholder={"First name"} type={"text"} className={"form-control"} disabled={type === "View" ? true : false}/>
                                {/* <label className="add-branch-label">First Name<span style={{ color: "red" }}> * </span></label>
                                <input type="text" className="form-control add-branch-input" placeholder="First name" disabled={type === "View" ? true : false} /> */}
                            </div>

                            <div className="col-6 mt-2" >
                                  <Labelcomponent value={"Last name"}/>
                                <Inputcomponent placeholder={"Last name"} type={"text"} className={"form-control"} disabled={type === "View" ? true : false}/>
                                {/* <label className="add-branch-label">Last name</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Last name" disabled={type === "View" ? true : false} /> */}
                            </div>
                        </div>

                        <div className="form-group row col-10 mx-auto p-0" >

                            <div className="col-4 mt-2" >
                                 <Labelcomponent value={"Date of birth"} important={"important"}/>
                                <Inputcomponent placeholder={"05/05/1999"} type={"date"} className={"form-control"} disabled={type === "View" ? true : false}/>
                                {/* <label className="add-branch-label">Date of birth<span style={{ color: "red" }}> * </span></label>
                                <input type="date" className="form-control add-branch-input" placeholder="05/05/1999" disabled={type === "View" ? true : false} /> */}
                            </div>

                            <div className="col-4 mt-2" >
                                <Labelcomponent value={"Gender"}/>
                                {/* <label className="add-branch-label ">Gender</label> */}
                                <select name="gender" className='gender-select mt-2' disabled={type === "View" ? true : false}>
                                    <option value="0" disabled defaultChecked>Select</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                    <option value="3">Prefer not to say</option>
                                </select>
                            </div>
                            <div className="col-4 mt-2" >
                                <Labelcomponent value={"Blood group"}/>
                                <Inputcomponent placeholder={"Blood group"} type={"text"} className={"form-control"} disabled={type === "View" ? true : false}/>
                                {/* <label className="add-branch-label">Blood group</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Blood group" disabled={type === "View" ? true : false} /> */}
                            </div>

                        </div>

                        {/* <div className="form-group col-10  mx-auto" >
                    <label className="add-branch-label"> Blood group <span style={{ color: "red" }}> * </span></label>
                    <select className="blood-group-dropdown">
                        {bloodGroups.map((group) => (
                            <option key={group} value={group}>
                                {group}
                            </option>
                        ))}
                    </select>
                </div> */}
                        <div className="form-group row col-10 mx-auto p-0" >
                        <div className="col-12 mt-2" >
                             <Labelcomponent value={"Upload photo in JPG, JPEG and PNG format"}/>
                                </div>
                            <div className="col-10 " >
                            <Inputcomponent placeholder={""} type={"text"} className={"form-control"} disabled={type === "View" ? true : false} accept={"image/png, image/gif, image/jpeg"}/>
                            </div>

                            <div className="col-2 " >
                            <ButtonComponent className={"uploadbtn w-100"} value={"upload"}/>
                            </div>

                            </div>
                        {/* <div className="form-group col-10 mt-2  mx-auto" >
                        
                            <label className="add-branch-label"> Upload photo in JPG, JPEG and PNG format </label>
                            <div className="d-flex">
                                <input type="text" accept="image/png, image/gif, image/jpeg" className="form-control add-branch-input " placeholder="" disabled={type === "View" ? true : false} />
                                <button className="mt-2 mb-2 btn add-new-branch col-2" style={{ height: "40px" }} >Upload</button>
                            </div>
                        </div> */}
                    </div>
                </form >
                :
                activeTab === "contact" ?
                    <form className="form-horizontal mt-4" >
                        <div className="row col-12 mx-auto ">

                            <div className="form-group row col-10 mx-auto p-0" >
                                <Labelcomponent value={"Address"}/>
                                {/* <label className="add-branch-label">Address</label> */}
                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Door number"}/>
                                    <Inputcomponent type={"text"} className={"form-control"} placeholder={"Door No."} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Door number</label> */}
                                    {/* <input type="text" className="form-control add-branch-input" placeholder="Door No." disabled={type === "View" ? true : false} /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                      <Labelcomponent value={"Line 1"}/>
                                    <Inputcomponent type={"text"} className={"form-control"} placeholder={"Line 1"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Line 1</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Line 1" disabled={type === "View" ? true : false} /> */}
                                </div>

                            </div>

                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Line 2"}/>
                                    <Inputcomponent type={"text"} className={"form-control"} placeholder={"Line 2"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Line 2</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Line 2" disabled={type === "View" ? true : false} /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                     <Labelcomponent value={"Landmark"}/>
                                    <Inputcomponent type={"text"} className={"form-control"} placeholder={"Landmark"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Landmark</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Landmark" disabled={type === "View" ? true : false} /> */}
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-6 mt-2" >
                                      <Labelcomponent value={"City"}/>
                                    <Inputcomponent type={"text"} className={"form-control"} placeholder={"City"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">City</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="City" disabled={type === "View" ? true : false} /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                     <Labelcomponent value={"State / Province / Region"}/>
                                    <Inputcomponent type={"text"} className={"form-control"} placeholder={"State / Province / Region"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">State / Province / Region</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="State / Province / Region" disabled={type === "View" ? true : false} /> */}
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-6 mt-2" >
                                     <Labelcomponent value={"ZIP / Postal / Pin Code"}/>
                                    <Inputcomponent type={"text"} className={"form-control"} placeholder={"ZIP / Postal / Pin Code"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">ZIP / Postal / Pin Code</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="ZIP / Postal / Pin Code" disabled={type === "View" ? true : false} /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                     <Labelcomponent value={"Country"}/>
                                    <Inputcomponent type={"text"} className={"form-control"} placeholder={"Country"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Country</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Country" disabled={type === "View" ? true : false} /> */}
                                </div>

                            </div>


                            <div className="form-group row col-10  mx-auto mt-2 p-0" >
                                <div className="col-6" >
                                     <Labelcomponent value={"Phone number"} important={"important"}/>
                                    {/* <label className="add-branch-label">Phone number<span style={{ color: "red" }}> * </span></label> */}
                                    <div className="d-flex">
                                        <select className=" mt-2 phone-number-select">
                                            {countryCodeList.map((country) => (
                                                <option value={country.name} >
                                                    {country.code + "  " + country.dial_code}
                                                </option>
                                            ))}
                                        </select>
                                        <Inputcomponent type={"text"} className={"form-control"} placeholder="99999999" disabled={type === "View" ? true : false}/>
                                        {/* <input type="tel" className="form-control add-branch-input" placeholder="99999999" disabled={type === "View" ? true : false}/> */}
                                    </div>
                                </div>
                                <div className="col-6" >
                                    <Labelcomponent value={"Email"}/>
                                    <Inputcomponent type={"email"} className={"form-control"} placeholder={"abc@gmail.com"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Email</label>
                                    <input type="email" className="form-control add-branch-input" placeholder="abc@gmail.com" disabled={type === "View" ? true : false} /> */}
                                </div>
                            </div>
                            <div className="form-group row col-10 mx-auto p-0" >
                                <Labelcomponent value={"Emergency contact"} className="mt-3"/>
                                {/* <label className="add-branch-label mt-3">Emergency contact</label> */}
                                <div className="col-3 mt-2" >
                                     <Labelcomponent value={"Person name"}/>
                                     <Inputcomponent type={"text"} className={"form-control"} placeholder={"Field"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label" style={{ fontSize: "13px" }}>Person name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Field" disabled={type === "View" ? true : false} /> */}
                                </div>
                                <div className="col-3 mt-2" >
                                      <Labelcomponent value={"Relation name"}/>
                                     <Inputcomponent type={"text"} className={"form-control"} placeholder={"Field"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label" style={{ fontSize: "13px" }}>Relation name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Field" disabled={type === "View" ? true : false} /> */}
                                </div>

                                <div className="col-4 mt-2" >
                                      <Labelcomponent value={"Relation name"} important={"important"}/>
                                    {/* <label className="add-branch-label">Phone number<span style={{ color: "red" }}> * </span></label> */}
                                    {/* <input type="tel" className="form-control add-branch-input" placeholder="99999999" /> */}
                                    <div className="d-flex">
                                        <select className=" mt-2 phone-number-select">
                                            {countryCodeList.map((country) => (
                                                <option value={country.name} >
                                                    {country.code + "  " + country.dial_code}
                                                </option>
                                            ))}
                                        </select>
                                        <Inputcomponent type={"tel"} className={"form-control"} placeholder={"99999999"} disabled={type === "View" ? true : false}/>
                                        {/* <input type="tel" className="form-control add-branch-input" placeholder="99999999"  disabled={type === "View" ? true : false} /> */}
                                    </div>
                                </div>
                                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                                    <img src={add_button} alt="add_button" className="btn add-upload-button p-2 pb-0" />
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                                    <img src={delete_button} alt="delete_button" className="btn add-upload-button p-2 pb-0 " />
                                </div>
                            </div>


                        </div>

                    </form>
                    :
                    <form className="form-horizontal mt-4" >
                        <div className="row col-12 mx-auto ">

                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-4 mt-2" >
                                    <Labelcomponent value={"Employee Designation"}/>
                                     <Inputcomponent type={"text"} className={"form-control"} placeholder={"Designation"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Employee Designation</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Designation" disabled={type === "View" ? true : false} /> */}
                                </div>

                                <div className="col-4 mt-2" >
                                     <Labelcomponent value={"Joining date"}/>
                                     <Inputcomponent type={"date"} className={"form-control"} placeholder={""} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Joining date</label>
                                    <input type="date" className="form-control add-branch-input" placeholder=" " disabled={type === "View" ? true : false} /> */}
                                </div>
                                <div className="col-4 mt-2" >
                                     <Labelcomponent value={"Leaving date"}/>
                                     <Inputcomponent type={"date"} className={"form-control"} placeholder={""} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Leaving date</label>
                                    <input type="date" className="form-control add-branch-input" placeholder=" " disabled={type === "View" ? true : false} /> */}
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0 mt-3" >
                                     <Labelcomponent value={"Roles and Responsibility"}/>
                                {/* <label className="add-branch-label">Roles and Responsibility</label> */}
                                <div className="col-4 mt-2" >
                                    <input type="checkbox" id="Teacher" disabled={type === "View" ? true : false} />
                                    <label htmlFor="Teacher" className="add-branch-label ms-1" >Teacher</label>
                                </div>
                            </div>

                            <div className="form-group row col-10 mx-auto p-0 mt-3" >
                            <Labelcomponent value={"Education"}/>
                                {/* <label className="add-branch-label">Education</label> */}
                                <div className="col-3 mt-2" >
                                      <Labelcomponent value={"Degree/Class"}/>
                                     <Inputcomponent type={"text"} className={"form-control"} placeholder={"Degree/class"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Degree/Class</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Degree/class" disabled={type === "View" ? true : false} /> */}
                                </div>

                                <div className="col-2 mt-2" >
                                     <Labelcomponent value={"Course"}/>
                                     <Inputcomponent type={"text"} className={"form-control"} placeholder={"Course"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label">Course</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Course" disabled={type === "View" ? true : false} /> */}
                                </div>
                                <div className="col-3 mt-2" >
                                    <Labelcomponent value={"School / College / University"}/>
                                     <Inputcomponent type={"text"} className={"form-control"} placeholder={"School / College / University"} disabled={type === "View" ? true : false}/>
                                    {/* <label className="add-branch-label" style={{ fontSize: "11px" }}>School / College / University</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="School / College / University" disabled={type === "View" ? true : false} /> */}
                                </div>

                                <div className="col-2 mt-2" >
                                <Labelcomponent value={"Passout year"}/>
                                    {/* <label className="add-branch-label">Passout year</label> */}
                                    <select className="form-select year-dropdown mt-2">
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                                    <img src={add_button} alt="add_button" className="btn add-upload-button p-2 pb-0" />
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                                    <img src={delete_button} alt="delete_button" className="btn add-upload-button p-2 pb-0 " />
                                </div>
                            </div>

                            {/* <div className="form-group row col-10 mx-auto p-0" >

                            <div className="col-4 mt-2" >
                                <label className="add-branch-label">Id Proof name</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Id Proof name" />
                            </div>

                            <div className="col-4 mt-2" >
                                <label className="add-branch-label">Id proof number</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Id proof number" />
                            </div>
                            <div className="col-2 mt-2" >
                                <label className="add-branch-label">Id Proof name</label>
                                <button className=' form-control mt-2 mb-2 btn add-new-branch' style={{ width: "70%" }}>Upload</button>
                            </div>

                            <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ">Add</button>
                            </div>

                            <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                            </div>

                        </div>


                        <div className="form-group row col-10 mx-auto p-0" >

                            <div className="form-group row col-12 mx-auto p-0" >

                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Id Proof name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Id Proof name" />
                                </div>

                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Id proof number</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Id proof number" />
                                </div>
                                <div className="col-2 mt-2" >
                                    <label className="add-branch-label">Id Proof name</label>
                                    <button className=' form-control mt-2 mb-2 btn add-new-branch' style={{ width: "70%" }}>Upload</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ">Add</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                                </div>

                            </div>

                        </div>




                        <div className="form-group row col-10 mx-auto mt-2 p-0" >
                            {/* <div className="col-6 mt-2" > 
                            <RadioGroup defaultValue='1'>
                                <Radio colorScheme='purple' value='1' className="me-2" >
                                    <span className="radio-button-text">Yes,comes by school transportation</span>
                                </Radio>
                                {/* </RadioGroup> */}
                            {/* </div> */}
                            {/* <div className="col-6 mt-2" > */}
                            {/* <RadioGroup defaultValue='2' className="col-6 mt-2"> 
                                <Radio colorScheme='purple' value='2' className="ms-5 me-2"  >
                                    <span className="radio-button-text"> No,comes by own transportation</span>
                                </Radio>
                            </RadioGroup>
                            {/* </div> 
                        </div>

                        <div className="form-group row col-10 mx-auto p-0" >
                            <div className="form-group col-6  mx-auto mt-2" >
                                <label className="add-branch-label"> From </label>
                                <input type="text" className="form-control add-branch-input" placeholder="From (place)" />
                            </div>

                            <div className="form-group col-6  mx-auto mt-2" >
                                <label className="add-branch-label">To</label>
                                <input type="text" className="form-control add-branch-input" placeholder="To (place)" />
                            </div>
                        </div> */}

                        </div>

                    </form >
            }
            {type === "Add" || type === "Edit" ?

                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <ButtonComponent onClick={() => setModel(true)} className={"me-2 cancelbtn"} value={"Cancel"}/>
                        {/* <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={() => setModel(true)}>Cancel </button> */}
                        {activeTab === "personal" ?
                        <ButtonComponent onClick={() => setActiveTab("contact")} className={"me-2 savebtn"} value={"Continue"}/>
                            // <button className='me-2 btn button-1' style={{ width: "95px" }} onClick={() => setActiveTab("contact")}>Continue</button>
                            :
                            activeTab === "contact" ?
                            <ButtonComponent onClick={() => setActiveTab("employee")} className={"me-2 savebtn"} value={"Continue"}/>
                                // <button className='me-2 btn button-1' style={{ width: "95px" }} onClick={() => setActiveTab("employee")}>Continue</button>
                                :
                                <ButtonComponent onClick={handleSubmit} className={"me-2 savebtn"} value={"Save"}/>
                                // <button className='me-2 btn button-1' style={{ width: "90px" }} onClick={handleSubmit}>Save</button>
                        }
                    </div>
                </div>
                :
                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <Button className='me-3 left-arrow-button' variant="link" leftIcon={<ArrowBackIcon />} style={{ width: "90px" }} onClick={() => setBranchAddProfile("")}>Back</Button>
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={handleEdit}>Edit</button>
                        <Button className='me-3 text-delete-button' variant="link" style={{ width: "90px" }} onClick={() => setDeleteModel(true)}>Delete</Button>
                    </div>
                </div>
            }

            <Modal
                show={model}
                size="lg"
                animation
                centered
                onHide={() => setModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <h2 className='welcome-title'>Are you sure you want to cancel?</h2>
                        <h5 className='welcome-subtext'>Any unsaved changes will be lost</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setModel(false)}>No, I want to continue Editing</button>
                                <button className='me-2 btn popup-cancel-button-outline ' onClick={handleCancel}>Yes I Want to cancel it</button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>
            <Modal
                show={deleteModel}
                size="lg"
                animation
                centered
                onHide={() => setDeleteModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <img src={bin} alt="delete" className="mx-auto mb-3 " style={{ height: "70px", borderRadius: "30px" }} />
                        <h2 className='welcome-title mb-3'>Are you sure you want to delete?</h2>
                        <h5 className='welcome-subtext'>This action is irreversible and will permanently</h5>
                        <h5 className='welcome-subtext'> remove the item from the system.</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setDeleteModel(false)} style={{ width: "250px " }}>No, Keep it</button>
                                <button className='me-2 btn popup-cancel-button-outline ' onClick={() => alert("Deleting")} style={{ width: "250px " }}>Yes, Delete it</button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>


        </div >

    )
}

export default BranchAddProfile