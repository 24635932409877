import {
    Button, Input,
} from "@chakra-ui/react";

import Modal from 'react-bootstrap/Modal';

import { countryCodeList } from "../../../constants"
import { Checkbox } from '@chakra-ui/react'
import React, { useState } from "react";
import { Radio, RadioGroup } from '@chakra-ui/react'
import bin from "../../../assests/images/icons/bin.svg"
import { ArrowBackIcon } from "@chakra-ui/icons";
import Labelcomponent from "../../../components/Label/Label"
import Inputcomponent from "../../../components/Input/Input"
import ButtonComponent from "../../../components/Button/Button";


const StudentsAddProfile = ({ setStudentsAddProfile, teacherData, onSave, type, onCancel, onEdit }) => {

    const [activeTab, setActiveTab] = useState("personal")
    //const[contact,setContact] = useState("contact")
    const [teacherFormData, setTeacherFormData] = useState(teacherData || "");
    const [model, setModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setTeacherFormData((prevTeacherData) => ({
            ...prevTeacherData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //setStudentsAddProfile(false);
        onSave(teacherFormData);
    };

    const handleCancel = () => {
        setTeacherFormData(teacherFormData);
        // setStudentsAddProfile(false);
        onCancel();
    };
    const handleEdit = () => {

        onEdit();
    }
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 50;

    const years = Array.from({ length: 51 }, (_, index) => startYear + index);

    const bloodGroups = [
        'A+',
        'A-',
        'B+',
        'B-',
        'AB+',
        'AB-',
        'O+',
        'O-',
    ];



    return (


        <div className="mt-3 pb-5" >

            <div class="d-flex col-12 align-items-end">

                <div class="d-flex col-6 align-items-end">
                    <h1 class="add-branch-title ms-3 mb-0">Student</h1>
                    <h1 class="add-branch-subtitle ms-2 mb-0">{">"} {type}</h1>
                </div>


                {/* <div class="d-flex col-6 justify-content-end">
                    <label for="inputGroupSelect" class="teacher-campus-select">Academic Year</label>
                    &nbsp;
                    <select name="academic-select" class="custom-select">
                        <option value="1">2003-2004</option>
                        <option value="2">2004-2005</option>
                    </select>
                </div> */}
            </div>



            <div className="row col-12 p-0 mx-auto mt-3">
                <Button className={`col-4 add-branch-header-tab${activeTab === "personal" ? "-active" : ""}`} onClick={() => setActiveTab("personal")}>Personal</Button>
                <Button className={`col-4 add-branch-header-tab${activeTab === "contact" ? "-active" : ""}`} onClick={() => setActiveTab("contact")}>Contact</Button>
                <Button className={`col-4 add-branch-header-tab${activeTab === "employee" ? "-active" : ""}`} onClick={() => setActiveTab("employee")}>Parents Information</Button>

            </div>
            {activeTab === "personal" ?
                <form className="form-horizontal mt-2 h-auto" >

                    <div className="row col-12 mx-auto mt-5 ">
                        <div className="form-group col-10 mx-auto" >
                            <Labelcomponent value={"Branch"}/>
                            <Inputcomponent className={"form-control"} type="text" placeholder={"saras-branch"} disabled={"disabled"}/>
                            {/* <label className="add-branch-label">Branch</label>
                            <input type="text" className="form-control add-branch-input" placeholder="saras-branch" disabled /> */}
                        </div>

                        <div className="form-group row col-10 mx-auto p-0" >

                            <div className="col-6 mt-2" >
                                <Labelcomponent value={"First Name"} important={"important"}/>
                                <Inputcomponent className={"form-control"} placeholder={"First name"}/>
                                {/* <label className="add-branch-label">First Name<span style={{ color: "red" }}> * </span></label>
                                <input type="text" className="form-control add-branch-input" placeholder="First name" /> */}
                            </div>

                            <div className="col-6 mt-2" >
                            <Labelcomponent value={"Last Name"}/>
                            <Inputcomponent className={"form-control"} type="text" placeholder={"Last name"}/>
                                {/* <label className="add-branch-label">Last name</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Last name" /> */}
                            </div>
                        </div>

                        <div className="form-group row col-10 mt-2  mx-auto p-0" >

                            <div className="col-3 mt-2" >
                            <Labelcomponent value={"Roll number"}/>
                            <Inputcomponent className={"form-control"} type="text" placeholder={"RA1931005040029"}/>
                                {/* <label className="add-branch-label">Roll number</label>
                                <input type="text" className="form-control add-branch-input" placeholder="RA1931005040029" /> */}
                            </div>

                            <div className="col-3 mt-2" >
                                <Labelcomponent value={"Class"} important={"important"}/>
                                {/* <label className="add-branch-label">Class <span style={{ color: "red" }}> * </span></label> */}
                                <select name="class" className='gender-select mt-2 '>
                                    <option value="0" disabled defaultChecked> &nbsp; Select</option>
                                    <option value="1">&nbsp; 1</option>
                                    <option value="2">&nbsp; 2</option>
                                    <option value="3">&nbsp; 3</option>
                                </select>
                            </div>

                            <div className="col-3 mt-2" >
                               <Labelcomponent value={"Section"} />
                                {/* <label className="add-branch-label">Section</label> */}
                                <select name="section" className='gender-select mt-2 '>
                                    <option value="0" disabled defaultChecked> &nbsp; Select</option>
                                    <option value="1">&nbsp; A</option>
                                    <option value="2">&nbsp; B</option>
                                    <option value="3">&nbsp; C</option>
                                </select>
                            </div>

                            <div className="col-3 mt-2" >
                                <Labelcomponent value={"Join Date"}/>
                                <Inputcomponent className={"form-control"} type="date" />
                                {/* <label className="add-branch-label">Join Date</label>
                                <input type="date" className="form-control add-branch-input" placeholder="Last name" /> */}
                            </div>
                        </div>


                        <div className="form-group row col-10 mt-2  mx-auto p-0" >

                            <div className="col-4 mt-2" >
                                  <Labelcomponent value={"Date of birth"} important={"important"}/>
                                  <Inputcomponent className={"form-control"} type="date" />
                                {/* <label className="add-branch-label">Date of birth<span style={{ color: "red" }}> * </span></label>
                                <input type="date" className="form-control add-branch-input" placeholder="05/05/1999" /> */}
                            </div>

                            <div className="col-4 mt-2" >
                                <Labelcomponent value={"Gender"}/>
                                {/* <label className="add-branch-label ">Gender</label> */}
                                <select name="gender" className='gender-select mt-2'>
                                    <option value="0" disabled defaultChecked>Select</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                    <option value="3">Prefer not to say</option>
                                </select>
                            </div>
                            <div className="col-4 mt-2" >
                                <Labelcomponent  value={"Blood Group"}/>
                                <Inputcomponent className={"form-control"} type={"text"} placeholder={"Blood Group"}/>
                                {/* <label className="add-branch-label">Blood group</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Blood group" /> */}
                            </div>

                        </div>

                        {/* <div className="form-group col-10  mx-auto" >
                    <label className="add-branch-label"> Blood group <span style={{ color: "red" }}> * </span></label>
                    <select className="blood-group-dropdown">
                        {bloodGroups.map((group) => (
                            <option key={group} value={group}>
                                {group}
                            </option>
                        ))}
                    </select>
                </div> */}

                        <div className="form-group col-10 mt-4  mx-auto" >
                            <Labelcomponent value={"Upload photo in JPG, JPEG and PNG format"}/>
                            {/* <label className="add-branch-label"> Upload photo in JPG, JPEG and PNG format </label> */}
                            <div className="d-flex align-items-center">
                                <Inputcomponent className={"form-control"} accept={"image/png, image/gif, image/jpeg"} placeholder=""  disabled={type === "View" ? true : false}/>
                                <ButtonComponent className={"uploadbtn"} value={"upload"}/>
                                {/* <input type="text" accept="image/png, image/gif, image/jpeg" className="form-control add-branch-input " placeholder="" disabled={type === "View" ? true : false} />
                                <button className="mt-2 mb-2 btn add-new-branch col-2" style={{ height: "40px" }} >Upload</button> */}
                            </div>
                        </div>

                    </div>
                </form >
                :
                activeTab === "contact" ?
                    <form className="form-horizontal mt-4" >
                        <div className="row col-12 mx-auto ">

                            <div className="form-group row col-10 mx-auto p-0" >
                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Door number"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Door No."}/>
                                    {/* <label className="add-branch-label">Door number</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Door No." /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Address line 1"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"line 1"}/>
                                    {/* <label className="add-branch-label">Address line 1</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="line 1" /> */}
                                </div>

                            </div>

                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Address line 2"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"line 2"}/>
                                    {/* <label className="add-branch-label">Address line 2</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="line 2" /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Landmark"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Landmark"}/>
                                    {/* <label className="add-branch-label">Landmark</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Landmark" /> */}
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"City"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"City"}/>
                                    {/* <label className="add-branch-label">City</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="City" /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"State / Province / Region"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"State / Province / Region"}/>
                                    {/* <label className="add-branch-label">State / Province / Region</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="State / Province / Region" /> */}
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"ZIP / Postal / Pin Code"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"ZIP / Postal / Pin Code"}/>
                                    {/* <label className="add-branch-label">ZIP / Postal / Pin Code</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="ZIP / Postal / Pin Code" /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Country"} />
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Country"}/>
                                    {/* <label className="add-branch-label">Country</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Country" /> */}
                                </div>

                            </div>
                            <div className="form-group col-10 mx-auto mt-2" >
                                <Labelcomponent value={"Mail id"}/>
                                <Inputcomponent className={"form-control"} type={"email"} placeholder={"abc@gmail.com"}/>
                                {/* <label className="add-branch-label">Mail Id</label>
                                <input type="text" className="form-control add-branch-input" placeholder="abc@gmail.com" /> */}
                            </div>


                            {/* <div className="form-group row col-10  mx-auto mt-2 p-0" >
                                <div className="col-6" >
                                    <label className="add-branch-label"> Primary phone number<span style={{ color: "red" }}> * </span></label>
                                    <div className="d-flex">
                                        <select className=" mt-2 phone-number-select">
                                            {countryCodeList.map((country) => (
                                                <option value={country.name} >
                                                    {country.code + "  " + country.dial_code}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="tel"
                                            className="form-control add-branch-input"
                                            placeholder="99999999"
                                        />
                                    </div>
                                </div>
                                <div className="col-6" >
                                    <label className="add-branch-label">Mail id<span style={{ color: "red" }}> * </span></label>
                                    <input type="email" className="form-control add-branch-input" placeholder="abc@gmail.com" />
                                </div>
                            </div>
                            <div className="form-group row col-10 mx-auto p-0" >
                                <label className="add-branch-label mt-3">Emergency contact</label>
                                <div className="col-3 mt-2" >
                                    <label className="add-branch-label" style={{ fontSize: "13px" }}>Person name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Field" />
                                </div>
                                <div className="col-3 mt-2" >
                                    <label className="add-branch-label" style={{ fontSize: "13px" }}>Relation name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Field" />
                                </div>

                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Phone number<span style={{ color: "red" }}> * </span></label>
                                    {/* <input type="tel" className="form-control add-branch-input" placeholder="99999999" /> 
                                    <div className="d-flex">
                                        <select className=" mt-2 phone-number-select">
                                            {countryCodeList.map((country) => (
                                                <option value={country.name} >
                                                    {country.code + "  " + country.dial_code}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="tel"
                                            className="form-control add-branch-input"
                                            placeholder="99999999"
                                        />
                                    </div>
                                </div>
                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ps-6">Add</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                                </div>
                            </div> */}


                        </div>

                    </form>
                    :
                    <form className="form-horizontal mt-4" >
                        <div className="row col-12 mx-auto ">
                            <div className="form-group row col-10 mx-auto p-0" >
                                <Labelcomponent value={"Father's info"}/>
                                {/* <label className="add-branch-label mt-3">Father's info</label> */}


                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Full Name"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Full Name"}/>
                                    {/* <label className="add-branch-label">Full Name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Full Name" /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Phone number"}/>
                                    {/* <label className="add-branch-label ">Phone number <span style={{ color: "red" }}> * </span></label> */}

                                    <input type="checkbox" id="parentapp" className="ms-2" />
                                    <label htmlFor="parentapp" className="add-branch-label ms-1">Allow access to parent app</label>

                                    <div className="d-flex">
                                        <select className=" mt-2 phone-number-select">
                                            {countryCodeList.map((country) => (
                                                <option value={country.name} >
                                                    {country.code + "  " + country.dial_code}
                                                </option>
                                            ))}
                                        </select>
                                        <Inputcomponent className={"from-control"} type={"text"} placeholder={"99999999"}/>
                                        {/* <input type="tel" className="form-control add-branch-input" placeholder="99999999"/> */}
                                    </div>
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0 mt-3" >
                                <label className="add-branch-label">Education</label>
                                <div className="col-3 mt-2" >
                                      <Labelcomponent value={"Degree/Class"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Degree/Class"}/>
                                    {/* <label className="add-branch-label">Degree/Class</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Degree/class" /> */}
                                </div>

                                <div className="col-2 mt-2" >
                                    <Labelcomponent value={"Course"}/>
                                    <Inputcomponent className={"from-control"} type={"text"} placeholder={"course"}/>
                                    {/* <label className="add-branch-label">Course</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="course" /> */}
                                </div>
                                <div className="col-3 mt-2">
                                    <Labelcomponent value={"School / College / University"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"School / College / University"}/>
                                    {/* <label className="add-branch-label" style={{ fontSize: "11px" }}>School / College / University</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="School / College / University" /> */}
                                </div>

                                <div className="col-2 mt-2" >
                                    <Labelcomponent value={"Passout year"}/>
                                    {/* <label className="add-branch-label">Passout year</label> */}
                                    <select className="form-select year-dropdown mt-2">
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ps-6">Add</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Occupation"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Occupation"}/>
                                    {/* <label className="add-branch-label">Occupation</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Occupation" /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Annual income"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Annual income"}/>
                                    {/* <label className="add-branch-label">Annual income</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Annual income" /> */}
                                </div>

                            </div>

                            <div className="form-group row col-10 mx-auto p-0 mt-4" >
                                <Labelcomponent value={"Mother's info"}/>
                                {/* <label className="add-branch-label mt-3">Mother's info</label> */}


                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Full Name"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Full Name"}/>
                                    {/* <label className="add-branch-label">Full Name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Full Name" /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Phone number"} important={"important"}/>
                                    {/* <label className="add-branch-label ">Phone number <span style={{ color: "red" }}> * </span></label> */}
                                    <input type="checkbox" id="parentapp" className="ms-2" /><label htmlFor="parentapp" className="add-branch-label ms-1">Allow access to parent app</label>

                                    <div className="d-flex">
                                        <select className=" mt-2 phone-number-select">
                                            {countryCodeList.map((country) => (
                                                <option value={country.name} >
                                                    {country.code + "  " + country.dial_code}
                                                </option>
                                            ))}
                                        </select>
                                        {/* <input type="tel" className="form-control add-branch-input" placeholder="99999999" /> */}
                                        <Inputcomponent className={"form-control"} type={"tel"}  placeholder={"99999999"}/>
                                    </div>
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0 mt-3" >
                                <Labelcomponent value={"Education"}/>
                                {/* <label className="add-branch-label">Education</label> */}
                                <div className="col-3 mt-2" >
                                    <Labelcomponent value={"Degree/Class"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Degree/Class"}/>
                                    {/* <label className="add-branch-label">Degree/Class</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Degree/class" /> */}
                                </div>

                                <div className="col-2 mt-2" >
                                    <Labelcomponent value={"Course"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Course"}/>
                                    {/* <label className="add-branch-label">Course</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="course" /> */}
                                </div>
                                <div className="col-3 mt-2" >
                                    <Labelcomponent value={"School / College / University"} />
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"School / College / University"} />
                                    {/* <label className="add-branch-label" style={{ fontSize: "11px" }}>School / College / University</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="School / College / University" /> */}
                                </div>

                                <div className="col-2 mt-2" >
                                    <Labelcomponent value={"Passout year"}/>
                                    {/* <label className="add-branch-label">Passout year</label> */}
                                    <select className="form-select year-dropdown mt-2">
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ps-6">Add</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Occupation"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Occupation"}/>
                                    {/* <label className="add-branch-label">Occupation</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Occupation" /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Annual income"}/>
                                    <Inputcomponent className={"from-control"} type={"text"} placeholder={"Annual income"}/>
                                    {/* <label className="add-branch-label">Annual income</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Annual income" /> */}
                                </div>

                            </div>

                            <div className="form-group row col-10 mx-auto p-0 mt-4" >
                                <Labelcomponent value={"Gaurdian's info"}/>
                                {/* <label className="add-branch-label mt-3">Gaurdian's info</label> */}


                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Full Name"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Full Name"}/>
                                    {/* <label className="add-branch-label">Full Name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Full Name" /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Phone number"} important={"important"}/>
                                    {/* <label className="add-branch-label ">Phone number <span style={{ color: "red" }}> * </span></label> */}

                                    <input type="checkbox" id="parentapp" className="ms-2" /><label htmlFor="parentapp" className="add-branch-label ms-1">Allow access to parent app</label>

                                    <div className="d-flex">
                                        <select className=" mt-2 phone-number-select">
                                            {countryCodeList.map((country) => (
                                                <option value={country.name} >
                                                    {country.code + "  " + country.dial_code}
                                                </option>
                                            ))}
                                        </select>
                                        <Inputcomponent className={"form-control"} type={"tel"} placeholder={"99999999"}/>
                                        {/* <input type="tel" className="form-control add-branch-input" placeholder="99999999" /> */}
                                    </div>
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0 mt-3" >
                                <Labelcomponent value={"Education"}/>
                                {/* <label className="add-branch-label">Education</label> */}
                                <div className="col-3 mt-2" >
                                    <Labelcomponent value={"Degree/Class"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Degree/Class"}/>
                                    {/* <label className="add-branch-label">Degree/Class</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Degree/class" /> */}
                                </div>

                                <div className="col-2 mt-2" >
                                    <Labelcomponent value={"Course"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Course"}/>
                                    {/* <label className="add-branch-label">Course</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="course" /> */}
                                </div>
                                <div className="col-3 mt-2" >
                                    <Labelcomponent value={"School / College / University"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"School / College / University"}/>
                                    {/* <label className="add-branch-label" style={{ fontSize: "11px" }}>School / College / University</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="School / College / University" /> */}
                                </div>

                                <div className="col-2 mt-2" >
                                    <Labelcomponent value={"passout year"}/>
                                    {/* <label className="add-branch-label">Passout year</label> */}
                                    <select className="form-select year-dropdown mt-2">
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ps-6">Add</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Occupation"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Occupation"}/>
                                    {/* <label className="add-branch-label">Occupation</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Occupation" /> */}
                                </div>

                                <div className="col-6 mt-2" >
                                    <Labelcomponent value={"Annual income"}/>
                                    <Inputcomponent className={"form-control"} type={"text"} placeholder={"Annual income"} />
                                    {/* <label className="add-branch-label">Annual income</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Annual income" /> */}
                                </div>

                            </div>



                            {/* <div className="form-group row col-10 mx-auto p-0" >

                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Employee Designation</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Designation" />
                                </div>

                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Joining date</label>
                                    <input type="date" className="form-control add-branch-input" placeholder=" " />
                                </div>
                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Joining date</label>
                                    <input type="date" className="form-control add-branch-input" placeholder=" " />
                                </div>

                            </div>
                            <div className="form-group row col-10 mx-auto p-0 mt-3" >
                                <label className="add-branch-label">Roles and Responsibility</label>
                                <div className="col-4 mt-2" >
                                    <input type="checkbox" id="Teacher" /><label htmlFor="Teacher" className="add-branch-label ms-1">Teacher</label>
                                </div>
                            </div> */}
                            {/* <div className="form-group row col-10 mx-auto p-0 mt-2" >
                            <div className="col-4 mt-2" >
                                <input type="checkbox" id="Main Admin" /><label htmlFor="Main Admin" className="add-branch-label ms-1">Main Admin</label>
                            </div>
                        </div>
                        <div className="form-group row col-10 mx-auto p-0 mt-2" >
                            <div className="col-4 mt-4" >
                                <input type="checkbox" id="Branch Admin" /><label htmlFor="Branch Admin" className="add-branch-label ms-1">Branch Admin</label>
                            </div>
                            <div className="col-4" >
                                <label className="add-branch-label">Select Branch</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Field" />
                            </div>
                            <div className="col-1  p-0 d-flex justify-content-center " >
                                <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ps-6">Add</button>
                            </div>

                            <div className="col-1  p-0 d-flex justify-content-center " >
                                <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                            </div> 
                        </div>

                            <div className="form-group row col-10 mx-auto p-0 mt-3" >
                                <label className="add-branch-label">Education</label>
                                <div className="col-3 mt-2" >
                                    <label className="add-branch-label">Degree/Class</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Degree/class" />
                                </div>

                                <div className="col-2 mt-2" >
                                    <label className="add-branch-label">Course</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="course" />
                                </div>
                                <div className="col-3 mt-2" >
                                    <label className="add-branch-label" style={{ fontSize: "11px" }}>School / College / University</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="School / College / University" />
                                </div>

                                <div className="col-2 mt-2" >
                                    <label className="add-branch-label">Passout year</label>
                                    <select className="form-select year-dropdown mt-2">
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ps-6">Add</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                                </div>

                            </div>

                            {/* <div className="form-group row col-10 mx-auto p-0" >

                            <div className="col-4 mt-2" >
                                <label className="add-branch-label">Id Proof name</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Id Proof name" />
                            </div>

                            <div className="col-4 mt-2" >
                                <label className="add-branch-label">Id proof number</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Id proof number" />
                            </div>
                            <div className="col-2 mt-2" >
                                <label className="add-branch-label">Id Proof name</label>
                                <button className=' form-control mt-2 mb-2 btn add-new-branch' style={{ width: "70%" }}>Upload</button>
                            </div>

                            <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ">Add</button>
                            </div>

                            <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                            </div>

                        </div>


                        <div className="form-group row col-10 mx-auto p-0" >

                            <div className="form-group row col-12 mx-auto p-0" >

                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Id Proof name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Id Proof name" />
                                </div>

                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Id proof number</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Id proof number" />
                                </div>
                                <div className="col-2 mt-2" >
                                    <label className="add-branch-label">Id Proof name</label>
                                    <button className=' form-control mt-2 mb-2 btn add-new-branch' style={{ width: "70%" }}>Upload</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ">Add</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                                </div>

                            </div>

                        </div>




                        <div className="form-group row col-10 mx-auto mt-2 p-0" >
                            {/* <div className="col-6 mt-2" > 
                            <RadioGroup defaultValue='1'>
                                <Radio colorScheme='purple' value='1' className="me-2" >
                                    <span className="radio-button-text">Yes,comes by school transportation</span>
                                </Radio>
                                {/* </RadioGroup> */}
                            {/* </div> */}
                            {/* <div className="col-6 mt-2" > */}
                            {/* <RadioGroup defaultValue='2' className="col-6 mt-2"> 
                                <Radio colorScheme='purple' value='2' className="ms-5 me-2"  >
                                    <span className="radio-button-text"> No,comes by own transportation</span>
                                </Radio>
                            </RadioGroup>
                            {/* </div> 
                        </div>

                        <div className="form-group row col-10 mx-auto p-0" >
                            <div className="form-group col-6  mx-auto mt-2" >
                                <label className="add-branch-label"> From </label>
                                <input type="text" className="form-control add-branch-input" placeholder="From (place)" />
                            </div>

                            <div className="form-group col-6  mx-auto mt-2" >
                                <label className="add-branch-label">To</label>
                                <input type="text" className="form-control add-branch-input" placeholder="To (place)" />
                            </div>
                        </div> */}

                        </div>

                    </form >
            }
            {type === "Add" || type === "Edit" ?

                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <ButtonComponent onClick={() => setModel(true)} className={"me-2 cancelbtn"} value={"cancel"}/>
                        {/* <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={() => setModel(true)}>Cancel</button> */}
                        {activeTab === "personal" ?
                        <ButtonComponent onClick={() => setActiveTab("contact")} className={"me-2 savebtn"} value={"Continue"}/>
                            // <button className='me-2 btn button-1' style={{ width: "95px" }} onClick={() => setActiveTab("contact")}>Continue</button>
                            :
                            activeTab === "contact" ?
                            <ButtonComponent onClick={() => setActiveTab("employee")} className={"me-2 savebtn"} value={"Continue"}/>
                                // <button className='me-2 btn button-1' style={{ width: "95px" }} onClick={() => setActiveTab("employee")}>Continue</button>
                                :
                                <ButtonComponent onClick={handleSubmit} className={"me-2 savebtn"} value={"save"}/>
                                // <button className='me-2 btn button-1' style={{ width: "90px" }} onClick={handleSubmit}>Save</button>
                        }
                    </div>
                </div>
                :
                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <Button className='me-3 left-arrow-button' variant="link" leftIcon={<ArrowBackIcon />} style={{ width: "90px" }} onClick={() => setStudentsAddProfile("")}>Back</Button>
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={handleEdit}>Edit</button>
                        <Button className='me-3 text-delete-button' variant="link" style={{ width: "90px" }} onClick={() => setDeleteModel(true)}>Delete</Button>
                    </div>
                </div>
            }.
            {/* {type === "Add" || type === "Edit" ?

                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={() => setModel(true)}>Cancel</button>
                        <button className='me-2 btn button-1' style={{ width: "90px" }} onClick={handleSubmit}>Save</button>
                    </div>
                </div>
                :
                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <Button className='me-3 left-arrow-button' variant="link" leftIcon={<ArrowBackIcon />} style={{ width: "90px" }} onClick={() => setStudentsAddProfile("")}>Back</Button>
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={handleEdit}>Edit</button>
                        <Button className='me-3 text-delete-button' variant="link" style={{ width: "90px" }} onClick={() => setDeleteModel(true)}>Delete</Button>
                    </div>
                </div>
            } */}



            <Modal
                show={model}
                size="lg"
                animation
                centered
                onHide={() => setModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <h2 className='welcome-title'>Are you sure you want to cancel?</h2>
                        <h5 className='welcome-subtext'>Any unsaved changes will be lost</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setModel(false)}>No, I want to continue Editing</button>
                                <button className='me-2 btn popup-cancel-button-outline ' onClick={handleCancel}>Yes I Want to cancel it</button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>
            <Modal
                show={deleteModel}
                size="lg"
                animation
                centered
                onHide={() => setDeleteModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <img src={bin} alt="delete" className="mx-auto mb-3 " style={{ height: "70px", borderRadius: "30px" }} />
                        <h2 className='welcome-title mb-3'>Are you sure you want to delete?</h2>
                        <h5 className='welcome-subtext'>This action is irreversible and will permanently</h5>
                        <h5 className='welcome-subtext'> remove the item from the system.</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setDeleteModel(false)} style={{ width: "250px " }}>No, Keep it</button>
                                <button className='me-2 btn popup-cancel-button-outline ' onClick={() => alert("Deleting")} style={{ width: "250px " }}>Yes, Delete it</button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>


        </div >

    )
}

export default StudentsAddProfile