import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import axios from "axios";
import { Button, border } from "@chakra-ui/react";

// Bootstrap Button
// import ButtonB from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import curve from "../../../assests/images/curve.svg";
import curve2 from "../../../assests/images/curve2.svg";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import people from "../../../assests/images/people_icon.png";
import bar_icon from "../../../assests/images/bar_icon.png";
import { apiUrl } from "../../../constants";
import {
  AdminDashboardCard,
  Featurecard,
} from "../../../components/ProfileCard";
import { useAuth } from "../../../context/AuthContext";
import feature_image from "../../../assests/images/login_image.png";
import { type } from "@testing-library/user-event/dist/type";
import { getsvgType } from "../../../constants";

const BranchAdminDashboard = () => {
  const [schools, setSchools] = useState();
  const [branchCount, setBranchCount] = useState(0);
  const [classroomCount, setClassroomCount] = useState(0);
  const [admissionCount, setAdmissionCount] = useState(0);
  const { state } = useAuth();
  const branchId = state.branchadminData.branchId;
  console.log("branchId", state.branchadminData);

  useEffect(() => {
    const fetchSchoolId = async () => {
      try {
        // const response = await axios.get(`${apiUrl}school?page=1&page_size=10`);
        const response = await axios.get(
          `${apiUrl}/dashboard/school-admin-branch/${2}`
        );
        setSchools(response.data);
        console.log("setSchools", response.data);
        await fetchDashboardData();
        // const [schoolsResponse, teachersResponse, studentsResponse] = await Promise.all([
        //   axios.get('https://api-mock.appsaras-dev.sarasapp.com/school?page=1&page_size=10'),
        //   axios.get('https://api-mock.appsaras-dev.sarasapp.com/teachers'),
        //   axios.get('https://api-mock.appsaras-dev.sarasapp.com/students'),
        // ]);

        // setSchools(schoolsResponse.data);
        // setTeachers(teachersResponse.data);
        // setStudents(studentsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSchoolId();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchDashboardData = async () => {
    try {
      const [branchResponse, classroomResponse, admissionResponse] =
        await Promise.all([
          axios.get(
            "https://api-mock.appsaras-dev.sarasapp.com/branch?schoolId=1&page=1&page_size=10"
          ),
          axios.get("https://api-mock.appsaras-dev.sarasapp.com/classroom"),
          axios.get("https://api-mock.appsaras-dev.sarasapp.com/admission"),
        ]);

      setBranchCount(branchResponse.data);
      setClassroomCount(classroomResponse.data);
      setAdmissionCount(admissionResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (!schools) {
    return <div>Loading</div>;
  }

  // svg type in dashboard images

  return (
    <>
      <div
        className="w-100 d-flex flex-column"
        style={{ background: "#fbf9ff" }}
      >
        <Header
          title="Branch Admin"
          schoolname={schools.dashboard.schoolName}
          branchname={schools.dashboard.branchName}
        />
        {/* <div className='ms-3 mb-3'>
                    <h5 className='subheader ms-3'>Hi Branch Admin,</h5>
                    <div className='d-flex me-4'>
                        <div className='ms-3 d-flex align-items-center'>
                            <h5 className='subheader me-3 my-auto'>Academic year</h5>
                            <select className="dropdown-select" >
                                <option selected>2023-2024</option>
                                <option value="1">2024-2025</option>
                            </select>
                        </div>
                    </div>
                </div> */}

        {/* DATA ITEMS */}
        <Modal
          show={show}
          // size="md"
          animation
          centered
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          style={{ padding: "14px" }}
        >
          <Modal.Body>
            <div className="mt-3 " style={{ marginLeft: "3.5rem" }}>
              <h2 className="welcome-title">Welcome to Saras App</h2>
              <h5 className="welcome-subtext">
                Please enter the following details
              </h5>
              <div className="mt-2 ">
                <div className="form-group col-10 mt-2">
                  <label className="welcome-input-label mt-4">
                    School Group name <span style={{ color: "red" }}> * </span>
                  </label>
                  <input
                    type="text"
                    className="form-control welcome-input"
                    id="id"
                    placeholder="School Group Name"
                    required
                  />
                </div>

                <div className="form-group col-10 mt-2">
                  <label className="welcome-input-label mt-4">
                    Phone number <span style={{ color: "red" }}> * </span>
                  </label>
                  <input
                    type="text"
                    className="form-control welcome-input"
                    id="id"
                    placeholder="999999999"
                    required
                  />
                </div>

                <div className="form-group col-10 mt-2">
                  <label className="welcome-input-label mt-4">
                    Email <span style={{ color: "red" }}> * </span>
                  </label>
                  <input
                    type="text"
                    className="form-control welcome-input"
                    id="id"
                    placeholder="example@gmail.com"
                    required
                  />
                </div>

                <div className="form-group col-10 mt-2">
                  <label className="welcome-input-label mt-4">
                    Institution Logo
                  </label>
                  <br />
                  <button className="mt-2 mb-2 btn add-new-branch">
                    Upload
                  </button>
                </div>
                <div className="form-group col-10 mt-2">
                  <button
                    className="mt-2 btn save-button"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <br />
            <br />
          </Modal.Body>
        </Modal>

        <div className="mt-2 ps-3">
          <div className="row m-0">
            <Col md={4} className="mb-3">
              <AdminDashboardCard
                title="Classrooms"
                data={schools.dashboard.totalClasses}
                icon={getsvgType("branches")}
              />
            </Col>
            <Col md={4}>
              <AdminDashboardCard
                title="Sections"
                data={schools.dashboard.totalSections}
                icon={getsvgType("sections")}
              />
            </Col>
            <Col md={4}>
              <AdminDashboardCard
                title="Students present"
                data={schools.dashboard.studentsPresent}
                icon={getsvgType("student")}
              />
            </Col>
            <Col md={4}>
              <AdminDashboardCard
                title="Teachers"
                data={schools.dashboard.totalTeachers}
                icon={getsvgType("teachers")}
              />
            </Col>
            <Col md={4}>
              <AdminDashboardCard
                title="Employees"
                data={schools.dashboard.totalEmployees}
                icon={getsvgType("employees")}
              />
            </Col>
            <Col md={4}>
              <AdminDashboardCard
                title="Branch Admins"
                data={schools.dashboard.totalMainAdmins}
                icon={getsvgType("branchadmin")}
              />
            </Col>
            {/* <div className='col-4 data-card d-flex'>
                                <div className='w-10'>
                                    <img src={people} alt="icon" />
                                </div>
                                <div className='ms-4 d-flex flex-column align-items-center' >
                                    <h5 className="data-card-title">Classrooms</h5>
                                    <h3 className='data-card-data'>4</h3>
                                </div>
                            </div> */}

            {/* <div className='col-4 data-card d-flex'>
                                <div className=''>
                                    <img src={people} alt="icon" />
                                </div>
                                <div className='ms-4 d-flex flex-column align-items-center' >
                                    <h5 className="data-card-title">Students Present</h5>
                                    <div className='d-flex align-items-baseline'>
                                        <h3 className='data-card-data'>1925</h3>
                                        <h5 className="data-card-title">/2000</h5>
                                    </div>
                                </div>
                            </div>

                            <div className='col-4  data-card d-flex'>
                                <div className='d-flex flex-column align-items-center me-4' >
                                    <h5 className="data-card-title">Admissions</h5>
                                    <h3 className='data-card-data'>10</h3>
                                </div>
                                <div className='w-10 ms-4'>
                                    <img src={bar_icon} alt="icon" />
                                </div>
                            </div> */}

            {/* <div className='col-4  data-card-active d-flex'>
                                <div className='d-flex flex-column align-items-center me-4 ms-3' >
                                    <h5 className="data-card-active-title">Teachers</h5>
                                    <div className='d-flex align-items-baseline'>
                                        <h3 className='data-card-active-data'>120</h3>&nbsp;
                                        <h5 className="data-card-active-title">/ 150</h5>
                                    </div>
                                </div>
                                <div className='w-10 ms-4'>
                                    <img src={curve} alt="icon" style={{ fill: "red" }} />
                                </div>
                            </div> */}

            {/* <div className='col-4  data-card d-flex'>
                                <div className='d-flex flex-column align-items-center me-4 ms-3' >
                                    <h5 className="data-card-title">Employees</h5>
                                    <div className='d-flex align-items-baseline'>
                                        <h3 className='data-card-data'>120</h3>&nbsp;
                                        <h5 className="data-card-title">/ 150</h5>
                                    </div>
                                </div>
                                <div className='w-10 ms-4'>
                                    <img src={curve2} alt="icon" style={{ height: "75px" }} />
                                </div>
                            </div> */}

            {/* <div className='col-4 data-card d-flex'>
                                <div className='w-10 ms-3'>
                                    <img src={people} alt="icon" />
                                </div>
                                <div className='ms-4 d-flex flex-column align-items-center' >
                                    <h5 className="data-card-title">Branch Admins</h5>
                                    <h3 className='data-card-data'>4</h3>
                                </div>
                            </div> */}

            {/* <div className='col-3 data-card d-flex'>
                                <div className='w-10 ms-3'>
                                    <img src={people} alt="icon" />
                                </div>
                                <div className='ms-4 d-flex flex-column align-items-center' >
                                    <h5 className="data-card-title">Branch Admins</h5>
                                    <h3 className='data-card-data'>4</h3>
                                </div>
                            </div> */}

            <Col md={12}>
              <div className="dashboard-pattern">
                <Row className=" align-items-center justify-content-center">
                  <Col md={5}>
                    <img
                      src={feature_image}
                      className="img-fluid"
                      alt="studentbanner"
                      loading="lazy"
                    />
                  </Col>
                  <Col md={7}>
                    <Row className=" align-items-center">
                      <Col md={12} className="mb-2">
                        <h1>Upcoming Features</h1>
                      </Col>
                      <Col md={6}>
                        <Featurecard value={"staff Attendance"} />{" "}
                      </Col>
                      <Col md={6}>
                        <Featurecard value={"Learn with Ai"} />{" "}
                      </Col>
                      <Col md={6}>
                        <Featurecard value={"Online Fee Payment"} />{" "}
                      </Col>
                      <Col md={6}>
                        <Featurecard value={"Library Management"} />{" "}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>

          {/* Event calendar */}
          {/* <Row className='mt-5 mx-0'>
                        <Col md={8} style={{display:"flex", flexDirection:"column"}}> 
                            <div style={{ background: "#ffffff", padding: "25px", borderRadius: "20px", border: "1px solid grey", flex:'1' }}></div>
                        </Col>

                        <Col md={4}>
                        <div className='' style={{ background: "#ffffff", padding: "10px", borderRadius: "20px", border: "1px solid grey" }}>
                                <h3 className='event-header mb-4'>27 Tue, Jun </h3>

                                <div className='d-flex'>
                                    <div className='event-line'></div>
                                    <div className='d-flex flex-column'>
                                        <h5 className='event-title'>Parent-Teacher meeting</h5>
                                        <p className='event-subtext'>For classes 10, 11, 12th</p>
                                    </div>

                                </div>
                                <div className='d-flex'>
                                    <div className='event-line'></div>
                                    <div className='d-flex flex-column'>
                                        <h5 className='event-title'>School Picnic</h5>
                                        <p className='event-subtext'>For Elementary Classes</p>
                                    </div>

                                </div>
                                <div className='d-flex'>
                                    <div className='event-line'></div>
                                    <div className='d-flex flex-column'>
                                        <h5 className='event-title'>Parent-Teacher meeting</h5>
                                        <p className='event-subtext'>For classes 10, 11, 12th</p>
                                    </div>

                                </div>
                            <Button variant="link" rightIcon={<ArrowForwardIcon />} style={{ fontFamily: "DM Sans", fontWeight: "700", fontSize: "14px", color: "#8A21FA" }}> View all</Button>
                            </div>
                        </Col>
                    </Row> */}

          {/* <div className='col-11 d-flex' >

                        <div className='col-9 ms-3 mt-5 me-2' style={{ background: "#ffffff", padding: "25px", borderRadius: "20px", border: "1px solid grey" }}>
                            <BarGraph />
                        </div>

                        <div className='col-3 mt-5 me-2' style={{ background: "#ffffff", padding: "10px", borderRadius: "20px", border: "1px solid grey" }}>

                            <div className='mt-3 ms-3 '>
                                <h3 className='event-header mb-4'>27 Tue, Jun </h3>

                                <div className='d-flex'>
                                    <div className='event-line'></div>
                                    <div className='d-flex flex-column'>
                                        <h5 className='event-title'>Parent-Teacher meeting</h5>
                                        <p className='event-subtext'>For classes 10, 11, 12th</p>
                                    </div>

                                </div>
                                <div className='d-flex'>
                                    <div className='event-line'></div>
                                    <div className='d-flex flex-column'>
                                        <h5 className='event-title'>School Picnic</h5>
                                        <p className='event-subtext'>For Elementary Classes</p>
                                    </div>

                                </div>
                                <div className='d-flex'>
                                    <div className='event-line'></div>
                                    <div className='d-flex flex-column'>
                                        <h5 className='event-title'>Parent-Teacher meeting</h5>
                                        <p className='event-subtext'>For classes 10, 11, 12th</p>
                                    </div>

                                </div>
                            </div>
                            <Button variant="link" rightIcon={<ArrowForwardIcon />} style={{ fontFamily: "DM Sans", fontWeight: "700", fontSize: "14px", color: "#8A21FA" }}> View all</Button>


                        </div>
                    </div> */}
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export default BranchAdminDashboard;
