import {
    Box,
    HStack,
    Input,
    InputLeftElement,
    Text,
    Wrap,
    WrapItem,
    InputGroup,
    Button,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer, IconButton, Icon
} from "@chakra-ui/react";

import Modal from 'react-bootstrap/Modal';

import { countryCodeList } from "../../../constants"
import { Checkbox } from '@chakra-ui/react'
import React, { useState } from "react";
import { Radio, RadioGroup } from '@chakra-ui/react'
import bin from "../../../assests/images/icons/bin.svg"
import { ArrowBackIcon } from "@chakra-ui/icons";

import add_button from "../../../assests/images/icons/add_button.svg"
import delete_button from "../../../assests/images/icons/delete_button.svg"
import PeriodComponent from "./PeriodComponent";


const PeriodForm = ({ setBranchAddProfile, teacherData, onSave, type, onCancel, onEdit }) => {

    const [activeTab, setActiveTab] = useState("period")
    //const[contact,setContact] = useState("contact")
    const [teacherFormData, setTeacherFormData] = useState(teacherData || "");
    const [model, setModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setTeacherFormData((prevTeacherData) => ({
            ...prevTeacherData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //setBranchAddProfile(false);
        onSave(teacherFormData);
    };

    const handleCancel = () => {
        setTeacherFormData(teacherFormData);
        // setBranchAddProfile(false);
        onCancel();
    };
    const handleEdit = () => {

        onEdit();
    }
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 50;

    const years = Array.from({ length: 51 }, (_, index) => startYear + index);

    const bloodGroups = [
        'A+',
        'A-',
        'B+',
        'B-',
        'AB+',
        'AB-',
        'O+',
        'O-',
    ];



    return (


        <div className="mt-3 pb-5" >

            <div class="d-flex col-12 align-items-end">

                <div class="d-flex col-6 align-items-end">
                    <h1 class="add-branch-title ms-3">Period Table</h1>
                    <h1 class="add-branch-subtitle ms-3">{">"}{type}</h1>
                </div>


                <div class="d-flex col-6 justify-content-end">
                    <label for="inputGroupSelect" class="teacher-campus-select">Academic Year</label>
                    &nbsp;
                    <select name="academic-select" class="custom-select">
                        <option value="1">2003-2004</option>
                        <option value="2">2004-2005</option>
                    </select>
                </div>
            </div>



            <div className="row col-12 p-0 mx-auto mt-3">
                <Button className={`col-6 add-branch-header-tab${activeTab === "period" ? "-active" : ""}`} onClick={() => setActiveTab("period")}>Manage Periods</Button>
                <Button className={`col-6 add-branch-header-tab${activeTab === "section" ? "-active" : ""}`} onClick={() => setActiveTab("section")}>Assign Sections</Button>
            </div>
            {activeTab === "period" ?
                <form className="form-horizontal mt-4" >
                    <div className="row col-12 mx-auto ">
                        <div className="form-group row col-12 mx-auto p-0" >
                            <div className="col-12 mt-2" >
                                <label className="period-label">Period Table name</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Ex. Elementry" disabled={type === "View" ? true : false} />
                                <div className="mt-4 " style={{ borderBottom: "1px solid rgba(174, 184, 214, 0.2)" }} ></div>
                            </div>
                        </div>

                        <TableContainer className="custom-scrollbar mt-3">
                            <Table variant="simple" size="sm" style={{ textAlign: "center" }}>
                                <Tbody fontSize="sm" padding={0} >
                                    <Tr className='branch-data-table-header'>
                                        
                                        <PeriodComponent />
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer >


                    </div>

                </form>
                :
                <form className="form-horizontal mt-4" >
                    <div className="row col-12 mx-auto ">

                        <div className="form-group row col-10 mx-auto p-0" >

                            <div className="col-4 mt-2" >
                                <label className="add-branch-label">Employee Designation</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Designation" disabled={type === "View" ? true : false} />
                            </div>

                            <div className="col-4 mt-2" >
                                <label className="add-branch-label">Joining date</label>
                                <input type="date" className="form-control add-branch-input" placeholder=" " disabled={type === "View" ? true : false} />
                            </div>
                            <div className="col-4 mt-2" >
                                <label className="add-branch-label">Leaving date</label>
                                <input type="date" className="form-control add-branch-input" placeholder=" " disabled={type === "View" ? true : false} />
                            </div>

                        </div>
                        <div className="form-group row col-10 mx-auto p-0 mt-3" >
                            <label className="add-branch-label">Roles and Responsibility</label>
                            <div className="col-4 mt-2" >
                                <input type="checkbox" id="Teacher" disabled={type === "View" ? true : false} /><label htmlFor="Teacher" className="add-branch-label ms-1" >Teacher</label>
                            </div>
                        </div>

                        <div className="form-group row col-10 mx-auto p-0 mt-3" >
                            <label className="add-branch-label">Education</label>
                            <div className="col-3 mt-2" >
                                <label className="add-branch-label">Degree/Class</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Degree/class" disabled={type === "View" ? true : false} />
                            </div>

                            <div className="col-2 mt-2" >
                                <label className="add-branch-label">Course</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Course" disabled={type === "View" ? true : false} />
                            </div>
                            <div className="col-3 mt-2" >
                                <label className="add-branch-label" style={{ fontSize: "11px" }}>School / College / University</label>
                                <input type="text" className="form-control add-branch-input" placeholder="School / College / University" disabled={type === "View" ? true : false} />
                            </div>

                            <div className="col-2 mt-2" >
                                <label className="add-branch-label">Passout year</label>
                                <select className="form-select year-dropdown mt-2">
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                                <img src={add_button} alt="add_button" className="btn add-upload-button p-2 pb-0" />
                            </div>

                            <div className="col-1 mt-2 p-0 d-flex justify-content-center align-items-end" >
                                <img src={delete_button} alt="delete_button" className="btn add-upload-button p-2 pb-0 " />
                            </div>
                        </div>

                        {/* <div className="form-group row col-10 mx-auto p-0" >

                            <div className="col-4 mt-2" >
                                <label className="add-branch-label">Id Proof name</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Id Proof name" />
                            </div>

                            <div className="col-4 mt-2" >
                                <label className="add-branch-label">Id proof number</label>
                                <input type="text" className="form-control add-branch-input" placeholder="Id proof number" />
                            </div>
                            <div className="col-2 mt-2" >
                                <label className="add-branch-label">Id Proof name</label>
                                <button className=' form-control mt-2 mb-2 btn add-new-branch' style={{ width: "70%" }}>Upload</button>
                            </div>

                            <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ">Add</button>
                            </div>

                            <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                            </div>

                        </div>


                        <div className="form-group row col-10 mx-auto p-0" >

                            <div className="form-group row col-12 mx-auto p-0" >

                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Id Proof name</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Id Proof name" />
                                </div>

                                <div className="col-4 mt-2" >
                                    <label className="add-branch-label">Id proof number</label>
                                    <input type="text" className="form-control add-branch-input" placeholder="Id proof number" />
                                </div>
                                <div className="col-2 mt-2" >
                                    <label className="add-branch-label">Id Proof name</label>
                                    <button className=' form-control mt-2 mb-2 btn add-new-branch' style={{ width: "70%" }}>Upload</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn add-upload-button d-flex align-items-center text-center mt-2 ">Add</button>
                                </div>

                                <div className="col-1 mt-2 p-0 d-flex justify-content-center " >
                                    <button className="btn delete-upload-button d-flex align-items-center mt-2">Delete</button>
                                </div>

                            </div>

                        </div>




                        <div className="form-group row col-10 mx-auto mt-2 p-0" >
                            {/* <div className="col-6 mt-2" > 
                            <RadioGroup defaultValue='1'>
                                <Radio colorScheme='purple' value='1' className="me-2" >
                                    <span className="radio-button-text">Yes,comes by school transportation</span>
                                </Radio>
                                {/* </RadioGroup> */}
                        {/* </div> */}
                        {/* <div className="col-6 mt-2" > */}
                        {/* <RadioGroup defaultValue='2' className="col-6 mt-2"> 
                                <Radio colorScheme='purple' value='2' className="ms-5 me-2"  >
                                    <span className="radio-button-text"> No,comes by own transportation</span>
                                </Radio>
                            </RadioGroup>
                            {/* </div> 
                        </div>

                        <div className="form-group row col-10 mx-auto p-0" >
                            <div className="form-group col-6  mx-auto mt-2" >
                                <label className="add-branch-label"> From </label>
                                <input type="text" className="form-control add-branch-input" placeholder="From (place)" />
                            </div>

                            <div className="form-group col-6  mx-auto mt-2" >
                                <label className="add-branch-label">To</label>
                                <input type="text" className="form-control add-branch-input" placeholder="To (place)" />
                            </div>
                        </div> */}

                    </div>

                </form >
            }
            {type === "Add" || type === "Edit" ?

                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={() => setModel(true)}>Cancel</button>
                        {activeTab === "personal" ?
                            <button className='me-2 btn button-1' style={{ width: "95px" }} onClick={() => setActiveTab("contact")}>Continue</button>
                            :
                            activeTab === "contact" ?
                                <button className='me-2 btn button-1' style={{ width: "95px" }} onClick={() => setActiveTab("employee")}>Continue</button>
                                :
                                <button className='me-2 btn button-1' style={{ width: "90px" }} onClick={handleSubmit}>Save</button>
                        }
                    </div>
                </div>
                :
                <div className="row col-10 p-0 mx-auto mt-3 ">
                    <div className="d-flex justify-content-center mt-3">
                        <Button className='me-3 left-arrow-button' variant="link" leftIcon={<ArrowBackIcon />} style={{ width: "90px" }} onClick={() => setBranchAddProfile("")}>Back</Button>
                        <button className='me-2 btn button-outline-1 ' style={{ width: "90px" }} onClick={handleEdit}>Edit</button>
                        <Button className='me-3 text-delete-button' variant="link" style={{ width: "90px" }} onClick={() => setDeleteModel(true)}>Delete</Button>
                    </div>
                </div>
            }



            <Modal
                show={model}
                size="lg"
                animation
                centered
                onHide={() => setModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <h2 className='welcome-title'>Are you sure you want to cancel?</h2>
                        <h5 className='welcome-subtext'>Any unsaved changes will be lost</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setModel(false)}>No, I want to continue Editing</button>
                                <button className='me-2 btn popup-cancel-button-outline ' onClick={handleCancel}>Yes I Want to cancel it</button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>
            <Modal
                show={deleteModel}
                size="lg"
                animation
                centered
                onHide={() => setDeleteModel(false)}
                backdrop="static"
                keyboard={false}
                style={{ padding: "0px" }}
            >
                <Modal.Body style={{ padding: "0px", }}>
                    <div className='mt-4 text-center' >
                        <img src={bin} alt="delete" className="mx-auto mb-3 " style={{ height: "70px", borderRadius: "30px" }} />
                        <h2 className='welcome-title mb-3'>Are you sure you want to delete?</h2>
                        <h5 className='welcome-subtext'>This action is irreversible and will permanently</h5>
                        <h5 className='welcome-subtext'> remove the item from the system.</h5>
                        <div className='mt-5 '>
                            <div className="d-flex justify-content-center mt-3">
                                <button className='me-2 btn popup-cancel-button ' onClick={() => setDeleteModel(false)} style={{ width: "250px " }}>No, Keep it</button>
                                <button className='me-2 btn popup-cancel-button-outline ' onClick={() => alert("Deleting")} style={{ width: "250px " }}>Yes, Delete it</button>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </Modal.Body>
            </Modal>


        </div >

    )
}

export default PeriodForm