import {
  Button,
  Avatar,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiSolidUser } from "react-icons/bi";
import { ChevronDownIcon, BellIcon, UnlockIcon } from "@chakra-ui/icons";

import { Menu, MenuButton } from "@chakra-ui/react";
import { Auth } from 'aws-amplify';

import { useAuth } from '../context/AuthContext';
import Inputcomponent from "./Input/Input";

const NavBar = ({title,schoolname,branchname}) => {
  const { dispatch } = useAuth();
  const navigate = useNavigate()
    //  const [username, setUsername] = useState(JSON.parse(localStorage.getItem("userData")).idToken.payload.name ? JSON.parse(localStorage.getItem("userData")).idToken.payload.name : "User")
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
     const usernameFromStorage = storedUserData?.idToken?.payload?.name || "User";
    const [username, setUsername] = useState(usernameFromStorage)

  async function signOut() {
    console.log("Logged Out ")
    try {
      // await Auth.signOut({ global: true });s
      dispatch({ type: 'LOGOUT' });
    } catch (error) {
      console.log('error signing out: ', error);

    }
  }


  const switchprofile = () =>{
    navigate('/select-profile')
  }

  return (
    <>
      <div className=" header-container  ps-3 ">
        <div className="row w-100 m-0">
          <div className="col-10">
            <div className="row align-items-center">
          <div className="col-4">
            <div className="d-flex align-items-center">
              <label>School</label>
              <Inputcomponent type="text" id='disabled' value={schoolname} disabled/>
              {/* <input type="text" id='disabled' value={schoolname} disabled/> */}
              </div>
              </div>
           
           {title === "Branch Admin" ? 
            <div className="col-4">
            <div className="d-flex align-items-center">
            <label>Branch</label>
            <input type="text" id='disabled' value={branchname} disabled/>
            </div>
            </div> : (null)
          }
             
              
              <div className="col-4">
                <p className="login-user mb-0">Logged-in as {title}</p>
              </div>
              </div>
              </div>

            <div className="col-2">
          
              <div className="d-flex justify-content-end align-items-center">
              {/* <BellIcon boxSize={6} className="me-4" /> */}
              <Avatar name={username}
                size="sm" cursor={"pointer"}
                bgColor="#8A21FA" className="me-2"
                color="#fff"
              />
              <Menu>
                <MenuButton
                  as={Button} rightIcon={<ChevronDownIcon />}
                  variant={"link"}
                  style={{
                    textDecoration: "none",
                    border: "none",
                    padding: "1px",
                    // width: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                  className="me-2"
                >
                  {username}
                </MenuButton>
                <MenuList
                  minWidth='10px'
                  style={{
                    marginTop: "10px",
                    textAlign: "--webkit-right"
                  }}
                >
                    {title === "Main Admin" ? (
                      <MenuItem onClick={() => navigate('/admin-profile')}><BiSolidUser /> &nbsp; View Profile</MenuItem>
                        ) : (
                      <MenuItem onClick={() => navigate('/Branchadmin-profile')}><BiSolidUser /> &nbsp; View Profile</MenuItem>
                        ) }
                  <MenuItem onClick={() => navigate("/roles")}><BiSolidUser /> &nbsp; Switch Profile</MenuItem>
                  <MenuItem onClick={() => signOut()}> <UnlockIcon className="me-4" /> Logout</MenuItem>
                </MenuList>
              </Menu>
            </div>
            </div>
            </div>
  
          {/* col  */}

       
      </div>
    </>
  );
};
export default NavBar;
