import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  HStack,
  Input,
  InputLeftElement,
  Text,
  Wrap,
  WrapItem,
  InputGroup,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer, IconButton, Icon
} from "@chakra-ui/react";

// icons
import eye from "../../../assests/images/icons/eye.svg";
import bin from "../../../assests/images/icons/bin.svg";
import pencil from "../../../assests/images/icons/pencil.svg";

import { MdCompareArrows } from "react-icons/md"
// import { RiDeleteBinLine, RiEditLine, RiEyeLine } from 'react-icons/ri'

const DataTable = ({ allBranchesData, setBranchType }) => {


  const data = [
    { no: 1, Branch_Name: "Branch Name", Classrooms: 200, Students: 2000, Employees: 500 },
    { no: 2, Branch_Name: "Branch Name", Classrooms: 100, Students: 1000, Employees: 300 },
  ];


  const handleDelete = (id) => {
    alert(`Deleting item with id: ${id}`);
  };

  return (
    <TableContainer className="custom-scrollbar mt-3">
      <Table variant="simple" size="sm" style={{ textAlign: "center" }}>
        <Tbody fontSize="sm" padding={0}>
          <Tr className='branch-data-table-header'>
            <Td>No<Icon as={MdCompareArrows} transform="rotate(90deg)" /></Td>
            <Td>Branch Name</Td>
            <Td>Classrooms</Td>
            <Td>Students</Td>
            <Td>Employees</Td>
            <Td>Action</Td>
          </Tr>
          {data.map((item) =>
            <Tr className='branch-data-table' size="sm" key={item.no}>
              <Td>{item.no}</Td>
              <Td>{item.Branch_Name}</Td>
              <Td>{item.Classrooms}</Td>
              <Td>{item.Students}</Td>
              <Td>{item.Employees}</Td>
              <Td>
                <div className='d-flex'>
                  <img className='me-2 icon' src={eye} alt="View" onClick={() => setBranchType("View", "id")} />
                  <img className='me-2 icon' src={pencil} alt="Edit" onClick={() => setBranchType("Edit", "id")} />
                  <img className='me-2 icon' src={bin} alt="Delete" onClick={() => handleDelete("id")} />
                </div>
              </Td>
            </Tr>
          )}

        </Tbody>
      </Table>
    </TableContainer >
  )
}

export default DataTable