import React, { useState, useEffect } from "react";

import Header from "../../../components/Header";

import people from "../../../assests/images/people_icon.png";

import axios from "axios";
import { apiUrl } from "../../../constants";
import {
  Box,
  HStack,
  Input,
  InputLeftElement,
  Text,
  Wrap,
  WrapItem,
  InputGroup,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Icon,
} from "@chakra-ui/react";

import { SearchIcon, SmallAddIcon } from "@chakra-ui/icons";
import CalenderData from "./CalenderData";
// import DataTable from './DataTable';
// import ClassFormData from './ClassFormData';

const Calender = () => {
  const [allTeachersData, setAllTeachersData] = useState([]);
  const [branchAddProfile, setBranchAddProfile] = useState("");
  const [teacherData, setTeacherData] = useState({});

  useEffect(() => {
    // Fetch All Branch data from API and update the formData state
    fetchAllTeacherData().then((data) => {
      setAllTeachersData(data);
    });

    // fetchTeacherData().then((data) => {
    //     setTeacherData(data);
    // });
  }, []);

  const handleCancel = () => {
    // Reset the form data to the initial state
    setBranchAddProfile("");
    setTeacherData(teacherData);
  };

  const handleSave = (newTeacherData) => {
    // Call the API to save the updated data
    saveTeacherData(newTeacherData).then(() => {
      // Handle any success actions
    });
    setBranchAddProfile("");
  };
  const handleProfileType = (e, id) => {
    setBranchAddProfile(e);
  };

  const handleEdit = () => {
    setBranchAddProfile("Edit");
  };

  const saveTeacherData = async () => {
    try {
      const response = await axios.get(`${apiUrl}`); // Replace with your API endpoint
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  //edit needed
  const fetchAllTeacherData = async () => {
    try {
      const allBranchApiData = await axios.get(`${apiUrl}/branch?schoolId=1`);
      console.log("Branch Data", allBranchApiData.data);

      // const addressData = await axios.get(`${apiUrl}address/${branchApiData.data.addressId}`); // Replace with your API endpoint
      // console.log("addressData ", branchApiData.data)
      // const apiData = {
      //     branchName: branchApiData.data.name
      // }
      // setBranchData({
      //     branchName: branchData.data.name,

      // })
      return allBranchApiData.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  if (branchAddProfile !== "") {
    return (
      <div
        className="w-100 d-flex flex-column"
        style={{ background: "#fbf9ff" }}
      >
        <Header />
        <div
          className="ms-3"
          style={{
            background: "#ffffff",
            padding: "10px",
            borderRadius: "15px",
            width: "95%",
            height: "auto",
            border: "1px solid #95A1BE",
          }}
        >
          {/* <ClassFormData setBranchAddProfile={setBranchAddProfile} type={branchAddProfile} teacherData={teacherData} onSave={handleSave} onCancel={handleCancel} onEdit={handleEdit} /> */}
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 d-flex flex-column" style={{ background: "#fbf9ff" }}>
      <Header />

      {/* SUB HEADER */}
      <div className="d-flex align-items-end">
        <h1 className="add-teacher-title ms-5">Settings</h1>
        <h1 className="add-teacher-subtitle ms-3">{">"} Annual Calender</h1>
      </div>
      <div className="ms-4 mt-2">
        <p className="ms-4 text-small-2 mb-0 ">
          Create and manage School events
        </p>
      </div>

      {/* SUB HEADER 1 */}

      <div className="col-11 ms-3 mt-4">
        <div
          className="ms-4"
          style={{
            background: "#ffffff",
            padding: "10px",
            borderRadius: "25px",
          }}
        >
          <CalenderData />
        </div>
      </div>
    </div>
  );
};
// {/* <h4 className='teacher-header ms-3 mt-3'>Class & Section List</h4> */ }
// {/*
//                     <div className='ms-3 mt-4 d-flex justify-content-between'>
//                         <div className='d-flex align-items-center'>
//                             <h6 className='me-2 mb-0 text-small-1'>Show</h6>
//                             <select className="teacher-select">
//                                 <option selected>10</option>
//                                 <option value="20">20</option>
//                                 <option value="30">30</option>
//                                 <option value="50">50</option>
//                             </select>
//                             <h6 className='ms-2 mb-0 text-small-1'>entries</h6>
//                         </div>

//                         <div className='d-flex me-5' > */}
// {/* <InputGroup size="sm" height="44px">
//                                 <InputLeftElement pointerEvents="none"
//                                     children={<SearchIcon color="#1B2559" boxSize={3} />} height="44px"
//                                 />
//                                 <Input
//                                     type="text" size="sm"
//                                     placeholder="Search" className='teacher-search'
//                                 />
//                             </InputGroup> */}
// {/* <Button
//                                 className='add-new-teacher me-3'
//                                 paddingX={5}
//                                 size="sm"
//                                 onClick={() => setBranchAddProfile("Add")}
//                             >
//                                 <SmallAddIcon boxSize={6} /> Create
//                             </Button>

//                         </div>

//                     </div>

//                     <div className='ms-3 mb-4'> */}
// {/* <DataTable allTeachersData={allTeachersData} setProfileType={handleProfileType} /> */ }
// {/* </div> */ }

// {/* <div className="teacher-pages">
//                 <button className="page0" disabled style={{ opacity: '40%' }}></button>
//                 <button className="page1">1</button>
//                 <button className="page2">2</button>
//                 <button className="page3">3</button>
//                 <button className="page4">4</button>
//                 <button className="page5">5</button>
//                 <button className="pageN">&gt;</button>
//             </div> */}

export default Calender;
