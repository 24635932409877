import React, { createContext, useReducer, useContext } from 'react';


const AuthContext = createContext();

const initialState = {
    user: null,
    isLoggedIn: false,
    mainadminData : {},
    branchadminData:{}
};

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.payload.user,
                isLoggedIn: true
            };
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                isLoggedIn: false
            };
            case 'SET_MAIN_ADMIN_DATA':
                return {
                    ...state,
                    mainadminData: action.payload.mainadminData,
                };
                case 'SET_BRANCH_ADMIN_DATA':
                    return {
                        ...state,
                        branchadminData: action.payload.branchadminData,
                    };
        default:
            return state;
    }
};


export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};


export function useAuth() {
    return useContext(AuthContext);
}