import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CalenderData = () => {

    const events = [
        {
            title: 'Event 1',
            start: new Date(2023, 7, 6, 13, 0, 0), // 1 PM
            end: new Date(2023, 7, 7, 14, 0, 0) // 2 PM
        },
        {
            title: 'Event 2',
            start: new Date(2023, 4, 31, 9, 0, 0), // 9 AM
            end: new Date(2023, 4, 31, 10, 0, 0) // 10 AM
        },

    ];

    return (
        <div>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
            />
        </div>
    );
};

export default CalenderData;
