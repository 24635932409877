import Header from "../../../components/Header";
import {Row,Col} from 'react-bootstrap'  
import  './Adminprofile.css'
import { apiUrl } from '../../../constants';
import axios from 'axios'
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";



const Adminprofile = () =>{
    const {state} = useAuth()
    const MainadminprofileData = state.mainadminData
   
    // const [profiledata, setProfiledata] = useState([])
    // const MainadminprofileData = JSON.parse(localStorage.getItem("MainadminProfileData")) || {};
    // const userId = storedUserData?.idToken?.payload?.sub

    // const Fetchdata =async () =>{
    //         try{
    //             const {data} =await axios.get(`${apiUrl}/user/${userId}/profiles/school-admin-main`)
    //             setProfiledata(data.profiles)
    //             console.log("Profile data :: -->",data);
    //         }
    //         catch(e){
    //             console.error(e)
    //         }
    // }

    // useEffect(() =>{
    //     Fetchdata()
    // },[])


    // profiledata.forEach(item =>{
    //     const fullName = item.schoolName
    //     console.log(fullName, "fullName");
    // })

    // const dateobj = new Date(MainadminprofileData.dateOfBirth)
    // const formattedDob = format(dateobj,'yyyy-MM-dd')

    return(
        <>
          <div className='w-100 d-flex flex-column me-3' style={{ background: "#fbf9ff" }}>
         <Header title="Main admin" schoolname={MainadminprofileData.schoolName}/>
            <div className="profile-container ms-3 mt-3 p-5">
                <div className="d-flex mb-4">
                    <h6>My Profile</h6>
                </div>
                <div className="Profile-image-container mb-3">
                <img src="" className="img-fluid" loading="lazy" alt="Admin"/>
                </div>
                <div className="Admin-credentials">
                    <div className="d-grid">
                    <label>Full Name</label>
                    <input value={MainadminprofileData.firstName + MainadminprofileData.lastName}  disabled/>
                    </div>

                    <div className="d-grid">
                    <label>Customer Id</label>
                    <input value={MainadminprofileData.customerId } disabled/>
                    </div>

                    <Row>
                        <Col md={4}>
                        <div className="d-grid">
                        <label>Date of Birth</label>
                        <input value={MainadminprofileData.dateOfBirth} disabled/>
                        </div>
                        </Col>

                        <Col md={4}>
                        <div className="d-grid">
                        <label>Gender</label>
                        <input value={MainadminprofileData.gender} disabled/>
                        </div>
                        </Col>

                        <Col md={4}>
                        <div className="d-grid">
                        <label>Blood Group</label>
                        <input value={MainadminprofileData.bloodGroup} disabled/>
                        </div>
                        </Col>

                        <Col md={12}>
                        <div className="d-grid">
                        <label>Address</label>
                        <input value={MainadminprofileData.address.city} disabled/>
                        </div>
                        </Col>

                        <Col md={6}>
                        <div className="d-grid">
                        <label>Phone number</label>
                        <input value={MainadminprofileData.phoneNumber} disabled/>
                        </div>
                        </Col>

                        <Col md={6}>
                        <div className="d-grid">
                        <label>Email</label>
                        <input value={MainadminprofileData.email} disabled/>
                        </div>
                        </Col>

                        <Col md={6}>
                        <div className="d-grid">
                        <label>School group name</label>
                        <input value={MainadminprofileData.schoolName} disabled/>
                        </div>
                        </Col>

                        <Col md={6}>
                        <div className="d-grid">
                        <label>Role</label>
                        <input value="main admin" disabled/>
                        </div>
                        </Col>


                    </Row>

                </div>
            </div>
    </div>
        </>
    )
}

export default Adminprofile;
